import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import { API_resend_welcome_email } from '../../../../../constants';
import { postRightworkAPI } from '../../utilities/requests';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EmployeeDeleteWarningModal(props) {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const get_store_name = (storeId) => {
    const store_info = props.storeNames.filter((s) => s.id === storeId);
    if (store_info.length > 0) {
      return store_info[0]["store_name"];
    } else {
      return null;
    }
  };


  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="text-rose-800">
                <WarningIcon style={{ marginBottom: "1px" }} />
              </div>
              <h3 className="text-2xl text-slate-900">Are you sure?</h3>
            </div>
            <div>
              <p className="text-slate-500 text-sm mt-4">Are you sure you want to delete <span className="font-bold">{props.firstName} {props.lastName}</span> from <span className="font-bold">{get_store_name(props.homeStore)}</span>?</p>
              <p className="text-slate-500 text-sm mt-4">Deleting this team member account will hide {props.firstName} from all lists associated with {get_store_name(props.homeStore)}. If {props.firstName} belongs to no other stores, their Rightwork account credentials will also be revoked.</p>
              <p className="text-slate-500 text-sm mt-4">Only perform this action if {props.firstName} is no longer working at {get_store_name(props.homeStore)}.</p>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                <div className="px-8 py-2 bg-rose-600 hover:bg-rose-700 text-white rounded-full text-base w-fit cursor-pointer"
                  onClick={() => {
                    props.archive_store_team_member(props.stm_id)
                    props.setShowUserProfile(false)
                    props.handleClose()
                  }}
                >
                  Delete {props.firstName} from {get_store_name(props.homeStore)}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default EmployeeDeleteWarningModal
