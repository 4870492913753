import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import DefaultAvailabilityCard from "./DefaultAvailabilityCard.js";
import AvailabilityRadioButtonsGroup from "./AvailabilityRadioButtonGroup.js";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { formatDateReadable } from "../../utilities/helpers.js";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatDate } from "../../utilities/helpers.js";
import { text_to_num } from "../../utilities/helpers.js";
import { format_time_num } from "../../utilities/helpers.js";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Tooltip from "@mui/material/Tooltip";
import DefaultAvailabilityCardUpdated from "./DefaultAvailabilityCardUpdated.js";
import { Drawer } from "@mui/material";
import DrawerHeader from "../helpers/DrawerHeader.js";
import DrawerFooterContainerSmall from "../helpers/DrawerFooterContainerSmall.js";
import ButtonFilled from "../helpers/ButtonFilled.js";
import { PurpleSwitch } from "../../../../../helpers/helpers.js";
import DatePickerDropdown from "../helpers/DatePickerDropdown.js";

export default function AvailabilityMineTableRow(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 475,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState("All Day");

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  const [date, setDate] = React.useState(formatDate(props.minEffectiveDate));

  const [note, setNote] = React.useState("");

  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const [preferredFrom, setPreferredFrom] = React.useState("");
  const [preferredTo, setPreferredTo] = React.useState("");

  const [validationError, setValidationError] = React.useState("");

  const [addPreferences, setAddPreferences] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setAddPreferences(event.target.checked);
    setPreferredFrom("");
    setPreferredTo("");
  };

  const [selectedAvailability, setSelectedAvailability] = React.useState("");

  const [preferencesChecked, setPreferencesChecked] = React.useState(false);

  const handlePreferencesChange = (event) => {
    setPreferencesChecked(event.target.checked);
  };

  console.log("SELECTED AVAILABILITY", selectedAvailability);

  // props.delete_availability_request

  const handleSubmit = () => {
    let days = [
      "Mondays",
      "Tuesdays",
      "Wednesdays",
      "Thursdays",
      "Fridays",
      "Saturdays",
      "Sundays",
    ];
    /*
    dow = request.data.get('dow')
    available_from = request.data.get('available_from')
    available_to = request.data.get('available_to')
    note = request.data.get('note')
    user_id = request.user.id
    */

    if (selectedAvailability === "Custom") {
      if (!startTime || !endTime) {
        setValidationError(
          "Both start and end times are required for custom availability."
        );
        return;
      }

      const startTimeNum = text_to_num(startTime);
      const endTimeNum = text_to_num(endTime);

      if (startTimeNum >= endTimeNum) {
        setValidationError("Start time must be before end time.");
        return;
      }

      if (addPreferences) {
        if (!preferredFrom || !preferredTo) {
          setValidationError(
            "Both Preferred From and Preferred To are required when 'Add Preferences' is checked."
          );
          return;
        }

        const preferredFromNum = text_to_num(preferredFrom);
        const preferredToNum = text_to_num(preferredTo);

        if (preferredFromNum >= preferredToNum) {
          setValidationError("Preferred From must be before Preferred To.");
          return;
        }

        // Check if preferred times are within the custom availability range
        if (preferredFromNum < startTimeNum || preferredToNum > endTimeNum) {
          setValidationError(
            "Preferred times must be within the custom availability range."
          );
          return;
        }
      }
    }

    const data = {
      dow: days.indexOf(props.day) + 1,
      available_from:
        selectedAvailability === "Custom" ? text_to_num(startTime) : 0,
      available_to:
        selectedAvailability === "Custom"
          ? text_to_num(endTime)
          : selectedAvailability === "All Day"
          ? 24
          : 0,
      preferred_from: preferredFrom ? text_to_num(preferredFrom) : 0,
      preferred_to: preferredTo ? text_to_num(preferredTo) : 0,
      note: note,
      activated_at: date,
    };
    console.log("DATA", data);
    props.create_availability_request(data);
    handleClose();
    setDrawerOpen(false);
  };

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  // For the effective date datepicker
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDatePicker() {
    setShowDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]); // Re-run the effect when 'showDatePicker' changes

  return (
    <>
      <tr key={props.day}>
        {/* <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm align-top">
          <div className="font-medium text-gray-900 mt-4">{props.day}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
          <div className="text-gray-900 mt-5">{props.day}</div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 align-top">
          <div>
            <p className="text-xs mb-0.5">Current</p>
            <DefaultAvailabilityCard
              day={props.day}
              availability={props.availability}
              preferences={props.preferences}
            />
          </div>
          {props.requested && props.requested.length > 0 && (
            <div>
              {props.requested.map((request, index) => (
                <div className="mt-3">
                  <p className="text-xs">
                    Requested{" "}
                    <span
                      className={`text-xxs rounded-full px-2 py-0.5 ${
                        request.status === "approved"
                          ? "bg-green-200 text-green-700"
                          : request.status === "pending"
                          ? "bg-yellow-200 text-yellow-700"
                          : "bg-red-200 text-red-700"
                      }`}
                    >
                      {request.status}
                    </span>
                  </p>
                  <p className="text-xxs text-gray-500 mb-0.5">
                    (In Effect: {formatDateReadable(request.inEffect)})
                  </p>
                  <div className="flex items-center space-x-0.5">
                    <DefaultAvailabilityCard
                      key={index}
                      day={props.day}
                      availability={request.availability}
                      preferences={request.preferences}
                      rescind_availability={props.rescind_availability}
                    />
                    <div>
                      {request.status === "pending" &&
                        <Tooltip title="Cancel Availability Request">
                          <IconButton
                            className="text-gray-500"
                            onClick={() =>
                              props.rescind_availability_request(request.id)
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      {(request.status === "approved" && props.permissionRole === "company_manager") &&
                        <Tooltip title="Cancel Availability Request">
                          <IconButton
                            className="text-gray-500"
                            onClick={() =>
                              props.rescind_availability_request(request.id)
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium align-top">
          <button
            className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg ml-8 mt-4"
            onClick={handleOpen}
          >
            Request Change
          </button>
        </td> */}
        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-slate-900">
          {props.day}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900 flex flex-col items-start justify-start">
          <div className="w-full">
            <DefaultAvailabilityCardUpdated
              day={props.day}
              availability={props.availability}
              preferences={props.preferences}
              status="current"
              inEffect={props.inEffect}
              permissionRole={props.permissionRole}
            />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-900">
          {props.requested && props.requested.length > 0 && (
            <div className="flex flex-col space-y-2 mb-2">
              {props.requested.map((request, index) => (
                <DefaultAvailabilityCardUpdated
                  key={index}
                  day={props.day}
                  availability={request.availability}
                  preferences={request.preferences}
                  status={request.status}
                  inEffect={request.inEffect}
                  permissionRole={props.permissionRole}
                  id={request.id}
                  note={request.note}
                  rescind_availability_request={
                    props.rescind_availability_request
                  }
                />
              ))}
            </div>
          )}
          <div
            className="w-full h-16 rounded-xl flex items-center justify-center border border-dashed border-slate-200 hover:border-slate-900 cursor-pointer"
            onClick={toggleDrawer(true)}
          >
            <p className="text-violet-700 text-xs">Request New Availability</p>
          </div>
        </td>
        <td className="whitespace-nowrap pl-3 pr-6 py-4 text-sm text-slate-500 text-right">
          {props.requested && props.requested.length > 0 && (
            <div>
              {props.requested.map((request, index) => (
                <div className="flex flex-col space-y-2">
                  <div className="text-slate-900 h-16 flex items-center justify-end">
                    {formatDateReadable(request.inEffect)}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="w-full h-16"></div>
        </td>
      </tr>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className="text-2xl font-semibold">
            {props.day} Availability Change
          </h2>
          <div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full flex items-center justify-between mt-4">
              <h3 className="text-base font-semibold">Existing Availability</h3>
              <DefaultAvailabilityCard
                day={props.day}
                availability={props.availability}
                preferences={props.preferences}
              />
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full flex items-center justify-between mt-4">
              <div>
                <h3 className="text-base font-semibold">
                  Request Availability
                </h3>
                <div className="mt-1">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={(event) => {
                        setValue(event.target.value);
                        setValidationError("");
                      }}
                    >
                      <FormControlLabel
                        value="All Day"
                        control={<Radio />}
                        label="All Day"
                      />
                      <FormControlLabel
                        value="Unavailable"
                        control={<Radio />}
                        label="Unavailable"
                      />
                      <FormControlLabel
                        value="Custom Availability"
                        control={<Radio />}
                        label="Custom Availability"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {value === "All Day" || value === "Unavailable" ? (
                <DefaultAvailabilityCard
                  day={props.day}
                  availability={value}
                  preferences={
                    addPreferences
                      ? `${format_time_num(
                          text_to_num(preferredFrom)
                        )} - ${format_time_num(text_to_num(preferredTo))}`
                      : ""
                  }
                />
              ) : (
                <DefaultAvailabilityCard
                  day={props.day}
                  availability={`${format_time_num(
                    text_to_num(startTime)
                  )} - ${format_time_num(text_to_num(endTime))}`}
                  preferences={
                    addPreferences
                      ? `${format_time_num(
                          text_to_num(preferredFrom)
                        )} - ${format_time_num(text_to_num(preferredTo))}`
                      : ""
                  }
                />
              )}
            </div>
            {value === "Custom Availability" && (
              <div className="w-full flex items-center justify-between mt-2 mb-8">
                <div>
                  <h3 className="text-base font-semibold">
                    Enter Custom Availability
                  </h3>
                  <div className="mt-4 flex items-center gap-5 relative">
                    <div className="w-44">
                      <TextField
                        size="small"
                        label="Start Time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                      {startTime && (
                        <p className="absolute -bottom-6 text-sm">
                          {format_time_num(text_to_num(startTime))}
                        </p>
                      )}
                    </div>
                    <div>to</div>
                    <div className="w-44">
                      <TextField
                        size="small"
                        label="End Time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                      />
                      {endTime && (
                        <p className="absolute -bottom-6 text-sm">
                          {format_time_num(text_to_num(endTime))}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {value !== "Unavailable" && (
              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addPreferences}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Add Preferences"
                  />
                </div>
              </div>
            )}
            {addPreferences && (
              <div className="mt-1 flex items-center gap-5 relative mb-8">
                <div className="w-44">
                  <TextField
                    size="small"
                    label="Preferred From"
                    value={preferredFrom}
                    onChange={(e) => setPreferredFrom(e.target.value)}
                  />
                  {preferredFrom && (
                    <p className="absolute -bottom-6 text-sm">
                      {format_time_num(text_to_num(preferredFrom))}
                    </p>
                  )}
                </div>
                <div>to</div>
                <div className="w-44">
                  <TextField
                    size="small"
                    label="Preferred To"
                    value={preferredTo}
                    onChange={(e) => setPreferredTo(e.target.value)}
                  />
                  {preferredTo && (
                    <p className="absolute -bottom-6 text-sm">
                      {format_time_num(text_to_num(preferredTo))}
                    </p>
                  )}
                </div>
              </div>
            )}
            {validationError && (
              <p className="text-red-500 text-sm">{validationError}</p>
            )}
            <div className="mt-4">
              <Divider />
            </div>
            <div className="w-full flex items-center justify-between mt-4">
              <h3 className="text-base font-semibold">In Effect Date</h3>
              <TextField
                type="date"
                size="small"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <Divider />
            </div>
            {/* <div className="w-full mt-4">
              <div>
                <TextField id="outlined-basic" label="Add Note" variant="outlined" multiline style={{ width: "100%" }} />
              </div>
            </div> */}
            <div className="w-full mt-6 flex justify-end">
              <div className="flex items-center gap-5">
                <button
                  className="px-8 py-3 rounded-lg border border-primary text-primary hover:bg-gray-100 text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="px-8 py-3 rounded-lg bg-primary hover:bg-blue-700 text-white text-sm"
                  onClick={() => handleSubmit()}
                >
                  Request
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div className="w-[450px] h-screen bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={`${props.day} New Request`}
              onClose={toggleDrawer(false)}
            />
            <div className="px-6">
              <h3 className="text-slate-900 text-base">Availability</h3>
              <p className="text-slate-500 mt-2">
                Choose your desired availability for {props.day}
              </p>
              <div className="mt-3 pb-2 border-b border-slate-200">
                <div className="bg-white border border-slate-200 rounded-2xl overflow-hidden">
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Unavailable")}
                  >
                    <p className="text-slate-900 text-sm">Unavailable</p>
                    {selectedAvailability === "Unavailable" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("All Day")}
                  >
                    <p className="text-slate-900 text-sm">All Day</p>
                    {selectedAvailability === "All Day" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                  <div
                    className="px-4 h-14 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                    onClick={() => setSelectedAvailability("Custom")}
                  >
                    <p className="text-slate-900 text-sm">Custom</p>
                    {selectedAvailability === "Custom" && (
                      <div className="text-violet-700">
                        <CheckIcon color="#6d28d9" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6">
                  {selectedAvailability === "Custom" && (
                    <div className="w-full flex items-center justify-between mb-6">
                      <div>
                        <div>
                          <p className="text-slate-500">
                            Set custom availability for {props.day}
                          </p>
                        </div>
                        <div className="mt-3 flex items-center relative bg-slate-100 rounded-2xl p-2">
                          <div className="w-[42%]">
                            <TextField
                              placeholder="Start Time"
                              size="small"
                              InputProps={{
                                sx: {
                                  backgroundColor: "white",
                                  borderRadius: "12px",
                                  paddingY: "5px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6d28d9",
                                  },
                                },
                              }}
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                            {startTime && (
                              <p className="absolute -bottom-6 text-sm">
                                {format_time_num(text_to_num(startTime))}
                              </p>
                            )}
                          </div>
                          <div className="text-slate-500 w-[16%] flex items-center justify-center">
                            <ArrowForwardIcon />
                          </div>
                          <div className="w-[42%]">
                            <TextField
                              placeholder="End Time"
                              size="small"
                              InputProps={{
                                sx: {
                                  backgroundColor: "white",
                                  borderRadius: "12px",
                                  paddingY: "5px",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#6d28d9",
                                  },
                                },
                              }}
                              value={endTime}
                              onChange={(e) => setEndTime(e.target.value)}
                            />
                            {endTime && (
                              <p className="absolute -bottom-6 text-sm">
                                {format_time_num(text_to_num(endTime))}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {selectedAvailability !== "Unavailable" && (
              <div className="px-6">
                <div className="pt-6 pb-6 border-b border-slate-200">
                  <div className="flex flex-row">
                    <div className="flex-1">
                      <h3 className="text-slate-900 text-base">Preferences</h3>
                      <p className="text-slate-500 mt-2">
                        Set your preferences (optional)
                      </p>
                    </div>
                    <PurpleSwitch
                      checked={preferencesChecked}
                      onChange={handlePreferencesChange}
                    />
                  </div>
                  {preferencesChecked && (
                    <div className="mt-3 flex items-center justify-between relative mb-2 bg-slate-100 p-2 rounded-2xl">
                      <div className="w-[42%]">
                        <TextField
                          placeholder="Preferred From"
                          size="small"
                          InputProps={{
                            sx: {
                              backgroundColor: "white",
                              borderRadius: "12px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                          value={preferredFrom}
                          onChange={(e) => setPreferredFrom(e.target.value)}
                        />
                        {preferredFrom && (
                          <p className="absolute -bottom-6 text-sm">
                            {format_time_num(text_to_num(preferredFrom))}
                          </p>
                        )}
                      </div>
                      <div className="text-slate-500 w-[16%] flex items-center justify-center">
                        <ArrowForwardIcon />
                      </div>
                      <div className="w-[42%]">
                        <TextField
                          placeholder="Preferred To"
                          size="small"
                          InputProps={{
                            sx: {
                              backgroundColor: "white",
                              borderRadius: "12px",
                              paddingY: "5px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                          value={preferredTo}
                          onChange={(e) => setPreferredTo(e.target.value)}
                        />
                        {preferredTo && (
                          <p className="absolute -bottom-6 text-sm">
                            {format_time_num(text_to_num(preferredTo))}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="px-6">
              <div className="py-5 border-b border-slate-200">
                <div>
                  <h3 className="text-slate-900 text-base">Effective Date</h3>
                  <p className="text-slate-500 mt-2">
                    When do you want this availability to go into effect?
                  </p>
                </div>
                {/* <div className="w-full mt-3 flex items-start">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <TextField
                      type="date"
                      size="small"
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "12px",
                          paddingY: "5px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="w-full mt-3">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <DatePickerDropdown
                      showDailyDatePicker={showDatePicker}
                      toggleDailyDatePicker={toggleDatePicker}
                      datePickerRef={datePickerRef}
                      setDate={setDate}
                      effectiveDate={true}
                      minEffectiveDate={props.minEffectiveDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-6">
              <div className="py-5 border-b border-slate-200">
                <div>
                  <h3 className="text-slate-900 text-base">Note</h3>
                  <p className="text-slate-500 mt-2">
                    Leave a note for your manager (optional)
                  </p>
                </div>
                <div className="w-full mt-3">
                  <div className="p-2 bg-slate-100 rounded-2xl">
                    <TextField
                      fullWidth
                      placeholder="eg: Starting school"
                      size="small"
                      multiline
                      rows={3}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "12px",
                          paddingY: "10px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[150px]"></div>
          </div>
          <DrawerFooterContainerSmall>
            <ButtonFilled text="Request" onClick={() => handleSubmit()} />
          </DrawerFooterContainerSmall>
        </div>
      </Drawer>
    </>
  );
}
