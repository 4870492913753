import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';

function TrackSidebarModuleDropdown(props) {

  const [showSectionsDropdown, setShowSectionsDropdown] = React.useState(false)

  function toggleShowSectionsDropdown() {
    setShowSectionsDropdown(prevState => !prevState)
  }

  React.useEffect(() => {
    if (props.selectedModuleIndex === props.index) {
      setShowSectionsDropdown(true)
    }
  }, [props.selectedSectionIndex])

  const moduleProgress = ((props.module.annotated_enrollment?.sections_completed_count / props.module.annotated_enrollment?.total_sections_count) * 100)


  return (
    <div>
      <div
        className="pl-2 pr-3 py-4 border-b border-slate-200 hover:bg-slate-100 flex items-center justify-between cursor-pointer relative"
        onClick={() => {
          toggleShowSectionsDropdown()
          // props.setSelectedModuleIndex(props.index)
          // props.setSelectedSectionIndex(null)
        }}
      >
        {props.selectedModuleIndex === props.index &&
          <div className="h-full w-1 bg-violet-700 absolute left-0"></div>
        }
        <div className="flex-1 flex items-center space-x-1.5">
          <div className="text-xxs text-slate-500">
            {showSectionsDropdown ? <ExpandMoreIcon style={{ fontSize: "20px" }} /> : <ChevronRightIcon style={{ fontSize: "20px" }} />}
          </div>
          <div>
            <p className="text-slate-900 text-xs">{props.module.name}</p>
            <p className="text-slate-500 text-xxs">{props.module.sections.length} {props.module.sections.length === 1 ? "section" : "sections"}</p>
          </div>
        </div>
        <div className="w-6 h-6 rounded-full bg-slate-300 flex items-center justify-center ml-3 relative">
          {props.type !== "Preview" &&
            <div className="absolute mt-[7px]">
              <CircularProgress
                size="24px"
                variant="determinate"
                value={moduleProgress}
                style={{ color: "#059669" }}
              />
            </div>
          }
          <div className={`w-5 h-5 rounded-full ${moduleProgress === 100 ? "bg-emerald-600" : "bg-white"} flex items-center justify-center text-white`}>
            {moduleProgress === 100 && <CheckIcon style={{ fontSize: "18px" }} />}
          </div>
        </div>
      </div>
      {showSectionsDropdown &&
        <div>
          {props.module.sections.map((section, index) => (
            <div
              key={index}
              className={`pl-6 pr-4 py-4 border-b border-slate-200 bg-slate-50 ${props.userType === "Manager" || (props.userType === "Employee" && index <= props.nextSectionIndex && props.index <= props.nextModuleIndex) ? "hover:bg-slate-100 cursor-pointer" : ""} flex items-center justify-between gap-x-3 ${
                props.selectedSectionIndex === index && props.selectedModuleIndex === props.index ? 'bg-slate-200' : ''
              }`}
              onClick={
                !props.isLoading && (props.userType === "Manager" || (props.userType === "Employee" && index <= props.nextSectionIndex && props.index <= props.nextModuleIndex))
                  ? () => {
                      props.removeAllBlocks();
                      props.setSelectedModuleIndex(props.index);
                      props.setSelectedSectionIndex(index);
                      props.fetchSectionDetail(true, section.uuid, props.selectedEmployee ? props.selectedEmployee?.id : null);
                      props.setSelectedSectionUUID(section.uuid);
                      props.setIsCurrentSectionCompleted(section.annotated_enrollment?.is_completed)
                    }
                  : null
              }
            >
              <div className="flex items-center space-x-2">
                <p className="text-slate-500 text-xs">{index + 1}.</p>
                <p className="text-slate-900 text-xs">{section.name}</p>
              </div>
              {props.type !== "Preview" ?
                <div>
                  {props.selectedSectionIndex === index && props.selectedModuleIndex === props.index ?
                  section.annotated_enrollment?.is_completed ?
                    <div className="w-4 h-4 rounded-full border bg-emerald-600 border-emerald-600 text-white flex items-center justify-center">
                      <CheckIcon style={{ fontSize: "14px" }} />
                    </div> :
                    <div className="w-4 h-4 rounded-full border bg-slate-200 border-violet-700 flex items-center justify-center">
                      <div className="w-2 h-2 rounded-full bg-violet-700"></div>
                    </div> :
                  section.annotated_enrollment?.is_completed ?
                    <div className="w-4 h-4 rounded-full border bg-emerald-600 border-emerald-600 text-white flex items-center justify-center">
                      <CheckIcon style={{ fontSize: "14px" }} />
                    </div> :
                    <div className="w-4 h-4 rounded-full border bg-white border-slate-300"></div>
                  }
                </div> :
                <div className="w-4 h-4 rounded-full border bg-white border-slate-300"></div>
              }
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default TrackSidebarModuleDropdown
