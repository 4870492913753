import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TradeTargetCard from "./TradeTargetCard";
import { formatDateReadable } from "../../utilities/helpers";
import DateGrayLine from "../helpers/DateGrayLine";
import ButtonFilled from "../helpers/ButtonFilled";
import ButtonGrayed from "../helpers/ButtonGrayed";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  py: 4,
};

function TradeTargetSelectModal(props) {
  const groupByDate = (array, originalShiftRoleType) => {
    return array?.reduce((result, item) => {
      // Extract the date from the item, assuming item.date is in the format 'YYYY-MM-DD'
      const date = item.date.split("T")[0];

      // Check if the roleType of the card matches the original shift's roleType
      if (item.roleType === originalShiftRoleType) {
        // Find an entry in the result array for the current date
        const existingEntry = result.find((entry) => entry.date === date);

        // If an entry was found, add this object to its cards array
        if (existingEntry) {
          existingEntry.cards.push({ ...item });
        }
        // Otherwise, create a new entry for this date
        else {
          result.push({
            date: date,
            cards: [{ ...item }],
          });
        }
      }

      return result;
    }, []);
  };

  let notMyUpcomingShifts = props.notMyUpcomingShifts.filter(
    (v, i) => v.status !== "AVAILABLE"
  );

  const tradeTargetDates = groupByDate(
    notMyUpcomingShifts,
    props.shiftInfo.roleType
  );

  const [tradeSelected, setTradeSelected] = React.useState(false);
  const [selectedCardId, setSelectedCardId] = React.useState(null);

  function handleTradeClick(id) {
    setTradeSelected(true);
    setSelectedCardId(id);
  }

  const handleSubmit = async () => {
    await props.trade_shift(props.shiftInfo.id, selectedCardId);
    props.handleClose();
  };

  // console.log("SHIFT INFO ID", props.shiftInfo.id);

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            className="absolute right-4 top-4 text-gray-500 cursor-pointer"
            onClick={props.handleClose}
          >
            <CloseIcon />
          </div>
          <div className="w-full flex justify-center mt-2">
            <h2 className="text-xl px-4">Select Shift To Trade For</h2>
          </div>
          <div className="w-full h-[500px] overflow-scroll">
            {tradeTargetDates.map((tradeTarget) => (
              <div className="w-full" key={tradeTarget.date}>
                <DateGrayLine date={formatDateReadable(tradeTarget.date)} />
                {tradeTarget.cards.map((card) => (
                  <div key={card.id} className="w-full">
                    <TradeTargetCard
                      id={card.id}
                      date={card.date}
                      name={card.name}
                      shift={card.shift}
                      shiftStartNumber={card.shift_start_formatted[0]}
                      shiftStartLetters={card.shift_start_formatted[1]}
                      shiftEndNumber={card.shift_end_formatted[0]}
                      shiftEndLetters={card.shift_end_formatted[1]}
                      station={card.station}
                      role={card.role}
                      roleType={card.roleType}
                      break={card.break_formatted}
                      location={card.location}
                      tradeSelected={tradeSelected}
                      selected={selectedCardId === card.id}
                      handleTradePress={() => handleTradeClick(card.id)}
                      roleId={card.role_id?.id}
                      roleNames={props.roleNames}
                    />
                  </div>
                ))}
              </div>
            ))}
            <div className="w-full h-[100px]"></div>
          </div>

          {/* {tradeSelected && (
              <button
                className="w-full h-28 bg-blue-900 absolute bottom-0 flex justify-center items-center rounded-xl"
                onClick={() => handleSubmit()}
              >
                <span className="text-white font-bold text-xl mb-4">Send Request</span>
              </button>
            )} */}
          <div className="w-full h-[90px] absolute bottom-0 border-t border-slate-300 flex items-center justify-center bg-white rounded-b-xl">
            <div className="px-4 w-full">
              {tradeSelected ? (
                <ButtonFilled
                  text="Send Request"
                  onClick={() => handleSubmit()}
                />
              ) : (
                <ButtonGrayed text="Send Request" />
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default TradeTargetSelectModal;
