import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { API_get_employee_clock_data } from "../../../constants";
import { getRightworkAPI } from "../requests/utilities/requests";
import PinEntry from "./PinEntry";
import TimeClockContents from "./TimeClockContents";
import LoadSpinner from "../../../utilities/LoadSpinner";


function TimeClockDisplay(props) {
  const [loggedInPin, setLoggedInPin] = useState("");
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetch_data = async (api_params, showLoading) => {
    setIsLoading(showLoading);
    let api_set_data_name = "employeeClockData";
    let api = API_get_employee_clock_data;
    try {
      console.log("CALLING APIS");
      const res = await getRightworkAPI(api, api_params);
      console.log(res);
      if (res.status === 200) {
        setIsAuthenticated(true);
        var tempData = { ...data };
        tempData[api_set_data_name] = res.data;
        setData(tempData);
        setLoggedInPin(api_params["clock_pin"]);
        setIsLoading(false);
      }
    } catch (error) {
      alert("Incorrect PIN"); // Provide feedback for incorrect PIN
      //throw Error("Promise failed");
    }
  };

  // Get Employee data using clock_pin parameter
  const get_employee_data = (clock_pin) => {
    let api_params = {
      store_id: props.homeStore,
      clock_pin: clock_pin,
    };

    fetch_data(api_params, true);
  };

  const employeeClockData = data.employeeClockData?.redux?.payload;

  console.log(employeeClockData);

  // State to track if the user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogout = () => {
    // Implementation for logging out (e.g., clear session, redirect to login page)
    setIsAuthenticated(false);
    // Add more logic as needed
  };


  const homeStoreObj = props.storeNames.find(store => store.id === props.homeStore);
  const homeStoreName = homeStoreObj.store_name


  if (!isAuthenticated) {
    return (
      <div>
        <PinEntry
          get_employee_data={get_employee_data}
          homeStoreName={homeStoreName}
        />

      </div>
    );
  } else {
    return (
      <div>
        <TimeClockContents
          handleLogout={handleLogout}
          employeeClockData={employeeClockData}
          get_employee_data={get_employee_data}
          loggedInPin={loggedInPin}
          homeStore={props.homeStore}
          homeStoreName={homeStoreName}
        />
      </div>
    );
  }
}

export default TimeClockDisplay;
