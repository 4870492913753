import React, { useEffect, useState } from 'react';
import { getRightworkAPI } from '../requests/utilities/requests';
import {
    API_get_stream_user_token,
    API_get_chat_users
} from '../../../constants';
import {
  Chat,
  Channel,
  ChannelList,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  useTranslationContext,
  useChatContext,
  useChannelStateContext
} from 'stream-chat-react';
import chatClient from './ChatClient';
import 'stream-chat-react/dist/css/v2/index.css';
import './ChatStyling2.css';
import CreateChannelModal from './CreateChannelModal';
import ChannelDetailsModal from './ChannelDetailsModal';
import EditIcon from '@mui/icons-material/Edit';
import LoadSpinner from '../../../utilities/LoadSpinner';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';


function ChatDisplay(props) {
  const [channel, setChannel] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [userID, setUserID] = useState(null);
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const [showChannelDetails, setShowChannelDetails] = useState(false)
  const [modalType, setModalType] = useState("Channel")
  const [modalEdit, setModalEdit] = useState(false)
  // For modal
  const [channelName, setChannelName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [currentChannelMembers, setCurrentChannelMembers] = useState()
  const [currentChannelName, setCurrentChannelName] = useState()
  const [channelID, setChannelID] = useState(null)
  const [userCanEditChannel, setUserCanEditChannel] = useState(false)

  // For tooltip
  const [showTooltip, setShowTooltip] = useState(false)

  // Chat users
  const [chatUsers, setChatUsers] = React.useState([]);

  const { setActiveChannel } = useChatContext();

  const fetchChatUserInfo = async () => {
    const storeID = props.homeStore;
    const streamUserToken = localStorage.getItem('streamUserToken');
    const shouldGenerateToken = !streamUserToken;

    let api_params = {
      store_id: storeID,
      should_generate_token: shouldGenerateToken,
    };
    let api = API_get_stream_user_token;
    try {
      const res = await getRightworkAPI(api, api_params);
      if (res.status === 200) {
        const data = res.data?.redux?.payload;
        setUserID(data.user_id);

        if (data.stream_user_token) {
          localStorage.setItem('streamUserToken', data.stream_user_token);
        } else {
          data.stream_user_token = streamUserToken;
        }
        return data;
      } else {
        throw new Error('Failed to fetch user info');
      }
    } catch (error) {
      console.error('Error fetching chat user info:', error);
      return null;
    }
  };

  const fetchChatUsers = async () => {
    let api_params = { store_id: props.homeStore };
    let api = API_get_chat_users;
    try {
      const res = await getRightworkAPI(api, api_params);
      console.log(res);
      if (res.status === 200) {
        const data = res.data?.redux?.payload;
        setChatUsers(data);
        console.log("CHAT USERS", data);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };


  useEffect(() => {
    const initChat = async () => {
      const user = await fetchChatUserInfo();
      if (user) {
        const userToken = user.stream_user_token;

        await chatClient.connectUser(
          {
            id: user.user_id.toString(),
            name: user.full_name,
            // image: 'URL_TO_USER_IMAGE',
          },
          userToken
        );

        setIsLoading(false);
      }
    };

    initChat();

    fetchChatUsers()

    return () => {
      chatClient.disconnectUser();
    };
  }, [props.homeStore]);

  console.log("CHAT USERS CHAT DISPLAY", chatUsers)


  const filters = { members: { $in: [userID] } };
  const options = { presence: true, state: true };
  const sort = { last_message_at: -1 };

  const userFilters = { id: { $in: chatUsers.map(user => user.id) } };
  console.log("USER FILTERS", userFilters)

  const handleCreateChannel = async (channelName, selectedMembers) => {
    const stringMembers = selectedMembers.map(member => member.id.toString());
    const newChannel = chatClient.channel('team', window.crypto.randomUUID(), {
      name: channelName,
      members: [userID.toString(), ...stringMembers],
    });
    try {
      await newChannel.create();
      setActiveChannel?.(newChannel);
      setShowCreateChannel(false);
    } catch (error) {
      console.error('Error creating channel:', error);
    }
  };

  const handleCreateDM = async (selectedMember) => {
    // const stringMembers = selectedMembers.map(member => member.toString());
    const newChannel = chatClient.channel('messaging', {
      members: [userID.toString(), selectedMember.id.toString()],
    });
    try {
      await newChannel.create();
      setActiveChannel?.(newChannel);
      setShowCreateChannel(false);
    } catch (error) {
      console.error('Error creating channel:', error);
    }
  };

  const handleEditChannel = async (channelID, updatedName) => {
    try {
      const channel = chatClient.channel('team', channelID);
      await channel.update({
        name: updatedName,
      });
      setCurrentChannelName(channel?.data?.name)

    } catch (error) {
      console.error('Error updating channel:', error);
    }
  };

  const handleAddMembersToChannel = async (channelID, selectedMembers) => {
    const stringMembers = selectedMembers.map(member => member.toString());
    try {
      const channel = chatClient.channel('team', channelID);
      await channel.addMembers(stringMembers);
      setCurrentChannelMembers(Object.values(channel?.state?.members))
    } catch (error) {
      console.error('Error updating channel:', error);
    }
  };

  const handleRemoveMemberFromChannel = async (channelID, selectedMember) => {
    try {
      const channel = chatClient.channel('team', channelID);
      await channel.removeMembers([selectedMember.toString()]);
      setCurrentChannelMembers(Object.values(channel?.state?.members))
    } catch (error) {
      console.error('Error updating channel:', error);
    }
  };

  const handleDeleteChannel = async (channelID) => {
    try {
      const channel = chatClient.channel('team', channelID);
      await channel.delete()
      setShowChannelDetails(false)
    } catch (error) {
      console.error('Error updating channel:', error);
    }
  };

  const CustomChannelHeader = (props) => {
    const { client } = useChatContext();
    const { channel, watcher_count } = useChannelStateContext();
    const member = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID,
    );

    const canEdit = channel?.data?.own_capabilities.includes("update-channel");

    const getWatcherText = (watchers) => {
      if (!watchers) return '0 online';
      if (watchers === 1) return '1 online';
      return `${watchers} online`;
    };


    return (
      <div className="py-4 px-6 border-b border-slate-100">
        {channel?.data?.name ? (
          <div className="flex items-center justify-between">
            <div
              className="flex gap-x-3 relative cursor-pointer"
              onClick={() => {
                setModalEdit(true);
                setModalType("Channel");
                setChannelName(channel?.data?.name);
                setSelectedMembers(Object.keys(channel?.state?.members));
                setCurrentChannelMembers(Object.values(channel?.state?.members))
                setCurrentChannelName(channel?.data?.name)
                setChannelID(channel?.id);
                setShowTooltip(false)
                setUserCanEditChannel(canEdit);
                setShowChannelDetails(true);
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <div className="w-10 h-10 bg-orange-600 text-white text-xl rounded-full flex items-center justify-center">
                #
              </div>
              <div>
                <p className="text-[18px] leading-[18px]">{channel?.data?.name || member?.[0]?.user?.name}</p>
                <div className="flex items-center gap-x-2 mt-0.5">
                  <p className="text-sm text-slate-500">{channel?.data?.member_count} members,</p>
                  <p className="text-sm text-slate-500">{getWatcherText(watcher_count)}</p>
                </div>
              </div>
              {showTooltip && (
                <div className="absolute top-10 left-10 z-50">
                  <CustomChannelMembersTooltip channel={channel} />
                </div>
              )}
            </div>
            {/* {canEdit && (
              <div
                className="w-10 h-10 rounded-full bg-slate-100 hover:bg-slate-200 text-slate-500 flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setModalEdit(true);
                  setModalType("channel");
                  setChannelName(channel?.data?.name);
                  setSelectedMembers(Object.keys(channel?.state?.members));
                  setCurrentChannelMembers(Object.values(channel?.state?.members))
                  setCurrentChannelName(channel?.data?.name)
                  setChannelID(channel?.id);
                  setUserCanEditChannel(canEdit)
                  setShowChannelDetails(true);
                }}
              >
                <EditIcon />
              </div>
            )} */}
          </div>
        ) : (
          <div className="flex gap-x-3">
            <div className="w-10 h-10 bg-violet-700 text-white text-xl rounded-full flex items-center justify-center">
              {member?.[0]?.user?.name?.[0]}
            </div>
            <div>
              <p className="text-[18px] leading-[18px]">{member?.[0]?.user?.name}</p>
              {watcher_count === 2 ? (
                <div className="flex items-center gap-x-1 mt-0.5">
                  <div className="w-2.5 h-2.5 bg-emerald-600 rounded-full"></div>
                  <p className="text-sm text-slate-500">Online</p>
                </div>
              ) : (
                <div className="flex items-center gap-x-1 mt-0.5">
                  <div className="w-2.5 h-2.5 bg-rose-700 rounded-full"></div>
                  <p className="text-sm text-slate-500">Offline</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const CustomChannelPreview = (props) => {
    const {
      channel,
      activeChannel,
      displayImage,
      displayTitle,
      latestMessage,
      setActiveChannel,
      type
    } = props;
    const latestMessageAt = channel.state.last_message_at;
    const isSelected = channel.id === activeChannel?.id;
    const { userLanguage } = useTranslationContext();
    const isChannel = channel.type === "team"
    const unreadMessageCount = channel.state.unreadCount
    const dmReceiverOnline = channel.state.watcher_count === 2

    const timestamp = React.useMemo(() => {
      if (!latestMessageAt) {
        return '';
      }

      const now = new Date();
      const messageDate = new Date(latestMessageAt);

      const isToday = now.toDateString() === messageDate.toDateString();
      const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === messageDate.toDateString();

      if (isToday) {
        const formatter = new Intl.DateTimeFormat(userLanguage, { timeStyle: 'short' });
        return formatter.format(messageDate);
      } else if (isYesterday) {
        return 'Yesterday';
      } else {
        const formatter = new Intl.DateTimeFormat(userLanguage, { month: 'numeric', day: 'numeric', year: '2-digit' });
        return formatter.format(messageDate);
      }
    }, [latestMessageAt, userLanguage]);


    const handleClick = () => {
      setActiveChannel?.(channel);
    };


    return (
      <div
        className={`py-4 px-6 hover:bg-slate-100 cursor-pointer ${isSelected ? 'bg-slate-200' : 'bg-white'}`}
        disabled={isSelected}
        onClick={handleClick}
      >
        {isChannel ?
          <div className="flex gap-x-3">
            <div className='w-7 h-7 bg-orange-600 text-white rounded-full flex items-center justify-center mt-1'>#</div>
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <p>{channel?.data?.name}</p>
                  {unreadMessageCount > 0 && <div className="w-3.5 h-3.5 rounded-full text-xxs bg-rose-600 text-white flex items-center justify-center mt-1">{unreadMessageCount}</div>}
                </div>
                <time dateTime={latestMessageAt?.toISOString()} className="text-slate-500 text-xs">
                  {timestamp}
                </time>
              </div>
              <p className="text-slate-500 text-sm">
                {latestMessage}
              </p>
            </div>
          </div> :
          <div className="flex gap-x-3">
            <div className='w-7 h-7 bg-violet-700 text-white rounded-full flex items-center justify-center mt-1 relative'>
              {displayTitle ? displayTitle[0] : ""}
              <div className={`absolute -bottom-0.5 -right-0.5 w-2.5 h-2.5 rounded-full ${dmReceiverOnline ? "bg-emerald-600" : "bg-rose-700"} border border-white`}></div>
            </div>
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <p>{displayTitle}</p>
                  {unreadMessageCount > 0 && <div className="w-3.5 h-3.5 rounded-full text-xxs bg-rose-600 text-white flex items-center justify-center mt-1">{unreadMessageCount}</div>}
                </div>
                <time dateTime={latestMessageAt?.toISOString()} className="text-slate-500 text-xs">
                  {timestamp}
                </time>
              </div>
              <p className="text-slate-500 text-sm">
                {latestMessage}
              </p>
            </div>
          </div>
        }
      </div>
    );
  };

  const CustomSearchResultItem = (props) => {
    const { result, index, focusedUser, selectResult } = props
    const isChannel = result.type === "team";

    return (
      <div
        className={`py-4 px-6 hover:bg-slate-100 cursor-pointer`}
        onClick={() => selectResult(result)}
      >
        {isChannel ? (
          <div className="flex gap-x-3">
            <div className='w-7 h-7 bg-orange-600 text-white rounded-full flex items-center justify-center'>#️</div>
            <p>{result.data?.name}</p>
          </div>
        ) : (
          <div className="flex gap-x-3">
            <div className='w-7 h-7 bg-purple-700 text-white rounded-full flex items-center justify-center'>{result.name ? result.name[0] : ""}</div>
            <p>{result.name ?? result.id}</p>
          </div>
        )}
      </div>
    );
  };

  const CustomChannelMembersTooltip = (props) => {

    let members = Object.values(props.channel?.state?.members);

    // Sort members by online status
    members.sort((a, b) => b.user.online - a.user.online);

    return (
      <div className="w-96 max-h-[700px] overflow-auto pt-4 pb-2 bg-white rounded-xl shadow-xl border-slate-300">
        <div className="w-full px-4 flex items-center justify-between">
          <h4 className="font-bold"># {props.channel?.data?.name}</h4>
          <div className="flex items-center gap-x-1">
            <PersonIcon style={{ fontSize: "20px" }} />
            <p>{members.length}</p>
          </div>
        </div>
        <div className="w-full px-4 py-2 border-b border-slate-200 flex items-center justify-between mt-2">
          <p className="text-sm text-slate-500">Name</p>
          <p className="text-sm text-slate-500">Status</p>
        </div>
        {members.map((member, index) => (
          <div
            key={index}
            className={`w-full px-4 py-2 ${index !== members.length - 1 ? 'border-b border-slate-200' : ''} flex items-center justify-between`}
          >
            <div className="flex items-center gap-x-4">
              <p className="text-sm">{member.user.name}</p>
              {member.role === "owner" && <div className="px-2 py-0.5 text-sm rounded bg-slate-100 text-slate-600">Owner</div>}
            </div>
            {member.user.online ? (
              <div className="flex items-center gap-x-1">
                <div className="w-3 h-3 bg-emerald-600 rounded-full"></div>
              </div>
            ) : (
              <div className="flex items-center gap-x-1">
                <div className="w-3 h-3 bg-rose-700 rounded-full"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };



  if (isLoading) return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <LoadSpinner />
    </div>
  )

  const canShowChannelSearch = props.permissionRole !== "company_employee" && props.permissionRole !== "company_manager";

  return (
    <>
      <div className="w-full h-screen flex">
        <div className="sidebar">
          <ChannelList
            showChannelSearch={canShowChannelSearch}
            additionalChannelSearchProps={{
              searchForChannels: true,
              SearchResultItem: CustomSearchResultItem,
              searchQueryParams: {
                channelFilters: { filters: filters },
                userFilters: { filters: userFilters }
              },
            }}
            Preview={CustomChannelPreview}
            sendChannelsToList
            sort={sort}
            filters={filters}
            options={options}
          />
          {props.permissionRole !== "company_employee" && props.permissionRole !== "company_manager" &&
            <div className="w-full flex items-center justify-between mb-2 px-4">
              <div
                className="bg-violet-50 hover:bg-violet-100 text-violet-700 w-full py-2.5 rounded-lg flex items-center justify-center gap-x-2 cursor-pointer"
                onClick={() => {
                  setModalEdit(false)
                  setModalType("Channel")
                  setChannelName('')
                  setSelectedMembers([])
                  setShowCreateChannel(true)
                }}
              >
                <div>
                  <AddIcon />
                </div>
                <p>Create New</p>
              </div>
            </div>
          }
        </div>
        <div className="chat-window">
          <Channel channel={channel}>
            <Window>
              <CustomChannelHeader />
              <MessageList />
              <MessageInput grow={true} />
            </Window>
            <Thread />
          </Channel>
        </div>
      </div>
      <CreateChannelModal
        open={showCreateChannel}
        handleClose={() => setShowCreateChannel(false)}
        handleCreateChannel={handleCreateChannel}
        handleEditChannel={handleEditChannel}
        channelName={channelName}
        setChannelName={setChannelName}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        channelID={channelID}
        handleCreateDM={handleCreateDM}
        modalType={modalType}
        setModalType={setModalType}
        modalEdit={modalEdit}
        homeStore={props.homeStore}
        chatUsers={chatUsers}
      />
      <ChannelDetailsModal
        open={showChannelDetails}
        handleClose={() => setShowChannelDetails(false)}
        handleCreateChannel={handleCreateChannel}
        handleEditChannel={handleEditChannel}
        channelName={channelName}
        setChannelName={setChannelName}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        currentChannelMembers={currentChannelMembers}
        setCurrentChannelMembers={setCurrentChannelMembers}
        currentChannelName={currentChannelName}
        channelID={channelID}
        handleAddMembersToChannel={handleAddMembersToChannel}
        handleRemoveMemberFromChannel={handleRemoveMemberFromChannel}
        modalType={modalType}
        modalEdit={modalEdit}
        userCanEditChannel={userCanEditChannel}
        homeStore={props.homeStore}
        handleDeleteChannel={handleDeleteChannel}
        chatUsers={chatUsers}
      />
    </>
  );
}

export default ChatDisplay;
