import React from "react";
import EmployeesTableRow from "./EmployeesTableRow";
import { API_get_certificates } from "../../../../../constants";
import { getRightworkAPI } from "../../utilities/requests";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerLarge from "../../shared/helpers/DrawerFooterContainerLarge";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ButtonOutlined from "../../shared/helpers/ButtonOutlined";
import ButtonDelete from "../../shared/helpers/ButtonDelete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import { getInitials } from "../../utilities/helpers";
import { getCardColor2 } from "../../../../../helpers/helpers";
import EmployeeProfile from "./EmployeeProfile";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { formatDateReadable } from "../../utilities/helpers";

export default function EmployeesTable(props) {
  const default_data = {
    first_name: "",
    last_name: "",
    email: "",
    phone: null,
    clock_pin: null,
    permission_type: "",
    stm_id: null,
    is_hidden: false,
    date_of_birth: null
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    {props.selectedTeamMemberData ?
      setModalData({
        first_name: props.selectedTeamMemberData?.first_name,
        last_name: props.selectedTeamMemberData?.last_name,
        email: props.selectedTeamMemberData?.email,
        phone: props.selectedTeamMemberData?.phone_number,
        clock_pin: props.selectedTeamMemberData?.clock_pin,
        permission_type: props.selectedTeamMemberData?.permission_type,
        stm_id: props.selectedTeamMemberData?.id,
        is_hidden: props.selectedTeamMemberData?.is_hidden,
        date_of_birth: props.selectedTeamMemberData?.date_of_birth
      }) :
      setModalData(default_data)
    }
  };
  const [modalData, setModalData] = React.useState(default_data);
  const [modalEdit, setModalEdit] = React.useState(false);



  console.log("MODALDATA", modalData)

  const [employees, setEmployees] = React.useState(props.employees);

  React.useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  React.useEffect(() => {
    props.setSelectedTeamMemberId(props.selectedTeamMemberData?.id)
    setModalData({
      first_name: props.selectedTeamMemberData?.first_name,
      last_name: props.selectedTeamMemberData?.last_name,
      email: props.selectedTeamMemberData?.email,
      phone: props.selectedTeamMemberData?.phone_number,
      clock_pin: props.selectedTeamMemberData?.clock_pin,
      permission_type: props.selectedTeamMemberData?.permission_type,
      stm_id: props.selectedTeamMemberData?.id,
      is_hidden: props.selectedTeamMemberData?.is_hidden,
      date_of_birth: props.selectedTeamMemberData?.date_of_birth
    });
  }, [props.selectedTeamMemberData]);

  React.useEffect(() => {
    setEmployees(props.employees);
    // props.setShowUserProfile(false);
  }, [props.homeStore]);

  const [isReordered, setIsReordered] = React.useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) return; // No destination, do nothing

    const newEmployees = Array.from(employees); // Create a copy of the original array
    const [reorderedItem] = newEmployees.splice(result.source.index, 1); // Remove the dragged item from its original position
    newEmployees.splice(result.destination.index, 0, reorderedItem); // Insert the dragged item into its new position
    setEmployees(newEmployees); // Update the state with the new order of employees
    setIsReordered(true);
  };

  const handleSaveOrder = () => {
    const reorderedData = employees.map((employee, index) => ({
      id: employee.id,
      sort_index: index,
    }));
    props.store_team_member_set_order(reorderedData);
    setIsReordered(false);
  };

  const handleDateChange = (newValue) => {
    const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
    setModalData((prevData) => ({
      ...prevData,
      date_of_birth: formattedDate,
    }));
  };

  function handleChange(evt) {
    const { name, value } = evt.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  }

  function handleSubmit() {
    props.post_store_team_member(
      modalData.first_name,
      modalData.last_name,
      modalData.email,
      modalData.phone,
      modalData.permission_type,
      modalData.stm_id,
      modalData.clock_pin,
      modalData.date_of_birth
    );
    handleClose();
  }


  let permissionInfo = {
    label: "",
    style: "",
  };
  if (props.selectedTeamMemberData?.permission_type === "company_admin") {
    permissionInfo = {
      label: "Admin",
      style: "border border-violet-600 bg-violet-50 text-violet-600",
    };
  } else if (props.selectedTeamMemberData?.permission_type === "company_manager") {
    permissionInfo = {
      label: "Manager",
      style: "border border-orange-600 bg-orange-50 text-orange-600",
    };
  } else if (props.selectedTeamMemberData?.permission_type === "company_timeclock") {
    permissionInfo = {
      label: "Time Clock",
      style: "border border-yellow-600 bg-yellow-50 text-yellow-700",
    };
  } else {
    permissionInfo = {
      label: "Employee",
      style: "border border-blue-600 bg-blue-50 text-blue-600",
    };
  }

  const avatarCardColor = props.selectedTeamMemberData?.primary_role_id ? getCardColor2(props.selectedTeamMemberData?.primary_role_id, props.roleNames, false) : "bg-slate-500";

  // const [certificateData, setCertificateData] = React.useState([])

  // const fetchCertificates = async (store_team_member_id) => {
  //   let api_params = {
  //     store_id: props.homeStore,
  //     store_team_member_id: store_team_member_id
  //   };
  //   let api = API_get_certificates;
  //   try {
  //     const res = await getRightworkAPI(api, api_params);
  //     if (res.status === 200) {
  //       setCertificateData(res.data.redux?.payload?.certificates)
  //     }
  //   } catch (error) {
  //     throw Error("Promise failed");
  //   }
  // };

  // Code to show date picker
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]);




  console.log("EMPLOYEES", props.employees)

  const [searchInput, setSearchInput] = React.useState("");

  const filteredEmployees = employees?.filter(employee => {
    const searchParts = searchInput?.toLowerCase()?.split(" ");
    return searchParts?.every(part =>
      employee?.first_name?.toLowerCase()?.includes(part) ||
      employee?.last_name?.toLowerCase()?.includes(part) ||
      employee?.email?.toLowerCase()?.includes(part)
    );
  });


  return (
    <>
      {!props.showUserProfile ?
        <div className="mt-8">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex items-center justify-between px-6">
              <div>
                <p className="text-slate-500 text-sm">Search by name or email</p>
                <input
                  className={`w-[250px] h-[40px] border border-slate-300 px-3 text-sm rounded-lg outline-none focus:border-2 focus:border-violet-700 mt-1`}
                  type="text"
                  placeholder="🔎  i.e. John Smith"
                  value={searchInput}
                  onChange={(event) =>
                    setSearchInput(event.target.value)
                  }
                />
              </div>
              <Tooltip title="Add employee">
                <div
                  className="h-10 w-10 rounded-full bg-violet-700 hover:bg-violet-800 text-white text-whiten flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setModalData(default_data);
                    setModalEdit(false);
                    handleOpen();
                  }}
                >
                  <AddIcon />
                </div>
              </Tooltip>
            </div>
            <div className="mt-8">
              <div className="mt-4 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 min-w-full">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-slate-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="w-[4%] py-3.5 pl-6 pr-3 text-left text-sm text-slate-500 font-medium"
                            >
                              <span className="sr-only">Drag Icon</span>
                            </th>
                            <th
                              scope="col"
                              className="w-[8%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              <span className="sr-only">Avatar</span>
                            </th>
                            <th
                              scope="col"
                              className="w-[10%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              First Name
                            </th>
                            <th
                              scope="col"
                              className="w-[14%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              Last Name
                            </th>
                            <th
                              scope="col"
                              className="w-[20%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="w-[12%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              Phone
                            </th>
                            {props.timeEntriesEnabled &&
                              <th
                                scope="col"
                                className="flex-1 px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                              >
                                Pin
                              </th>
                            }
                            <th
                              scope="col"
                              className="w-[12%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium"
                            >
                              Permission
                            </th>
                            {/* <th scope="col" className="w-[16%] px-3 py-3.5 text-left text-sm text-slate-500 font-medium">
                              Home Store
                            </th> */}
                            <th
                              scope="col"
                              className="w-[12%] relative py-3.5 pl-3 pr-6 text-end text-sm font-medium"
                            >
                              {isReordered ? (
                                <Tooltip title="Save this order of employees as default. If saved, employees will show up in this order in the weekly scheduling view (unless sorted or filtered otherwise).">
                                  <div className="w-full flex justify-end">
                                    <div
                                      className="px-5 py-1.5 bg-emerald-600 hover:bg-emerald-700 text-white rounded-full cursor-pointer"
                                      onClick={handleSaveOrder}
                                    >
                                      Save Order
                                    </div>
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title="You can drag and drop employees to reorder how they appear in the weekly scheduling view.">
                                  <div className="w-full flex justify-end">
                                    <div className="px-4 py-1.5 bg-slate-200 text-slate-400 rounded-full">
                                      Save Order
                                    </div>
                                  </div>
                                </Tooltip>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <Droppable droppableId="employees">
                          {(provided) => (
                            <tbody
                              className="divide-y divide-slate-200 bg-white"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {filteredEmployees?.map((person, index) => (
                                <Draggable
                                  key={String(person.id)}
                                  draggableId={String(person.id)}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <EmployeesTableRow
                                      key={person.id}
                                      provided={provided}
                                      snapshot={snapshot}
                                      person={person}
                                      id={person.id}
                                      firstName={person.first_name}
                                      lastName={person.last_name}
                                      email={person.email}
                                      phoneNumber={person.phone_number}
                                      is_hidden={person.is_hidden}
                                      clockPin={person.clock_pin}
                                      is_minor={person.is_minor}
                                      permissionType={person.permission_type}
                                      has_logged_in={person.user?.has_logged_in}
                                      hide_store_team_member={
                                        props.hide_store_team_member
                                      }
                                      primaryRoleId={person.primary_role_id}
                                      roleNames={props.roleNames}
                                      open={open}
                                      handleOpen={handleOpen}
                                      handleClose={handleClose}
                                      setModalData={setModalData}
                                      setModalEdit={setModalEdit}
                                      timeEntriesEnabled={props.timeEntriesEnabled}
                                      setShowUserProfile={props.setShowUserProfile}
                                      homeStore={props.homeStore}
                                      // fetchCertificates={fetchCertificates}
                                      fetchStoreTeamMember={props.fetchStoreTeamMember}
                                    />
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                      <div className="border-b border-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div> :
        <div>
          <EmployeeProfile
            selectedTeamMemberData={props.selectedTeamMemberData}
            avatarCardColor={avatarCardColor}
            permissionInfo={permissionInfo}
            timeEntriesEnabled={props.timeEntriesEnabled}
            roleNames={props.roleNames}
            certificateData={props.selectedTeamMemberData?.user?.certificates}
            handleOpen={handleOpen}
            setModalEdit={setModalEdit}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            updatePersonInfo={props.updatePersonInfo}
            post_certificate={props.post_certificate}
            delete_certificate={props.delete_certificate}
            archive_store_team_member={props.archive_store_team_member}
            setShowUserProfile={props.setShowUserProfile}
            post_logbook_employee_entry={props.post_logbook_employee_entry}
            delete_logbook_employee_entry={props.delete_logbook_employee_entry}
            get_file_upload={props.get_file_upload}
            homeCompany={props.homeCompany}
          />
        </div>
      }
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div className="w-[450px] h-full bg-slate-50 relative">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={modalEdit ? "Edit Employee Info" : "Add Employee"}
              onClose={handleClose}
            />
            <div className="px-6">
              <div className="mt-4">
                <p className="text-sm text-slate-500">First Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="John"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="first_name"
                    value={modalData?.first_name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Last Name</p>
                <div className="mt-1">
                  <TextField
                    placeholder="Smith"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="last_name"
                    value={modalData?.last_name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Email</p>
                <div className="mt-1">
                  <TextField
                    placeholder="email@email.com"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={modalData?.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Phone</p>
                <div className="mt-1">
                  <TextField
                    placeholder="(555) 555-5555"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="phone"
                    value={modalData?.phone}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              {props.timeEntriesEnabled &&
                <div className="mt-4">
                  <p className="text-sm text-slate-500">Pin</p>
                  <div className="mt-1">
                    <TextField
                      placeholder="1234"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="clock_pin"
                      value={modalData?.clock_pin}
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              }
              <div className="mt-4">
                <p className="text-sm text-slate-500">Permission Type</p>
                <div className="mt-1">
                  <Select
                    fullWidth
                    displayEmpty
                    value={modalData?.permission_type}
                    name="permission_type"
                    onChange={handleChange}
                    size="small"
                    sx={{
                      borderRadius: "8px",
                      paddingY: "1px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6d28d9",
                      },
                      height: "45px",
                      backgroundColor: "white",
                    }}
                  >
                    <MenuItem disabled value="">
                      Select Permission
                    </MenuItem>
                    <MenuItem key="company_admin" value="company_admin">
                      Admin
                    </MenuItem>
                    <MenuItem key="company_manager" value="company_manager">
                      Manager
                    </MenuItem>
                    <MenuItem key="company_employee" value="company_employee">
                      Employee
                    </MenuItem>
                    <MenuItem key="company_timeclock" value="company_timeclock">
                      Time Clock
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-slate-500">Date of Birth</p>
                <div className={`${!showDailyDatePicker ? "border border-white" : ""} flex-1 mt-1`}>
                  <div
                    className={`flex-1 bg-white rounded-lg text-sm ${showDailyDatePicker ? "border-2 border-violet-700" : "border border-[#bdbdbd] hover:border-slate-900"} cursor-pointer relative`}
                    ref={datePickerRef}
                  >
                    <div
                      className="w-full h-full px-3.5 py-2.5"
                      onClick={toggleDailyDatePicker}
                    >
                      <div
                        className="w-full h-full flex items-center justify-between"
                      >
                        <p className="text-[16px]">{modalData.date_of_birth ? formatDateReadable(modalData.date_of_birth) : "Click to select"}</p>
                        <div className="text-slate-500">
                          <CalendarTodayIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
                        </div>
                      </div>
                    </div>
                    {showDailyDatePicker && (
                      <div className="absolute bottom-12 left-0 bg-white z-10 flex items-center justify-center pl-4 pr-5 border border-[#bdbdbd] rounded-lg shadow-xl overflow-hidden">
                        <div className="mt-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                              <DemoItem>
                                <DateCalendar
                                  value={modalData?.date_of_birth ? dayjs(modalData?.date_of_birth) : null}
                                  onChange={handleDateChange}
                                  slotProps={{
                                    day: {
                                      sx: {
                                        "&.MuiPickersDay-root.Mui-selected": {
                                          backgroundColor: "#6d28d9",
                                        },
                                      },
                                    },
                                  }}
                                />
                              </DemoItem>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {modalData?.is_hidden && (
                <div className="mt-6 w-fit">
                  <div className="border border-rose-600 bg-rose-50 text-rose-600 px-3 h-10 rounded-lg flex items-center justify-center">
                    <p>hidden</p>
                  </div>
                </div>
              )}
            </div>
            <div className="h-[200px]"></div>
          </div>
          <div>
            <DrawerFooterContainerLarge>
              <div className="w-full">
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  onClick={() => handleSubmit()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {modalEdit ? "Save Employee" : "Add Employee"}
                </LoadingButton>
              </div>
              <div className="w-full mt-3">
                {modalEdit ? (
                  // <ButtonDelete
                  //   text="Delete"
                  //   // onClick={() => handleDelete()}
                  // /> :
                  <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
                ) : (
                  <ButtonOutlined text="Cancel" onClick={() => handleClose()} />
                )}
              </div>
            </DrawerFooterContainerLarge>
          </div>
        </div>
      </Drawer>
    </>
  );
}
