import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import VisualForecastTableSmall from "./VisualForecastTableSmall";
import { RosterGraph } from "./visual_components/RosterGraph";
import BatchGuidanceTable from "./BatchGuidanceTable";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { get_t_str } from "../../../../helpers/helpers";
import { get_t_str_shortened } from "../../../../helpers/helpers";
import { get_t_str_shortened_max } from "../../../../helpers/helpers";
import { process_schedule_data } from "../../../../helpers/helpers";
import { formatDateReadableWithDayOfWeek } from "../../requests/utilities/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  minHeight: 400,
  width: "99%",
  height: "99%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
};

function VisualDailyReportModal2(props) {
  // Helper function to convert qh_num to time format
  function convertToTime(qh_num) {
    const hours = Math.floor(qh_num);
    const minutes = Math.floor((qh_num - hours) * 60)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const schedule_data = process_schedule_data(
    props.actual_schedule,
    props.store_team_member_weekly_info,
    props.role_names
  );

  console.log("SCHEDULE DATA ABC", schedule_data);
  console.log("STORE TEAM MEMBER", props.store_team_member_weekly_info);

  // Initialize the show_non_coverage variable
  let show_non_coverage = false;
  // Check if any shift has exclude_from_labor set to true
  show_non_coverage = schedule_data?.some(
    (shift) => shift.exclude_from_labor === true
  );

  let openShiftCounter = 1;

  // Transform the array
  let transformed_schedule_data = schedule_data?.map((shift) => {
    if (shift.name === "") {
      shift.name = `Open Shift ${openShiftCounter}`;
      openShiftCounter++;
    }
    return shift;
  });

  transformed_schedule_data.sort((a, b) => {
    if (a.shift_start !== b.shift_start) {
      return a.shift_start - b.shift_start;
    }
    return a.shift_length - b.shift_length;
  });

  const stm_members_dict = {};
  const stm_is_minor_dict = {};
  props.store_team_member_weekly_info.forEach((member) => {
    stm_members_dict[member.id] = member.hours;
    stm_is_minor_dict[member.id] = member.is_minor;
  });

  const transformed_schedule_data2 = transformed_schedule_data?.map((item) => {
    const total_weekly_hours = stm_members_dict[item.store_team_member];
    const is_minor = stm_is_minor_dict[item.store_team_member];
    return {
      ...item,
      total_weekly_hours:
        total_weekly_hours !== undefined ? total_weekly_hours : null,
      is_minor: is_minor !== undefined ? is_minor : null,
    };
  });

  const groupedData = transformed_schedule_data2.reduce((acc, item) => {
    if (!acc[item.store_team_member]) {
      acc[item.store_team_member] = {
        store_team_member: item.store_team_member,
        name: item.name,
        total_weekly_hours: item.total_weekly_hours,
        is_minor: item.is_minor,
        shifts: [],
      };
    }

    acc[item.store_team_member].shifts.push({
      shift_start: item.shift_start,
      shift_end: item.shift_end,
      shift_length: item.shift_length,
      break_start: item.break_start,
      break_end: item.break_start + item.break_length,
      break_length: item.break_length,
      pre_shift: item.pre_shift,
      post_shift: item.post_shift,
      role_id: item.role_id,
      role_type: item.role_type,
      date: item.date,
      hours: item.hours,
      color: item.color,
      note: item.note,
      status: item.status,
      store: item.store,
      exclude_from_labor: item.exclude_from_labor,
    });

    return acc;
  }, {});

  const transformed_schedule_data3 = Object.values(groupedData);

  transformed_schedule_data3.sort((a, b) => {
    const startTimeA = a.shifts[0].shift_start;
    const startTimeB = b.shifts[0].shift_start;
    return startTimeA - startTimeB;
  });

  console.log("TRANSFORMED SCHEDULE DATA 3", transformed_schedule_data3);

  function get_weather_path(icon_string) {
    return process.env.PUBLIC_URL + "/images/weather/" + icon_string.slice(-7);
  }

  function formatName(name) {
    // Convert the name to lowercase and split it into words
    const words = name.toLowerCase().split(" ");

    // Capitalize the first letter of each word
    const formattedName = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back together with a space between them
    return formattedName.join(" ");
  }

  // create a ref for your component
  let componentRef = React.useRef();

  // Dynamically calculate start_qhnum and end_qhnum based on data
  const forecastedLabor =
    props.daily_intraday_metrics?.["Forecasted Labor"]?.filter(
      (i) => i.total_value > 0
    ) || [];
  const scheduledHours =
    props.daily_intraday_metrics?.["Scheduled Hours"]?.filter(
      (i) => i.total_value > 0
    ) || [];

  const allQhNums = [
    ...forecastedLabor.map((d) => d.qh_num),
    ...props.actual_schedule.map((d) => d.shift_start),
    ...props.actual_schedule.map((d) => d.shift_end),
  ];

  const start_qhnum = Math.floor(Math.min(...allQhNums));
  const end_qhnum = Math.ceil(Math.max(...allQhNums));

  const numOfHourBoxes = end_qhnum - start_qhnum;
  const widthOfEachHourBox = 100 / numOfHourBoxes;
  const widthOfEachHalfTick = widthOfEachHourBox / 2;
  const widthOfEachQuarterTick = widthOfEachHourBox / 4;

  const totalSales = props.daily_intraday_metrics?.["Sales"]?.reduce(
    (acc, obj) => acc + obj.total_value,
    0
  );
  const totalTransactions = props.daily_intraday_metrics?.[
    "Transactions"
  ]?.reduce((acc, obj) => acc + obj.total_value, 0);
  const totalItems = props.daily_intraday_metrics?.["Items"]?.reduce(
    (acc, obj) => acc + obj.total_value,
    0
  );
  const totalForecastedHours = props.daily_intraday_metrics?.[
    "Forecasted Hours"
  ]?.reduce((acc, obj) => acc + obj.total_value, 0);
  const totalScheduledHours = props.daily_intraday_metrics?.[
    "Scheduled Hours"
  ]?.reduce((acc, obj) => acc + obj.total_value, 0);

  // Filter metrics and guidance based on qh_num range
  const filteredMetrics = {};
  [
    "Sales",
    "Transactions",
    "Items",
    "Forecasted Labor",
    "Forecasted Hours",
    "Scheduled Labor",
    "Scheduled Hours",
  ].forEach((metric) => {
    if (props.daily_intraday_metrics?.[metric]) {
      filteredMetrics[metric] = props.daily_intraday_metrics[metric].filter(
        (i) => i.qh_num >= start_qhnum && i.qh_num < end_qhnum
      );
    }
  });

  const filteredBatchGuidance = {};
  Object.keys(props.daily_batch_guidance || {}).forEach((item) => {
    filteredBatchGuidance[item] = props.daily_batch_guidance[item].filter(
      (i) => i.qh_num >= start_qhnum && i.qh_num < end_qhnum
    );
  });

  function convertToK(num) {
    // Divide the number by 1000 to get the value in thousands
    let result = num / 1000;

    // Round the result to one decimal place
    result = Math.round(result * 10) / 10;

    // Return the result followed by 'k'
    return result + "k";
  }

  return (
    <div>
      <style jsx>{`
        .bg-diagonal-stripes::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: repeating-linear-gradient(
            45deg,
            #4c51bf,
            /* Tailwind indigo-600 */ #4c51bf 3px,
            #ffffff 3px,
            #ffffff 10px
          );
          opacity: 0.4;
          pointer-events: none;
        }
      `}</style>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={style}>
            <div
              className="absolute right-4 top-4 text-slate-500 cursor-pointer"
              onClick={props.handleClose}
            >
              <CancelIcon />
            </div>
            <div className="absolute left-[250px] top-[20px] text-slate-500 cursor-pointer">
              <ReactToPrint
                trigger={() => (
                  <IconButton>
                    <PrintIcon />
                  </IconButton>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="p-6" ref={componentRef}>
              <div>
                <h2 className="font-bold text-xl">Daily Coverage Report</h2>
                <h4 className="mt-1">
                  <span>{formatDateReadableWithDayOfWeek(props.date_str)}</span>
                </h4>
              </div>
              <div className="border border-slate-900 mt-6">
                <div className="w-full border-b border-slate-900 flex">
                  <div className="w-[35%] flex py-2 border-r border-slate-900">
                    <div className="w-[28%] flex items-center px-4">
                      <p className="text-slate-900 text-xxs font-bold">Name</p>
                    </div>
                    <div className="w-[30%] text-center flex items-center justify-center">
                      <p className="text-slate-900 text-xxs font-bold">
                        Scheduled
                      </p>
                    </div>
                    <div className="w-[12%] text-center flex items-center justify-center">
                      <p className="text-slate-900 text-xxs font-bold w-[85%]">
                        Break Start
                      </p>
                    </div>
                    <div className="w-[16%] text-center flex items-center justify-center">
                      <p className="text-slate-900 text-xxs font-bold w-[85%]">
                        Shift Duration
                      </p>
                    </div>
                    <div className="w-[14%] text-center flex items-center justify-center">
                      <p className="text-slate-900 text-xxs font-bold w-[85%]">
                        Hours Week
                      </p>
                    </div>
                  </div>
                  <div className="w-[65%] flex">
                    {Array.from({ length: numOfHourBoxes }).map((_, index) => (
                      <div
                        key={index}
                        className={`${
                          index < numOfHourBoxes - 1 ? "border-r" : ""
                        } border-slate-900 flex flex-col`}
                        style={{ width: `${widthOfEachHourBox}%` }}
                      >
                        <div className="h-[70%] flex items-center justify-center">
                          <p className="text-slate-900 text-xs">
                            {get_t_str_shortened_max(index + start_qhnum)}
                          </p>
                        </div>
                        <div className="h-[30%] flex">
                          <div className="w-[25%] border-r border-slate-900"></div>
                          <div className="w-[25%] border-r border-slate-900"></div>
                          <div className="w-[25%] border-r border-slate-900"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {transformed_schedule_data3?.map((employee, index) => (
                  <div
                    className={`w-full ${
                      index !== transformed_schedule_data3.length - 1
                        ? "border-b border-slate-900"
                        : ""
                    } ${index % 2 === 0 ? "bg-white" : "bg-slate-100"}`}
                  >
                    {employee?.shifts?.map((shift, index2) => (
                      <div className="w-full flex">
                        <div
                          className={`w-[35%] flex py-3 border-r border-slate-900 relative z-30`}
                        >
                          <div className="w-[28%] flex items-center px-4">
                            {index2 === 0 && (
                              <div className="flex items-center gap-x-1">
                                <p className="text-slate-900 text-xxs">
                                  {formatName(employee.name)}
                                </p>
                                {employee.is_minor && (
                                  <p className="text-xxs font-bold">[m]</p>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="w-[30%] text-center flex items-center justify-center">
                            <p className="text-slate-900 text-xxs">
                              {get_t_str_shortened(shift.shift_start)} -{" "}
                              {get_t_str_shortened(shift.shift_end)}
                            </p>
                          </div>
                          <div className="w-[12%] text-center flex items-center justify-center">
                            <p className="text-slate-900 text-xxs">
                              {shift.break_length > 0
                                ? get_t_str_shortened(shift.break_start)
                                : ""}
                            </p>
                          </div>
                          <div className="w-[16%] text-center flex items-center justify-center">
                            <p className="text-slate-900 text-xxs">
                              {shift.shift_length?.toFixed(2)}
                            </p>
                          </div>
                          <div className="w-[14%] text-center flex items-center justify-center">
                            <p className="text-slate-900 text-xxs">
                              {index2 === 0 &&
                                employee.total_weekly_hours?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                        <div className="w-[65%] flex items-center relative">
                          <div
                            className={`border border-slate-900 h-7 relative ${
                              shift.exclude_from_labor === true
                                ? "bg-diagonal-stripes"
                                : "bg-white"
                            }`}
                            style={{
                              marginLeft: `${
                                shift.shift_start * 4 * widthOfEachQuarterTick -
                                start_qhnum * 4 * widthOfEachQuarterTick
                              }%`,
                              width: `${
                                shift.shift_length * 4 * widthOfEachQuarterTick
                              }%`,
                            }}
                          ></div>
                          {shift.break_length > 0 && (
                            <div
                              className="border border-slate-900 bg-slate-300 h-7 absolute"
                              style={{
                                marginLeft: `${
                                  shift.break_start *
                                    4 *
                                    widthOfEachQuarterTick -
                                  start_qhnum * 4 * widthOfEachQuarterTick
                                }%`,
                                width: `${
                                  shift.break_length *
                                  4 *
                                  widthOfEachQuarterTick
                                }%`,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="w-full flex">
                <div className="w-[35%] py-2 border-r border-slate-300 flex justify-center items-center my-2">
                  <div className="flex gap-x-4">
                    <p className="text-slate-900 text-xxs font-bold">
                      Employee Type:
                    </p>
                    {/* <p className="text-slate-900 text-xxs">
                      <span className="font-bold">[g]</span> Guest
                    </p> */}
                    <p className="text-slate-900 text-xxs">
                      <span className="font-bold">[m]</span> Minor
                    </p>
                  </div>
                </div>
                <div className="w-[65%] flex justify-center items-center">
                  <div className="flex items-center gap-x-6">
                    <p className="text-slate-900 text-xxs font-bold">
                      Schedule:
                    </p>
                    <div className="flex items-center gap-x-2">
                      <div className="w-6 h-6 border border-slate-900"></div>
                      <p className="text-slate-900 text-xxs">Coverage</p>
                    </div>
                    {show_non_coverage && (
                      <div className="flex items-center gap-x-2">
                        <div className="w-6 h-6 border border-slate-900 relative bg-diagonal-stripes"></div>
                        <p className="text-slate-900 text-xxs">Non-coverage</p>
                      </div>
                    )}
                    <div className="flex items-center gap-x-2">
                      <div className="w-6 h-6 border border-slate-900 bg-slate-300"></div>
                      <p className="text-slate-900 text-xxs">Break</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-slate-900">
                <div className="w-full border-b border-slate-900 flex">
                  <div className="w-[35%] flex py-4 border-r border-slate-900">
                    <div className="w-full flex items-center px-4">
                      <p className="text-slate-900 text-xxs">
                        <span className="font-bold">Metrics </span>
                        <i className="text-xxs">
                          {/* Unfilled shifts are not included in the below metrics */}
                        </i>
                      </p>
                    </div>
                  </div>
                  <div className="w-[65%] flex">
                    {Array.from({ length: numOfHourBoxes }).map((_, index) => (
                      <div
                        key={index}
                        className={`${
                          index < numOfHourBoxes - 1 ? "border-r" : ""
                        } border-slate-900 flex flex-col`}
                        style={{ width: `${widthOfEachHourBox}%` }}
                      >
                        <div className="h-[70%] flex items-center justify-center">
                          <p className="text-slate-900 text-xs">
                            {get_t_str_shortened_max(index + start_qhnum)}
                          </p>
                        </div>
                        <div className="h-[30%] flex">
                          <div className="w-[50%] border-r border-slate-900"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {filteredMetrics.hasOwnProperty("Sales") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Sales
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {totalSales?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Sales
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Sales"]?.map((value, hourIndex) => {
                        return (
                          <div
                            key={hourIndex}
                            className={`${
                              hourIndex < numOfHourBoxes * 2 - 1
                                ? "border-r border-dashed"
                                : ""
                            } border-slate-200 flex flex-col`}
                            style={{ width: `${widthOfEachHalfTick}%` }}
                          >
                            <div className="h-[100%] flex items-center justify-center">
                              <p className="text-slate-900 text-xxs">
                                {value.total_value < 1000
                                  ? Math.round(value.total_value)
                                  : convertToK(value.total_value)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {filteredMetrics.hasOwnProperty("Transactions") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Transactions
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {Math.round(totalTransactions)}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Transactions
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Transactions"]?.map(
                        (value, hourIndex) => {
                          return (
                            <div
                              key={hourIndex}
                              className={`${
                                hourIndex < numOfHourBoxes * 2 - 1
                                  ? "border-r border-dashed"
                                  : ""
                              } border-slate-200 flex flex-col`}
                              style={{ width: `${widthOfEachHalfTick}%` }}
                            >
                              <div className="h-[100%] flex items-center justify-center">
                                <p className="text-slate-900 text-xxs">
                                  {Math.round(value.total_value)}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {filteredMetrics.hasOwnProperty("Items") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Items
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {Math.round(totalItems)}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Items
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Items"]?.map((value, hourIndex) => {
                        return (
                          <div
                            key={hourIndex}
                            className={`${
                              hourIndex < numOfHourBoxes * 2 - 1
                                ? "border-r border-dashed"
                                : ""
                            } border-slate-200 flex flex-col`}
                            style={{ width: `${widthOfEachHalfTick}%` }}
                          >
                            <div className="h-[100%] flex items-center justify-center">
                              <p className="text-slate-900 text-xxs">
                                {Math.round(value.total_value)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={`w-full h-5 flex`}>
                  <div
                    className={`w-[35%] flex py-2 border-r border-slate-900`}
                  ></div>
                  <div className="w-[65%] flex">
                    {filteredMetrics?.["Sales"]?.map((value, hourIndex) => {
                      return (
                        <div
                          key={hourIndex}
                          className={`${
                            hourIndex < numOfHourBoxes * 2 - 1
                              ? "border-r border-dashed"
                              : ""
                          } border-slate-200 flex flex-col`}
                          style={{ width: `${widthOfEachHalfTick}%` }}
                        >
                          <div className="h-[100%] flex items-center justify-center"></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {filteredMetrics.hasOwnProperty("Forecasted Hours") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Forecasted Hours
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {Math.round(totalForecastedHours)}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Forecasted Labor
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Forecasted Labor"]?.map(
                        (value, hourIndex) => {
                          return (
                            <div
                              key={hourIndex}
                              className={`${
                                hourIndex < numOfHourBoxes * 2 - 1
                                  ? "border-r border-dashed"
                                  : ""
                              } border-slate-200 flex flex-col`}
                              style={{ width: `${widthOfEachHalfTick}%` }}
                            >
                              <div className="h-[100%] flex items-center justify-center">
                                <p className="text-slate-900 text-xxs">
                                  {Math.round(value.total_value)}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {filteredMetrics.hasOwnProperty("Scheduled Hours") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Scheduled Hours
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {Math.round(totalScheduledHours)}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Scheduled Labor
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Scheduled Labor"]?.map(
                        (value, hourIndex) => {
                          return (
                            <div
                              key={hourIndex}
                              className={`${
                                hourIndex < numOfHourBoxes * 2 - 1
                                  ? "border-r border-dashed"
                                  : ""
                              } border-slate-200 flex flex-col`}
                              style={{ width: `${widthOfEachHalfTick}%` }}
                            >
                              <div className="h-[100%] flex items-center justify-center">
                                <p className="text-slate-900 text-xxs">
                                  {Math.round(value.total_value)}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                <div className={`w-full h-5 flex`}>
                  <div
                    className={`w-[35%] flex py-2 border-r border-slate-900`}
                  ></div>
                  <div className="w-[65%] flex">
                    {filteredMetrics?.["Sales"]?.map((value, hourIndex) => {
                      return (
                        <div
                          key={hourIndex}
                          className={`${
                            hourIndex < numOfHourBoxes * 2 - 1
                              ? "border-r border-dashed"
                              : ""
                          } border-slate-200 flex flex-col`}
                          style={{ width: `${widthOfEachHalfTick}%` }}
                        >
                          <div className="h-[100%] flex items-center justify-center"></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {filteredMetrics.hasOwnProperty("Sales") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Scheduled SPLH
                        </p>
                        <p className="text-slate-900 text-xxs">
                          $
                          {totalScheduledHours === 0
                            ? 0
                            : Math.round(
                                Math.round(totalSales) /
                                  Math.round(totalScheduledHours)
                              )}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Scheduled SPLH
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Sales"]?.map((value, hourIndex) => {
                        return (
                          <div
                            key={hourIndex}
                            className={`${
                              hourIndex < numOfHourBoxes * 2 - 1
                                ? "border-r border-dashed"
                                : ""
                            } border-slate-200 flex flex-col`}
                            style={{ width: `${widthOfEachHalfTick}%` }}
                          >
                            <div className="h-[100%] flex items-center justify-center">
                              <p className="text-slate-900 text-xxs">
                                {filteredMetrics?.["Scheduled Hours"]?.[
                                  hourIndex
                                ].total_value !== 0
                                  ? Math.round(
                                      value.total_value /
                                        filteredMetrics?.["Scheduled Hours"]?.[
                                          hourIndex
                                        ].total_value
                                    ) < 1000
                                    ? Math.round(
                                        value.total_value /
                                          filteredMetrics?.[
                                            "Scheduled Hours"
                                          ]?.[hourIndex].total_value
                                      )
                                    : convertToK(
                                        Math.round(
                                          value.total_value /
                                            filteredMetrics?.[
                                              "Scheduled Hours"
                                            ]?.[hourIndex].total_value
                                        )
                                      )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {filteredMetrics.hasOwnProperty("Transactions") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Scheduled TPLH
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {totalScheduledHours === 0
                            ? 0
                            : Math.round(
                                Math.round(totalTransactions) /
                                  Math.round(totalScheduledHours)
                              )}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Total Scheduled TPLH
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Transactions"]?.map(
                        (value, hourIndex) => {
                          return (
                            <div
                              key={hourIndex}
                              className={`${
                                hourIndex < numOfHourBoxes * 2 - 1
                                  ? "border-r border-dashed"
                                  : ""
                              } border-slate-200 flex flex-col`}
                              style={{ width: `${widthOfEachHalfTick}%` }}
                            >
                              <div className="h-[100%] flex items-center justify-center">
                                <p className="text-slate-900 text-xxs">
                                  {filteredMetrics?.["Scheduled Hours"]?.[
                                    hourIndex
                                  ].total_value !== 0
                                    ? Math.round(
                                        value.total_value /
                                          filteredMetrics?.[
                                            "Scheduled Hours"
                                          ]?.[hourIndex].total_value
                                      )
                                    : 0}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {filteredMetrics.hasOwnProperty("Items") && (
                  <div className={`w-full flex`}>
                    <div
                      className={`w-[35%] flex py-2 border-r border-slate-900`}
                    >
                      <div className="w-full flex items-center justify-between px-4">
                        <p className="text-slate-900 text-xxs w-[175px]">
                          Total Scheduled IPLH
                        </p>
                        <p className="text-slate-900 text-xxs">
                          {totalScheduledHours === 0
                            ? 0
                            : Math.round(
                                Math.round(totalItems) /
                                  Math.round(totalScheduledHours)
                              )}
                        </p>
                        <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                          Scheduled IPLH
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {filteredMetrics?.["Items"]?.map((value, hourIndex) => {
                        return (
                          <div
                            key={hourIndex}
                            className={`${
                              hourIndex < numOfHourBoxes * 2 - 1
                                ? "border-r border-dashed"
                                : ""
                            } border-slate-200 flex flex-col`}
                            style={{ width: `${widthOfEachHalfTick}%` }}
                          >
                            <div className="h-[100%] flex items-center justify-center">
                              <p className="text-slate-900 text-xxs">
                                {filteredMetrics?.["Scheduled Hours"]?.[
                                  hourIndex
                                ].total_value !== 0
                                  ? Math.round(
                                      value.total_value /
                                        filteredMetrics?.["Scheduled Hours"]?.[
                                          hourIndex
                                        ].total_value
                                    )
                                  : 0}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {Object.keys(filteredBatchGuidance).length > 0 && (
                <div className="border border-slate-900 mt-12">
                  <div className="w-full border-b border-slate-900 flex">
                    <div className="w-[35%] flex py-4 border-r border-slate-900">
                      <div className="w-full flex items-center px-4">
                        <p className="text-slate-900 text-xxs">
                          <span className="font-bold">
                            Forecasted Item Quantities{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="w-[65%] flex">
                      {Array.from({ length: numOfHourBoxes }).map(
                        (_, index) => (
                          <div
                            key={index}
                            className={`${
                              index < numOfHourBoxes - 1 ? "border-r" : ""
                            } border-slate-900 flex flex-col`}
                            style={{ width: `${widthOfEachHourBox}%` }}
                          >
                            <div className="h-[100%] flex items-center justify-center">
                              <p className="text-slate-900 text-xs">
                                {get_t_str_shortened_max(index + start_qhnum)}
                              </p>
                            </div>
                            {/* <div className="h-[30%] bg-red-200 flex">
                            <div className="w-[50%] border-r border-slate-900"></div>
                          </div> */}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {Object.keys(filteredBatchGuidance).map((item, index) => {
                    const guidance = filteredBatchGuidance[item];
                    const totalSum = guidance.reduce(
                      (acc, obj) => acc + obj.total_value,
                      0
                    );
                    return (
                      <div className={`w-full flex`} key={index}>
                        <div
                          className={`w-[35%] flex py-2 border-r border-slate-900`}
                        >
                          <div className="w-full flex items-center justify-between px-4">
                            <p className="text-slate-900 text-xxs w-[175px]">
                              Total {item}
                            </p>
                            <p className="text-slate-900 text-xxs">
                              {Math.round(totalSum)}
                            </p>
                            <p className="text-slate-900 text-xxs w-[175px] flex justify-end">
                              {item}
                            </p>
                          </div>
                        </div>
                        <div className="w-[65%] flex">
                          {guidance?.map((value, hourIndex) => {
                            return (
                              <div
                                key={hourIndex}
                                className={`${
                                  hourIndex < numOfHourBoxes - 1
                                    ? "border-r border-dashed"
                                    : ""
                                } border-slate-200 flex flex-col`}
                                style={{ width: `${widthOfEachHourBox}%` }}
                              >
                                <div className="h-[100%] flex items-center justify-center">
                                  <p className="text-slate-900 text-xxs">
                                    {Math.round(value.total_value)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default VisualDailyReportModal2;
