import React from 'react'

function TimeOffCard(props) {
  // Set the maximum length for the title
  const maxLength = 54;
  // Truncate the title if it exceeds the maximum length
  const truncatedTitle = props.title.length > maxLength ? props.title.substring(0, maxLength) + '...' : props.title;

  return (
    <div className="w-full h-full py-3 px-3 text-slate-900 hover:text-black bg-red-200 rounded-lg shadow-md flex flex-col justify-center">
      <p className="text-xs font-bold xl:font-normal">Time Off</p>
      {!props.minimizeMode && <p className="text-xxs">{truncatedTitle}</p>}
    </div>
  )
}

export default TimeOffCard
