import axios from "axios";

/*
 * Returns axios config object with authentication Token.
 */
export async function getDefaultAxiosConfig() {
  try {
    const token = localStorage.getItem("token");
    return {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
  } catch (error) {
    // Handle error while retrieving token
    console.error("Error retrieving authentication token:", error);
    return {
      headers: {},
    };
  }
}

export async function getRightworkAPI(url, data_obj) {
  const params = new URLSearchParams(data_obj).toString();
  console.log(url + "?" + params);

  try {
    const axiosConfig = await getDefaultAxiosConfig();
    const res = await axios.get(url + "?" + params, axiosConfig);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function postRightworkAPI(url, data_obj) {
  const axiosConfig = await getDefaultAxiosConfig();
  const res = await axios.post(url, data_obj, axiosConfig);
  return res;
}
