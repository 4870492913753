import React, { useEffect } from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import OpenShiftInputsModal from "./OpenShiftInputsModal";
import { getCardColor, getCardColor2, getRoleType } from "../../../../helpers/helpers";

function OpenShiftCard(props) {
  // Shows modal when set to true
  const [open, setOpen] = React.useState(false);

  const [showActionButtons, setShowActionButtons] = React.useState(false);
  //const [showPasteButton, setShowPasteButton] = React.useState(false);
  const [showCopiedCheck, setShowCopyCheck] = React.useState(false);

  // Shows the check when they have successfully cut a shift when true
  const [showCutCheck, setShowCutCheck] = React.useState(false);

  // Function for opening the modal
  const handleOpen = () => {
    console.log(props.shift_info);
    setOpen(!props.locked_mode);
  };

  // Function for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
  };

  // Function for handling right-click context menu
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (showActionButtons) {
      props.setCopiedShift(null);
      setShowActionButtons(false);
    } else {
      setShowActionButtons((prevState) => !prevState);
    }
  };

  // Handle copy in copy and paste
  const handleCopy = (shift) => {
    if (showCopiedCheck === false) {
      shift["copy_type"] = "copy";
      props.setCopiedShift(shift);
      setShowCopyCheck(true);
      setShowCutCheck(false);
    } else {
      props.setCopiedShift(null);
      setShowCopyCheck(false);
      setShowCutCheck(false);
    }
  };

  const handleCut = (shift) => {
    if (showCutCheck === false) {
      shift["copy_type"] = "cut";
      props.setCopiedShift(shift);
      setShowCutCheck(true);
      setShowCopyCheck(false);
    } else {
      props.setCopiedShift(null);
      setShowCutCheck(false);
      setShowCopyCheck(false);
    }
  };

  useEffect(() => {
    if (props.copiedShift !== props.shift_info) {
      setShowActionButtons(false);
      setShowCopyCheck(false);
    }
  }, [props.copiedShift]);

  // Function to get role name
  const getRoleName = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_name"];
    } else {
      return null;
    }
  };

  // const shiftCardColor = getCardColor(
  //   props.shift_info.role_id,
  //   props.role_names,
  //   props.role_types,
  //   props.locked_mode
  // );

  const shiftCardColor = getCardColor2(
    props.shift_info.role_id,
    props.role_names,
    props.locked_mode,
    true
  )

  const handleDragStart = (e, shift) => {
    //e.dataTransfer.setData("shift", JSON.stringify(shift));
    props.setCopiedShift(shift);
  };

  const minimizeMode = true;

  return (
    <div className="w-full min-w-full relative">
      {!showActionButtons ? (
        <div
          onClick={handleOpen}
          className={
            "w-full min-w-full flex overflow-hidden bg-white rounded-lg shadow-md " +
            (props.weekly_or_daily === "weekly" ? "max-w-sm" : "")
          }
          onContextMenu={(event) => handleContextMenu(event)}
          draggable={!props.locked_mode}
          onDragStart={(e) => handleDragStart(e, props.shift_info)}
          onDragEnd={(e) => props.setCopiedShift(null)}
        >
          <div
            className={`w-full flex items-center h-7 px-2 text-white relative ${shiftCardColor}`}
          >
            <div className="mx-1 w-full">
              {props.weekly_or_daily === "weekly" ? (
                <div>
                  <p className="text-xs font-bold xl:font-normal">
                    {props.get_t_str(props.shift_info.shift_start) +
                      " - " +
                      props.get_t_str(props.shift_info.shift_end)}
                  </p>
                  {!minimizeMode && (
                    <p className="text-xxxs">
                      {props.shift_info.break_length > 0
                        ? "Break: " +
                          props.get_t_str(props.shift_info.break_start) +
                          " (" +
                          props.shift_info.break_length +
                          "hr)"
                        : "Break: None"}
                    </p>
                  )}
                  {getRoleName(props.shift_info.role_id, props.role_names)
                    ? !minimizeMode && (
                        <p className="text-xxxs xl:hidden">
                          {getRoleName(
                            props.shift_info.role_id,
                            props.role_names
                          )}
                        </p>
                      )
                    : !minimizeMode && (
                        <p className="text-xxxs">
                          {props.shift_info.hours} hrs
                        </p>
                      )}
                </div>
              ) : (
                <div className="h-full flex items-center gap-4 pl-3">
                  <p className="text-xs font-bold xl:font-normal">
                    {props.get_t_str(props.shift_info.shift_start) +
                      " - " +
                      props.get_t_str(props.shift_info.shift_end)}
                  </p>
                  {getRoleName(props.shift_info.role_id, props.role_names) ? (
                    <p className="text-xs xl:hidden">
                      {getRoleName(props.shift_info.role_id, props.role_names)}
                    </p>
                  ) : (
                    <p className="text-xs">{props.shift_info.hours} hrs</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          // onClick={handleOpen}
          className={
            "w-full min-w-full flex overflow-hidden bg-white rounded-lg shadow-md " +
            (props.weekly_or_daily === "weekly" ? "max-w-sm" : "")
          }
          onContextMenu={(event) => handleContextMenu(event)}
          // draggable={!props.locked_mode}
          // onDragStart={(e) => handleDragStart(e, props.shift_info)}
          // onDragEnd={(e) => props.setCopiedShift(null)}
        >
          <div
            className={`w-full flex items-center h-7 px-2 text-white relative ${shiftCardColor}`}
          >
            <div className="mx-1 w-full">
              <div className="w-full flex items-center justify-between space-x-1.5">
                <Tooltip title={"Edit or add shift"} arrow>
                  <div
                    className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 flex items-center justify-center"
                    onClick={handleOpen}
                  >
                    <EditIcon style={{ fontSize: "14px" }} />
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    !showCopiedCheck
                      ? "Copy shift"
                      : "Shift copied. When you paste this shift to another slot, the original will remain"
                  }
                  arrow
                >
                  <div
                    className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 flex items-center justify-center"
                    onClick={() => handleCopy(props.shift_info)}
                  >
                    {!showCopiedCheck ? (
                      <ContentCopyIcon style={{ fontSize: "14px" }} />
                    ) : (
                      <CheckIcon style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    !showCutCheck
                      ? "Cut shift"
                      : "Shift cut. When you paste this shift to another slot, the original will disappear"
                  }
                  arrow
                >
                  <div
                    className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 flex items-center justify-center"
                    onClick={() => handleCut(props.shift_info)}
                  >
                    {!showCutCheck ? (
                      <ContentCutIcon style={{ fontSize: "14px" }} />
                    ) : (
                      <CheckIcon style={{ fontSize: "20px" }} />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {showActionButtons && !showCopiedCheck && (
        <div
          className={`absolute ${
            minimizeMode ? "-left-6 bottom-1" : "-left-7 bottom-5"
          }`}
        >
          <Tooltip title={"Copy Shift"}>
            <IconButton onClick={() => handleCopy(props.shift_info)}>
              <ContentCopyIcon style={{ fontSize: "14px" }} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {showCopiedCheck && (
        <div
          className={`absolute ${
            minimizeMode ? "-left-6 bottom-1" : "-left-7 bottom-5"
          }`}
        >
          <Tooltip title={"Shift Copied!"}>
            <IconButton disabled={true}>
              <CheckIcon style={{ fontSize: "14px" }} />
            </IconButton>
          </Tooltip>
        </div>
      )} */}
      <OpenShiftInputsModal
        open={open}
        handleClose={handleClose}
        shift_info={props.shift_info}
        shifts_info={[props.shift_info]}
        p={props.p}
        new_shift={false}
        addShift={props.addShift}
        date_name={props.date_name}
        get_t_str={props.get_t_str}
        role_names={props.role_names}
        getRoleName={getRoleName}
        getRoleType={getRoleType}
        saveShiftFinished={props.saveShiftFinished}
        setSaveShiftFinished={props.setSaveShiftFinished}
        d={props.d}
        role_types={props.role_types}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
      />
    </div>
  );
}

export default OpenShiftCard;

/*

      <Stack direction="row" spacing={1}>
        <div>{props.start_time + "-" + props.stop_time}</div>
        <div>{props.hours}</div>
        <div>{props.role}</div>
      </Stack>

      */
