import React from "react";
import TabsTwo from "../../shared/helpers/TabsTwo";
import ShiftsTableEmployee from "../shiftswaps/ShiftsTableEmployee";
import CircleIcon from "@mui/icons-material/Circle";
import { formatDate, dateObjToShortStr } from "../../utilities/helpers";
import { add_shift_attributes } from "../../utilities/helpers";
import { formatDateShift } from "../../utilities/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { getInitials } from "../../utilities/helpers";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import EmployeeShiftCardToday from "./EmployeeShiftCardToday";
import EmployeeShiftCardUpdated from "../../shared/shiftswaps/EmployeeShiftCardUpdated";
import RequestHeader from "../../shared/helpers/RequestHeader";
import NoShiftBar from "../../shared/helpers/NoShiftBar";

export default function HomeDisplayEmployee(props) {
  const [activeTab, setActiveTab] = React.useState("Your Shifts");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  const [isModalVisible, setModalVisible] = React.useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const today = new Date();

  let myShiftsToday = props.myUpcomingShifts.filter(
    (v, i) => v.date === formatDate(today)
  );

  myShiftsToday = myShiftsToday.map((v, i) => add_shift_attributes(v));

  let myShiftsUpcoming = props.myUpcomingShifts.filter(
    (v, i) => v.date > formatDate(today)
  );

  myShiftsUpcoming = myShiftsUpcoming.sort((a, b) => a.date - b.date);

  myShiftsUpcoming = myShiftsUpcoming.map((v, i) => add_shift_attributes(v));


  const filterScheduleData = (scheduleData, selectedDate, myShiftToday) => {
    let dayScheduleData = [];
    if (scheduleData) {
      dayScheduleData = scheduleData.filter((v, i) => v.date === selectedDate);
    }
    dayScheduleData = dayScheduleData.map((v, i) => add_shift_attributes(v));

    let alsoWorking = dayScheduleData.filter(
      (v, i) => v.store_team_member?.id !== props.myId
    );

    alsoWorking = alsoWorking.filter(
      (v, i) =>
        (v.shift_start < myShiftToday.shift_end &&
          v.shift_end > myShiftToday.shift_start) ||
        (v.shift_start < myShiftToday.shift_start &&
          v.shift_end > myShiftToday.shift_start) ||
        (v.shift_start < myShiftToday.shift_end &&
          v.shift_end > myShiftToday.shift_end)
    );

    return alsoWorking;
  };

  const alsoWorking = filterScheduleData(
    props.scheduleData,
    myShiftsToday[0]?.date,
    myShiftsToday[0]
  );

  function getGreeting() {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  let notMyUpcomingShifts = props.notMyUpcomingShifts.map((v, i) =>
    add_shift_attributes(v)
  );

  return (
    <>
      {props.scheduleData ? (
        <div>
          <div className="mt-12 px-6">
            <p className="text-slate-600">{dateObjToShortStr(today)}</p>
            <h2 className="text-slate-900 text-4xl font-bold mt-3">
              {getGreeting()}, {props.firstName}
            </h2>
          </div>
          <div className="mt-8 px-6">
            <p className="text-slate-600 text-base">Today</p>
            {myShiftsToday.length > 0 ? (
              <div className="flex items-center gap-x-5">
                {myShiftsToday?.map((shift, index) => (
                  <div key={index} className="mt-5 w-96">
                    <EmployeeShiftCardToday
                      key={index}
                      shiftStartNumber={shift.shift_start_formatted[0]}
                      shiftStartLetters={shift.shift_start_formatted[1]}
                      shiftEndNumber={shift.shift_end_formatted[0]}
                      shiftEndLetters={shift.shift_end_formatted[1]}
                      role={shift.role}
                      roleType={shift.role_type}
                      station={shift.station?.name}
                      location={shift.location}
                      break={shift.break_formatted}
                      alsoWorking={alsoWorking}
                      roleNames={props.roleNames}
                      note={shift.note}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-5 w-96">
                <NoShiftBar text="No shift today, enjoy your day off!" />
              </div>
            )}
          </div>
          <div className="w-full mt-8 px-6">
            <div className="w-full">
              <p className="text-slate-600 text-base">
                {myShiftsUpcoming.length > 0
                  ? "Upcoming"
                  : "No Shifts Upcoming"}
              </p>
            </div>
            <div className="flex items-center gap-x-5 flex-wrap pb-10">
              {myShiftsUpcoming.map((shift, index) => (
                <div key={index} className="mt-5 w-96">
                  <EmployeeShiftCardUpdated
                    key={index}
                    firstName={shift.store_team_member?.first_name}
                    lastName={shift.store_team_member?.last_name}
                    date={shift.date}
                    dateFormatted={formatDateShift(shift.date)}
                    shiftStartNumber={shift.shift_start_formatted[0]}
                    shiftStartLetters={shift.shift_start_formatted[1]}
                    shiftEndNumber={shift.shift_end_formatted[0]}
                    shiftEndLetters={shift.shift_end_formatted[1]}
                    role={shift.role}
                    station={shift.station?.name}
                    roleType={shift.roleType}
                    location={shift.location}
                    break={shift.break_formatted}
                    shift={shift}
                    notMyUpcomingShifts={notMyUpcomingShifts}
                    drop_shift={props.drop_shift}
                    trade_shift={props.trade_shift}
                    rescind_drop_shift={props.rescind_drop_shift}
                    rescind_trade_shift={props.rescind_trade_shift}
                    roleId={shift.role_id}
                    roleNames={props.roleNames}
                    note={shift.note}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
