import React from "react";
import Stack from "@mui/material/Stack";
import LoadScheduleButtons from "./LoadScheduleButtons";
import AutoScheduleButtons from "./AutoScheduleButtons";
import Divider from "@mui/material/Divider";

function EditScheduleButtonsGrouping(props) {
  return (
    <div>
      <Stack
        direction="row"
        spacing={4}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <LoadScheduleButtons
          week_already_started={props.week_already_started}
          loadFrom7Shift={props.loadFrom7Shift}
          loadFromTemplate={props.loadFromTemplate}
          template_names={props.template_names}
          week_start_date={props.week_start_date}
          deleteScheduleTemplate={props.deleteScheduleTemplate}
          load7ShiftsScheduleFinished={props.load7ShiftsScheduleFinished}
          setLoad7ShiftsScheduleFinished={props.setLoad7ShiftsScheduleFinished}
          loadFromTemplateFinished={props.loadFromTemplateFinished}
          setLoadFromTemplateFinished={props.setLoadFromTemplateFinished}
          loadedFrom7Shifts={props.loadedFrom7Shifts}
          loadedFromTemplate={props.loadedFromTemplate}
          has7ShiftsIntegration={props.has7ShiftsIntegration}
        />
        <AutoScheduleButtons
          week_ended={props.week_ended}
          autoSchedule={props.autoSchedule}
          autosched_available={props.autosched_available}
          acceptNewSchedule={props.acceptNewSchedule}
          schedule_differences={props.schedule_differences}
          autoScheduleFinished={props.autoScheduleFinished}
          autoScheduleError={props.autoScheduleError}
          setAutoScheduleError={props.setAutoScheduleError}
          setAutoScheduleFinished={props.setAutoScheduleFinished}
          acceptAutoScheduleFinished={props.acceptAutoScheduleFinished}
          setAcceptAutoScheduleFinished={props.setAcceptAutoScheduleFinished}
          loadedFromAuto={props.loadedFromAuto}
          lockAllShifts={props.lockAllShifts}
          setLockAllShifts={props.setLockAllShifts}
          store_team_member_weekly_info={props.store_team_member_weekly_info}
          get_t_str={props.get_t_str}
          actual_schedule={props.actual_schedule}
          role_types={props.role_types}
          role_names={props.role_names}
        />
      </Stack>
    </div>
  );
}

export default EditScheduleButtonsGrouping;
