import React from "react";
import AddAvailabilityCard from "./AddAvailabilityCard";
import AvailabilityCard from "./AvailabilityCard";
import TimeOffCard from "./TimeOffCard";

function PersonDayAvailabilityDisplay(props) {
  if (
    (props.person_day_availability.length === 0) &
    // (props.edit_mode & !props.displayMode) &
    (props.person_day_time_off_requests.length === 0)
  ) {
    return (
      <AddAvailabilityCard
        p={props.p}
        date_str={props.date_str}
        addShift={props.addShift}
        date_name={props.date_name}
        day_of_week_name={props.dow_name}
        date_dow={props.date_dow}
        editAvailability={props.editAvailability}
        get_t_str={props.get_t_str}
        saveAvailabilityFinished={props.saveAvailabilityFinished}
        setSaveAvailabilityFinished={props.setSaveAvailabilityFinished}
        d={props.d}
        minimizeMode={props.minimizeMode}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        request_time_off={props.request_time_off}
      />
    );
  } else if (props.person_day_time_off_requests.length > 0) {
    return <TimeOffCard title={props.person_day_time_off_requests[0].title} minimizeMode={props.minimizeMode} />;
  } else if (props.person_day_availability.length > 0) {
    return (
      <AvailabilityCard
        availability_info={props.person_day_availability[0]}
        p={props.p}
        edit_mode={props.edit_mode & !props.displayMode}
        date_str={props.date_str}
        date_name={props.date_name}
        day_of_week_name={props.dow_name}
        editAvailability={props.editAvailability}
        get_t_str={props.get_t_str}
        saveAvailabilityFinished={props.saveAvailabilityFinished}
        setSaveAvailabilityFinished={props.setSaveAvailabilityFinished}
        d={props.d}
        minimizeMode={props.minimizeMode}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        request_time_off={props.request_time_off}
      />
    );
  } else {
    return <div></div>;
  }
}

export default PersonDayAvailabilityDisplay;
