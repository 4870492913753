import React from "react";
import Stack from "@mui/material/Stack";
import FilterButton from "./FilterButton";
import SortButton from "./SortButton";
import DisplayButton from "./DisplayButton";
import PrintButton from "./PrintButton";
import MinimizeButton from "./MinimizeButton";

function FilterOrderButtons(props) {
  return (
    <div>
      <Stack direction="row" spacing={1}>
        <FilterButton
          filterTeam={props.filterTeam}
          filtered={props.filtered}
          weeklyView={props.weeklyView}
        />
        <SortButton
          sortTeam={props.sortTeam}
          sorted={props.sorted}
          setSorted={props.setSorted}
          weeklyView={props.weeklyView}
        />
        {props.weeklyView && (
          <>
            {/* <DisplayButton
              toggleDisplayMode={props.toggleDisplayMode}
              displayMode={props.displayMode}
            /> */}
            <PrintButton
              addDays={props.addDays}
              week_start_date={props.week_start_date}
              store_team_member_weekly_info_view={
                props.store_team_member_weekly_info_view
              }
              get_t_str={props.get_t_str}
              weeklyView={props.weeklyView}
            />
            <MinimizeButton
              toggleMinimizeMode={props.toggleMinimizeMode}
              minimizeMode={props.minimizeMode}
            />
          </>
        )}
      </Stack>
    </div>
  );
}

export default FilterOrderButtons;
