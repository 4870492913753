import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import VisualForecastTableSmall from "./VisualForecastTableSmall";
import { RosterGraph } from "./visual_components/RosterGraph";
import BatchGuidanceTable from "./BatchGuidanceTable";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { get_t_str } from "../../../../helpers/helpers";
import { process_schedule_data } from "../../../../helpers/helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  minHeight: 400,
  width: "98%",
  height: "98%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
};

function VisualDailyReportModal(props) {
  // Helper function to convert qh_num to time format
  function convertToTime(qh_num) {
    const hours = Math.floor(qh_num);
    const minutes = Math.floor((qh_num - hours) * 60)
      .toString()
      .padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  const schedule_data = process_schedule_data(
    props.actual_schedule,
    props.store_team_member_weekly_info,
    props.role_names
  );


  let openShiftCounter = 1;

  // Transform the array
  let transformed_schedule_data = schedule_data.map(shift => {
      if (shift.name === "") {
          shift.name = `Open Shift ${openShiftCounter}`;
          openShiftCounter++;
      }
      return shift;
  });

  console.log("TRANSFORMED SCHEDULE DATA", transformed_schedule_data)

  function get_weather_path(icon_string) {
    return process.env.PUBLIC_URL + "/images/weather/" + icon_string.slice(-7);
  }

  function generateMetricBar(metricName, value, outOf) {
    if (!value) {
      value = 0;
    }

    const grayCount = outOf - value;

    const greenDivs = Array(value).fill(
      <div className="w-7 h-3 rounded-full bg-emerald-600"></div>
    );

    const grayDivs = Array(grayCount).fill(
      <div className="w-7 h-3 rounded-full bg-slate-500"></div>
    );

    return (
      <div className="flex items-center gap-2 mt-2">
        <p className="text-base w-44">{metricName}</p>
        {greenDivs}
        {grayDivs}
      </div>
    );
  }

  // Function to transform the array
  function createBreakLogArray(employees) {
    // Filter out employees with a break
    const employeesWithBreaks = employees.filter(employee => employee.break_length > 0);

    // Map to new array with desired fields
    const breakLogArray = employeesWithBreaks.map(employee => ({
      name: employee.name,
      break_start: employee.break_start,
      break_end: employee.break_start + employee.break_length,
      break_length: employee.break_length
    }));

    // Sort the array by break_start
    breakLogArray.sort((a, b) => a.break_start - b.break_start);

    return breakLogArray;
  }


  const breakLog = createBreakLogArray(schedule_data)

  function formatName(name) {
    // Convert the name to lowercase and split it into words
    const words = name.toLowerCase().split(' ');

    // Capitalize the first letter of each word
    const formattedName = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space between them
    return formattedName.join(' ');
  }

  console.log("BREAK LOG", breakLog)

  // create a ref for your component
  let componentRef = React.useRef();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={style}>
            <div
              className="absolute right-4 top-4 text-slate-500 cursor-pointer"
              onClick={props.handleClose}
            >
              <CancelIcon />
            </div>
            <div className="absolute left-48 top-[35px] text-slate-500 cursor-pointer">
              <ReactToPrint
                trigger={() => (
                  <IconButton>
                    <PrintIcon />
                  </IconButton>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div className="p-10" ref={componentRef}>
              <h2 className="font-bold text-2xl">Daily Report</h2>
              <h4>{props.date_name}</h4>

              <div className="w-full flex justify-between mt-5">
                <div className="w-[30%] border border-gray-300 p-6 rounded-lg mt-3 mb-3">
                  <div>
                    <div className="w-full flex justify-between">
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold">
                          Forecasted Sales
                        </h5>
                        <p className="text-xl">
                          ${parseInt(props.forecast_sales, 10).toLocaleString()}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold text-right">
                          Weather
                        </h5>
                        <p className="text-xl text-right">
                          {" "}
                          {props.daily_weather.length > 0 ? (
                            <div className="flex items-center">
                              <img
                                src={get_weather_path(
                                  props.daily_weather[0].icon
                                )}
                                className="d-inline-block align-top"
                                alt={props.daily_weather[0].text}
                                style={{ height: "32px" }}
                              />
                              <span className=" ml-3">
                                {props.daily_weather[0].maxtemp}°F
                              </span>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </p>
                      </div>
                    </div>
                    {/*      <div className="w-full flex justify-between mt-5">
                    <div>
                      <h5 className="text-slate-600 text-sm text-bold">
                        Scheduled Labor Cost
                      </h5>
                      <p className="text-2xl">$3,258</p>
                    </div>
                    <div>
                      <h5 className="text-slate-600 text-sm text-bold text-right">
                        Expected Labor %
                      </h5>
                      <p className="text-2xl text-right">27%</p>
                    </div>
    </div>*/}
                    <div className="w-full flex justify-between mt-5">
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold">
                          Scheduled Hours
                        </h5>
                        <p className="text-xl">
                          {props
                            .agg_column(props.actual_schedule_labor_day, "hours")
                            .toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold text-right">
                          Forecasted Hours
                        </h5>
                        <p className="text-xl text-right">
                          {" "}
                          {props
                            .agg_column(props.forecasted_labor_day, "hours")
                            .toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="w-full flex justify-between mt-5">
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold">
                          Scheduled SPLH
                        </h5>
                        <p className="text-xl">
                          {"$" +
                            (props.agg_column(
                              props.actual_schedule_labor_day,
                              "hours"
                            ) === 0
                              ? 0
                              : (
                                  props.forecast_sales /
                                  props.agg_column(
                                    props.actual_schedule_labor_day,
                                    "hours"
                                  )
                                ).toFixed())}
                        </p>
                      </div>
                      <div>
                        <h5 className="text-slate-500 text-sm text-bold text-right">
                          Forecasted SPLH
                        </h5>
                        <p className="text-xl text-right">
                          {"$" +
                            (props.agg_column(
                              props.forecasted_labor_day,
                              "hours"
                            ) === 0
                              ? 0
                              : (
                                  props.forecast_sales /
                                  props.agg_column(
                                    props.forecasted_labor_day,
                                    "hours"
                                  )
                                ).toFixed())}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    {/* <div>
                      <h5 className="text-slate-600 text-sm text-bold">
                        Schedule Strength
                      </h5>

                      {generateMetricBar(
                        "Daily Budget",
                        props.calculate_daily_budget_score(
                          props.actual_schedule_labor_day,
                          props.forecasted_labor_day
                        ),
                        5
                      )}
                      {generateMetricBar(
                        "Intraday Coverage",
                        props.calculate_intraday_coverage_score(
                          props.actual_schedule_labor_day,
                          props.forecasted_labor_day
                        ),
                        5
                      )}
                    </div> */}
                    {breakLog.length > 0 &&
                      <div>
                        <div className="flex items-center justify-between">
                          <h5 className="text-slate-500 text-sm text-bold">
                            Break log
                          </h5>
                          <h5 className="text-slate-500 text-sm text-bold">
                            Break time
                          </h5>
                        </div>
                        <div className="mt-2">
                          {breakLog.map((breakItem, index) => (
                            <div
                              key={index}
                              className={`py-2 ${index !== breakLog.length - 1 ? 'border-b' : ''} border-slate-200 flex items-center justify-between`}
                            >
                              <p className="text-xs">{formatName(breakItem.name)}</p>
                              <div className="flex items-center gap-x-2">
                                <p className="text-xs">{get_t_str(breakItem.break_start)} - {get_t_str(breakItem.break_end)}</p>
                                <div className="w-5 h-5 border border-slate-200 rounded"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="w-[70%]">
                  {schedule_data.length > 0 && (
                    <RosterGraph
                      data={transformed_schedule_data}
                      chartid="rostergraph"
                      get_t_str={props.get_t_str}
                      role_types={props.role_types}
                    />
                  )}
                </div>
              </div>
              <div className="w-full mt-5">
                {/* <VisualForecastTableSmall
                data={data.summary}
                roleTypes={data.roleTypes}
                date_name={props.date_name}
  /> */}
                <BatchGuidanceTable
                  data={props.daily_batch_guidance}
                  items={Object.keys(props.daily_batch_guidance)}
                  date_name={props.date_name}
                  get_t_str={props.get_t_str}
                />
              </div>
            </div>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default VisualDailyReportModal;
