import React from "react";
import Stack from "@mui/material/Stack";
import AutoTuneButton from "./AutoTuneButton";
import LockAllButton from "./LockAllButton";
import AutoScheduleFunction from "./AutoScheduleFunction";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TuneIcon from "@mui/icons-material/Tune";

function AutoScheduleButtons(props) {
  const autoScheduleText = () => {
    return (
      <div id="modal-modal-description">
        <h3>
          Auto Schedule will automatically build you an optimized schedule based
          on your demand forecast and team member availability.
        </h3>
        <p className="mt-3">
          Lock any team members or days that want to keep the same.
        </p>
        <p className="mt-3">This process will take a few minutes.</p>
      </div>
    );
  };
  const autoScheduleIcon = () => {
    return (
      <AutoFixHighIcon color={props.loadedFromAuto ? "success" : "default"} />
    );
  };

  const autoTuneText = () => {
    return (
      <div id="modal-modal-description">
        <h3>
          Auto Tuner keeps all current shifts but changes start and stop times
          to optimize schedule.
        </h3>
        <p className="mt-3">
          Lock any team members or days that you don't want to change.
        </p>
        <p className="mt-3">This process will take a few minutes.</p>
      </div>
    );
  };
  const autoTuneIcon = () => {
    return <TuneIcon color={props.loadedFromAuto ? "success" : "default"} />;
  };

  return (
    <div>
      <Stack direction="row" spacing={1}>
        {/* <LockAllButton
          lockAllShifts={props.lockAllShifts}
          setLockAllShifts={props.setLockAllShifts}
        /> */}
        <AutoScheduleFunction
          autoScheduleText={autoScheduleText}
          autoScheduleTitle="Autoscheduling Options"
          autoScheduleButtonText="Auto Schedule"
          autoScheduleIcon={autoScheduleIcon}
          runType={"autoschedule"}
          autosched_available={props.autosched_available}
          week_ended={props.week_ended}
          autoSchedule={props.autoSchedule}
          acceptNewSchedule={props.acceptNewSchedule}
          schedule_differences={props.schedule_differences}
          autoScheduleError={props.autoScheduleError}
          setAutoScheduleError={props.setAutoScheduleError}
          autoScheduleFinished={props.autoScheduleFinished}
          setAutoScheduleFinished={props.setAutoScheduleFinished}
          acceptAutoScheduleFinished={props.acceptAutoScheduleFinished}
          setAcceptAutoScheduleFinished={props.setAcceptAutoScheduleFinished}
          loadedFromAuto={props.loadedFromAuto}
          lockAllShifts={props.lockAllShifts}
          setLockAllShifts={props.setLockAllShifts}
          store_team_member_weekly_info={props.store_team_member_weekly_info}
          get_t_str={props.get_t_str}
          actual_schedule={props.actual_schedule}
          role_types={props.role_types}
          role_names={props.role_names}
        />
        {/* <AutoScheduleFunction
          autoScheduleText={autoTuneText}
          autoScheduleTitle="Auto Tuner"
          autoScheduleButtonText="Auto Tune"
          autoScheduleIcon={autoTuneIcon}
          runType={"autotune"}
          autosched_available={props.autosched_available}
          week_ended={props.week_ended}
          autoSchedule={props.autoSchedule}
          acceptNewSchedule={props.acceptNewSchedule}
          schedule_differences={props.schedule_differences}
          autoScheduleError={props.autoScheduleError}
          setAutoScheduleError={props.setAutoScheduleError}
          autoScheduleFinished={props.autoScheduleFinished}
          setAutoScheduleFinished={props.setAutoScheduleFinished}
          acceptAutoScheduleFinished={props.acceptAutoScheduleFinished}
          setAcceptAutoScheduleFinished={props.setAcceptAutoScheduleFinished}
          loadedFromAuto={props.loadedFromAuto}
        /> */}
      </Stack>
    </div>
  );
}

export default AutoScheduleButtons;
