import React from 'react'
import TopBarDisplayCreateTrainingContent from '../home/top_bar_components/TopBarDisplayCreateTrainingContent';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditNoteIcon from '@mui/icons-material/EditNote';
// import ViewLogsDisplay from './ViewLogsDisplay';
// import LogCategoriesDisplay from './LogCategoriesDisplay';
import ViewLogbookDisplay from './ViewLogbookDisplay';
import ConfigureLogbookDisplay from './ConfigureLogbookDisplay';

function LogbookDisplay(props) {

  const [display, setDisplay] = React.useState("View");
  return (
    <div className="w-full min-h-screen flex">
      <div className="w-1/5">
        <div className="h-full flex flex-col gap-2 px-6 py-12 bg-slate-50 border-r border-slate-200">
          <button
            className={`w-full py-3 px-4 text-left text-base flex items-center relative rounded-2xl ${
              display === "View" ? "bg-white" : ""
            }`}
            onClick={() => {
              setDisplay("View");
            }}
          >
            <div
              className={`flex flex-1 items-center space-x-4 ${
                display === "View" ? "text-violet-700" : "text-slate-500"
              }`}
            >
              <div>
                <MenuBookIcon />
              </div>
              <span>View Logbook</span>
            </div>
          </button>
          {(props.permissionRole === "company_admin" || props.permissionRole === "rightwork_internal") &&
            <button
              className={`w-full py-3 px-4 text-left flex items-center relative rounded-2xl ${
                display === "Categories" ? "bg-white" : ""
              }`}
              onClick={() => {
                setDisplay("Categories");
              }}
            >
              <div
                className={`flex flex-1 items-center space-x-4 ${
                  display === "Categories" ? "text-violet-700" : "text-slate-500"
                }`}
              >
                <div>
                  <EditNoteIcon />
                </div>
                <span>Configure Logbook</span>
              </div>
            </button>
          }
        </div>
      </div>
      <div className="w-4/5 min-h-screen">
        <div>
          {display === "View" ? (
            <ViewLogbookDisplay
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              homeCompany={props.homeCompany}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
              laborPercentEnabled={props.laborPercentEnabled}
            />
          ) : display === "Categories" ? (
            <ConfigureLogbookDisplay
              storeOptions={props.storeOptions}
              homeStore={props.homeStore}
              homeCompany={props.homeCompany}
              storeNames={props.storeNames}
              change_home_store={props.change_home_store}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LogbookDisplay
