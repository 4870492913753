import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import PrintMode from "../schedule_components/PrintMode";
import CloseIcon from "@mui/icons-material/Close";

const printStyles = `
  @media print {
    .no-print {
      display: none;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    // th, td {
    //   border: 1px solid #ddd;
    //   text-align: left;
    // }

    th {
      font-size: 8px;
    }

    td {
      font-size: 8px;
      line-height: 12px;
    }
  }
`;


const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "auto",
  top: 0,
  left: 0,
  bgcolor: "white",
  boxShadow: 24,
  paddingX: 4,
};

export default function PrintButton(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title={"Print View"}>
        <IconButton onClick={handleOpen}>
          <PrintIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <style>{printStyles}</style>
          <div
            className="no-print absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <div className="bg-white w-full h-full">
            <PrintMode
              addDays={props.addDays}
              week_start_date={props.week_start_date}
              store_team_member_weekly_info_view={
                props.store_team_member_weekly_info_view
              }
              get_t_str={props.get_t_str}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
