import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import "@blocknote/core/fonts/inter.css";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { API_post_learning_file_with_eagerly_generated_presigned_url, API_get_learning_file } from "../../../constants";
import { getRightworkAPI, getDefaultAxiosConfig } from "../requests/utilities/requests";
import axios from "axios";

function SectionInputsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    paddingY: 4,
    paddingX: 8,
    overflowY: "auto",
  };

  const uploadFile = async (media) => {
    const formData = new FormData();
    formData.append("company_id", props.homeCompany);
    formData.append("file_name", media.name);

    try {

      // [1] Get presigned URL
      const axiosConfig = await getDefaultAxiosConfig();
      const res = await axios.post(API_post_learning_file_with_eagerly_generated_presigned_url, formData, {
        headers: {
          ...axiosConfig.headers,
          "Content-Type": "multipart/form-data",
        },
      });
      const fileUUID = res?.data?.redux?.payload?.learning_file?.uuid;
      const presignedUrl = res?.data?.redux?.payload?.presigned_url;
      
      // [2] Upload file
      await axios.put(presignedUrl, media, {
        headers: {
          'Content-Type': media.type,
        }
      });

      // [3] Get file URL
      const apiParams = {
        company_id: props.homeCompany,
        learning_file_uuid: fileUUID,
      }
      const res2 = await getRightworkAPI(API_get_learning_file, apiParams);
      const fileUrl = res2?.data?.redux?.payload?.learning_file?.file;

      // NOTE: Because we use signed URLs, whatever we get back from our API will
      //       only be valid for ~1 hour. So whenever the Blocknote compontent is saved,
      //       we must send up the file UUID to our server instead of the image URL.
      let mapSignedUrlToFileUuid = localStorage.getItem("mapSignedUrlToFileUuid") || "{}";
      if(!mapSignedUrlToFileUuid) {
        mapSignedUrlToFileUuid = {};
      } else {
        mapSignedUrlToFileUuid = JSON.parse(mapSignedUrlToFileUuid);
      }
      mapSignedUrlToFileUuid[fileUrl] = fileUUID;
      localStorage.setItem(
        "mapSignedUrlToFileUuid",
        JSON.stringify(mapSignedUrlToFileUuid)
      );

      // Finally
      return fileUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const [hasDataLoaded, setHasDataLoaded] = React.useState(false)

  React.useEffect(() => {
    setHasDataLoaded(true)
  }, [props.modalDataSection.as_json])

  React.useEffect(() => {
    setHasDataLoaded(false)
  }, [props.open])




  const editor = useCreateBlockNote({
    uploadFile,
  });

  const removeAllBlocks = () => {
    if (editor) {
      const allBlockIDsToRemove = editor.document.map(block => block.id);
      editor.removeBlocks(allBlockIDsToRemove);
    }
  };

  const loadAllBlocks = () => {
    if (editor) {
      const allBlocksToLoad = props.modalDataSection.as_json;
      editor.insertBlocks(allBlocksToLoad, editor.document[0]?.id || null, "before");
    }
  }

  React.useEffect(() => {
    if (hasDataLoaded && props.modalEdit === true) {
      loadAllBlocks()
    }
  }, [hasDataLoaded])


  function handleChange(event) {
    const { name, value } = event.target;
    props.setModalDataSection((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose()
          removeAllBlocks()
        }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => {
              props.handleClose()
              removeAllBlocks()
            }}
          >
            <CloseIcon />
          </div>
          {!props.isLoading ? (
            <div>
              <div>
                <h3 className="text-2xl text-slate-900">
                  {props.modalEdit ? "Edit Section" : "Create Section"}
                </h3>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">Section Name</p>
                <div className="mt-1.5">
                  <TextField
                    placeholder="What do you want to name this section? (i.e. How to serve a table)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="name"
                    value={props.modalDataSection?.name}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-5">
                <p className="text-sm text-slate-500">
                  Add any content that you would like
                </p>
                <div className="mt-1.5">
                  <div className="w-full min-h-[450px] py-5 border-y border-[#bdbdbd]">
                    <div className="-ml-[50px] -mr-[54px]">
                      {editor && (
                        <BlockNoteView
                          editor={editor}
                          editable={true}
                          theme="light"
                          onChange={() => {
                            // Saves the document JSON to state.
                            props.setModalDataSection(prevModalData => ({
                              ...prevModalData,
                              as_json: editor.document
                            }));
                            console.log("EDITOR", editor.document)
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full mt-5 flex justify-end">
                <div className="flex items-center space-x-2">
                  {props.modalEdit ? (
                    <div
                      className="px-10 py-2.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                      onClick={() => {
                        props.delete_learning_section()
                        props.handleClose()
                      }}
                    >
                      Delete Section
                    </div>
                  ) : (
                    <div
                      className="px-10 py-2.5 border border-violet-700 bg-white hover:bg-slate-50 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                      onClick={() => {
                        props.handleClose()
                        removeAllBlocks()
                      }}
                    >
                      Cancel
                    </div>
                  )}
                  <div
                    className="px-10 py-2.5 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                    onClick={() => {
                      props.post_learning_section();
                      props.handleClose();
                      removeAllBlocks()
                    }}
                  >
                    Save Section
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[700px]">
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <LoadSpinner />
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default SectionInputsModal;
