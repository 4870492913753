import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import QuestionInputsModal from './QuestionInputsModal';
import LoadSpinner from "../../../utilities/LoadSpinner";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteWarningModal from './DeleteWarningModal';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { Lightbox } from "react-modal-image";
import { getRightworkAPI } from '../requests/utilities/requests';
import { API_get_file_upload } from '../../../constants';


function QuizInputsModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };


  const [openQuestionInputsModal, setOpenQuestionInputsModal] = React.useState(false);
  const handleOpenQuestionInputsModal = () => setOpenQuestionInputsModal(true);
  const handleCloseQuestionInputsModal = () => setOpenQuestionInputsModal(false);

  const [openDeleteWarningModal, setOpenDeleteWarningModal] = React.useState(false);
  const handleOpenDeleteWarningModal = () => setOpenDeleteWarningModal(true);
  const handleCloseDeleteWarningModal = () => setOpenDeleteWarningModal(false);

  const [passPercentageChecked, setPassPercentageChecked] = React.useState(false);
  const [limitRetakesChecked, setLimitRetakesChecked] = React.useState(false);
  const [showCorrectAnswersChecked, setShowCorrectAnswersChecked] = React.useState(false);

  const [showLighbox, setShowLightbox] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(null)

  // [GET] api/file_upload_info/get_file_upload
  // * file_upload_uuid
  const get_file_upload = async (file_upload_uuid) => {
    let api_params = {
      file_upload_uuid: file_upload_uuid,
    };
    try {
      const res = await getRightworkAPI(API_get_file_upload, api_params);
      if (res.status === 200) {
        setSelectedImage(res.data.redux?.payload?.presigned_url);
        setShowLightbox(true)
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  const default_data_quiz_question = {
    answers: [],
    created_at: null,
    question: "",
    updated_at: null,
    uuid: null,
    pass_percentage: 0,
    retake_limit: null,
    show_correct_answers: true,
  };

  const [modalDataQuizQuestion, setModalDataQuizQuestion] = React.useState(default_data_quiz_question);

  const [modalEdit, setModalEdit] = React.useState(false);
  const [selectedQuestionIndexToEdit, setSelectedQuestionIndexToEdit] = React.useState(null);

  function handleChange(event) {
    const { name, value } = event.target;
    props.setModalDataQuiz((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  function removeQuestionFromModalData(index) {
    props.setModalDataQuiz(prevModalData => ({
      ...prevModalData,
      learning_questions: prevModalData.learning_questions.filter((_, i) => i !== index)
    }));
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const reorderedQuestions = Array.from(props.modalDataQuiz.learning_questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    props.setModalDataQuiz(prevModalData => ({
      ...prevModalData,
      learning_questions: reorderedQuestions
    }));
  }

  function handlePassPercentageCheckChange(event) {
    const checked = event.target.checked;
    setPassPercentageChecked(checked);
    if (!checked) {
      props.setModalDataQuiz(prevModalData => ({
        ...prevModalData,
        pass_percentage: 0
      }));
    }
  }

  function handleLimitRetakesCheckChange(event) {
    const checked = event.target.checked;
    setLimitRetakesChecked(checked);
    if (!checked) {
      props.setModalDataQuiz(prevModalData => ({
        ...prevModalData,
        retake_limit: ''
      }));
    }
  }

  function handleShowCorrectAnswersCheckChange(event) {
    const checked = event.target.checked;
    setShowCorrectAnswersChecked(checked);
    props.setModalDataQuiz(prevModalData => ({
      ...prevModalData,
      show_correct_answers: checked
    }));
  }

  React.useEffect(() => {
    if (props.open) {
      setPassPercentageChecked(props.modalDataQuiz?.pass_percentage || false);
      setLimitRetakesChecked(props.modalDataQuiz?.retake_limit || false);
      setShowCorrectAnswersChecked(props.modalDataQuiz?.show_correct_answers || false);
    }
  }, [props.open, props.modalDataQuiz]);

  return (
    <>
      <Modal
        open={props.open}
        // onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div>
          {showLighbox &&
            <div className="absolute w-screen h-screen z-40">
              <Lightbox
                small={selectedImage}
                medium={selectedImage}
                // large={selectedImage}
                hideDownload={true}
                hideZoom={true}
                onClose={() => setShowLightbox(false)}
              />
            </div>
          }
          <Box sx={style}>
            <div
              className="absolute right-2 top-2 text-gray-500 cursor-pointer"
              onClick={() => props.handleClose()}
            >
              <CloseIcon />
            </div>
            {!props.isLoading ?
              <div>
                <div>
                  <h3 className="text-2xl text-slate-900">{props.modalEdit ? "Edit Quiz" : "Create Quiz"}</h3>
                </div>
                <div className="mt-5">
                  <p className="text-sm text-slate-500">Quiz Name</p>
                  <div className="mt-1.5">
                    <TextField
                      placeholder="What do you want to name this quiz? (i.e. Greet table quiz)"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="name"
                      value={props.modalDataQuiz?.name}
                      onChange={handleChange}
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "5px",
                          height: "45px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <div className="flex items-center">
                    <Checkbox
                      name="showCorrectAnswersChecked"
                      checked={showCorrectAnswersChecked}
                      onChange={handleShowCorrectAnswersCheckChange}
                      size="small"
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9",
                        },
                      }}
                    />
                    <p className="text-sm text-slate-500 ml-1 py-5">Do you want the employee to see the correct answer after each question?</p>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="flex items-center">
                    <Checkbox
                      name="passPercentageChecked"
                      checked={passPercentageChecked}
                      onChange={handlePassPercentageCheckChange}
                      size="small"
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9",
                        },
                      }}
                    />
                    <p className="text-sm text-slate-500 ml-1 flex-1 py-5">Do you want to require a certain percentage to pass this quiz?</p>
                    {passPercentageChecked &&
                      <div>
                        <TextField
                          placeholder="Percentage?"
                          variant="outlined"
                          type="number"
                          size="small"
                          name="pass_percentage"
                          value={props.modalDataQuiz?.pass_percentage || ''}
                          onChange={handleChange}
                          InputProps={{
                            sx: {
                              width: "150px",
                              backgroundColor: "white",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "45px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#6d28d9",
                              },
                            },
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
                {passPercentageChecked &&
                  <div className="mt-3">
                    <div className="flex items-center">
                      <Checkbox
                        name="limitRetakesChecked"
                        checked={limitRetakesChecked}
                        onChange={handleLimitRetakesCheckChange}
                        size="small"
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      <p className="text-sm text-slate-500 ml-1 flex-1 py-5">Do you want to limit the number of attempts to pass this quiz?</p>
                      {limitRetakesChecked &&
                        <div>
                          <TextField
                            placeholder="How many?"
                            variant="outlined"
                            type="number"
                            size="small"
                            name="retake_limit"
                            value={props.modalDataQuiz?.retake_limit || ''}
                            onChange={handleChange}
                            InputProps={{
                              sx: {
                                width: "150px",
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "5px",
                                height: "45px",
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#6d28d9",
                                },
                              },
                            }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className="mt-6">
                  <p className="text-sm text-slate-500">Questions</p>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="questions">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="bg-slate-50"
                        >
                          {props.modalDataQuiz?.learning_questions?.map((question, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    left: "auto !important",
                                    top: "auto !important",
                                  }}
                                  className={`py-3 border-b border-slate-200 flex ${snapshot.isDragging ? 'bg-slate-200' : 'bg-white'}`}
                                >
                                  <div
                                    className={`w-[8%] text-slate-500 cursor-pointer`}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIndicatorIcon />
                                  </div>
                                  <p className="w-[8%]">{index + 1}</p>
                                  <div className="flex-1">
                                    <div className="flex space-x-1">
                                      <h4 className="text-slate-900">{question.question_v2?.[0]}</h4>
                                      {question.question_v2?.[1] &&
                                        <div
                                          className="text-violet-700 hover:text-violet-800 text-sm cursor-pointer"
                                          onClick={() => {
                                            if (question.question_v2?.[2]) {
                                              setSelectedImage(question.question_v2?.[2])
                                              setShowLightbox(true)
                                            } else {
                                              get_file_upload(question.question_v2?.[1])
                                            }
                                          }}
                                        >
                                          <Tooltip title="Click to see attached image">
                                            <ImageIcon style={{ fontSize: "18px" }} />
                                          </Tooltip>
                                        </div>
                                      }
                                    </div>
                                    {question.answers?.map(([answer, is_correct, file_uuid, file_url], index) => (
                                      <div
                                        key={index}
                                        className="mt-1 flex items-center space-x-1"
                                      >
                                        <p className={`${is_correct ? "text-emerald-600" : "text-slate-900"}`}>{index === 0 ? "a." : index === 1 ? "b." : index === 2 ? "c." : index === 3 ? "d." : "e."} {answer}</p>
                                        {file_uuid &&
                                          <div
                                            className="text-violet-700 hover:text-violet-800 text-sm cursor-pointer"
                                            onClick={() => {
                                              if (file_url) {
                                                setSelectedImage(file_url)
                                                setShowLightbox(true)
                                              } else {
                                                get_file_upload(file_uuid)
                                              }
                                            }}
                                          >
                                            <Tooltip title="Click to see attached image">
                                              <ImageIcon style={{ fontSize: "18px" }} />
                                            </Tooltip>
                                          </div>
                                        }
                                      </div>
                                    ))}
                                  </div>
                                  <div className="h-fit flex items-center space-x-3 ml-5">
                                    <div
                                      className="text-slate-500 cursor-pointer"
                                      onClick={() => {
                                        setModalEdit(true)
                                        setModalDataQuizQuestion(question)
                                        setSelectedQuestionIndexToEdit(index)
                                        handleOpenQuestionInputsModal()
                                      }}
                                    >
                                      <EditIcon style={{ fontSize: "22px" }} />
                                    </div>
                                    <div
                                      className="text-slate-500 cursor-pointer"
                                      onClick={() => removeQuestionFromModalData(index)}
                                    >
                                      <CloseIcon style={{ fontSize: "22px" }} />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="w-full py-5 flex items-center space-x-2">
                    <div
                      className="px-4 py-2.5 flex items-center justify-center bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg cursor-pointer"
                      onClick={() => {
                        setModalEdit(false)
                        setModalDataQuizQuestion(default_data_quiz_question)
                        handleOpenQuestionInputsModal()
                      }}
                    >
                      <div>
                        <AddIcon style={{ fontSize: "22px" }} />
                      </div>
                      <p className="mr-1">Add Question</p>
                    </div>
                  </div>
                </div>
                <div className="w-full mt-6 flex justify-end">
                  <div className="flex items-center space-x-2">
                    {(props.modalDataQuiz?.learning_questions?.length > 0 && props.modalEdit) &&
                      <Tooltip title="Duplicate quiz">
                        <div
                          className="px-6 py-2.5 bg-amber-200 hover:bg-amber-300 text-amber-800 rounded-full text-base w-fit cursor-pointer"
                          onClick={() => {
                            props.post_learning_quiz(true)
                            props.handleClose()
                          }}
                        >
                          <ContentCopyIcon />
                        </div>
                      </Tooltip>
                    }
                    {props.modalEdit ?
                      <div
                        className="px-10 py-2.5 bg-rose-200 hover:bg-rose-300 text-rose-800 rounded-full text-base w-fit cursor-pointer"
                        onClick={() => {
                          handleOpenDeleteWarningModal()
                        }}
                      >
                        Delete Quiz
                      </div> :
                      <div
                        className="px-10 py-2.5 border border-violet-700 bg-white hover:bg-slate-50 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                        onClick={() => props.handleClose()}
                      >
                        Cancel
                      </div>
                    }
                    {props.modalDataQuiz?.learning_questions?.length > 0 ?
                      <div
                        className="px-10 py-2.5 bg-violet-700 hover:bg-violet-800 text-white rounded-full text-base w-fit cursor-pointer"
                        onClick={() => {
                          props.post_learning_quiz()
                          props.handleClose()
                        }}
                      >
                        Save Quiz
                      </div> :
                      <div
                        className="px-10 py-2.5 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                      >
                        Save Quiz
                      </div>
                    }
                  </div>
                </div>
              </div> :
              <div className="w-full h-[700px]">
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <LoadSpinner />
                </div>
              </div>
            }
          </Box>
        </div>
      </Modal>
      <QuestionInputsModal
        open={openQuestionInputsModal}
        handleClose={handleCloseQuestionInputsModal}
        modalEdit={modalEdit}
        modalDataQuiz={props.modalDataQuiz}
        setModalDataQuiz={props.setModalDataQuiz}
        modalDataQuizQuestion={modalDataQuizQuestion}
        setModalDataQuizQuestion={setModalDataQuizQuestion}
        default_data_quiz_question={default_data_quiz_question}
        selectedQuestionIndexToEdit={selectedQuestionIndexToEdit}
        homeCompany={props.homeCompany}
      />
      <DeleteWarningModal
        open={openDeleteWarningModal}
        handleClose={handleCloseDeleteWarningModal}
        type="quiz"
        delete_function={props.delete_learning_quiz}
        handleCloseInputsModal={props.handleClose}
      />
    </>
  )
}

export default QuizInputsModal
