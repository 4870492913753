import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import LoadSpinner from '../../../utilities/LoadSpinner';
import { getRightworkAPI } from '../requests/utilities/requests';
import { API_get_learning_quiz_detail } from '../../../constants';

function CompleteQuizModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    overflowY: "auto",
  };

  const [isLoading, setIsLoading] = useState(props.isLoading ? props.isLoading : false);
  const [quizDetails, setQuizDetails] = useState(props.modalDataQuiz || null);
  const [step, setStep] = useState('welcome');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const quizPassPercentage = quizDetails?.pass_percentage

  const modalRef = React.useRef(null);

  const scrollToTop = () => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  console.log("QUIZ DETAILS", quizDetails)

  const fetchQuizDetail = async (showLoading) => {
    setIsLoading(showLoading);
    let api_params = {
      company_id: props.homeCompany,
      learning_quiz_uuid: props.quizSelected?.uuid,
      store_team_member_id: props.employeeID ? props.employeeID : null
    };

    try {
      const res = await getRightworkAPI(API_get_learning_quiz_detail, api_params);
      if (res.status === 200) {
        setQuizDetails(res.data.redux?.payload?.learning_quiz);
        setIsLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    setIsLoading(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (!props.modalDataQuiz && props.quizSelected) {
      fetchQuizDetail(true);
    } else {
      setQuizDetails(props.modalDataQuiz);
      setStep('welcome');
      setCurrentQuestionIndex(0);
    }
  }, [props.quizSelected, props.modalDataQuiz]);

  const handleNextQuestion = () => {
    const question = quizDetails.learning_questions[currentQuestionIndex];
    const correctAnswers = question.answers
      .filter(([, is_correct]) => is_correct)
      .map(([answer, , image_uuid]) => image_uuid || answer);

    const isCorrect = correctAnswers.every(id => selectedAnswers.includes(id)) &&
      selectedAnswers.every(id => correctAnswers.includes(id));

    if (isCorrect) {
      setScore(score + 1);
    }

    setFeedback(isCorrect ? 'Correct!' : 'Incorrect!');
    setHasSubmitted(true);
  };


  const handleProceed = () => {
    setFeedback(null);
    setSelectedAnswers([]);
    setHasSubmitted(false); // Reset the submission state for the next question
    if (currentQuestionIndex < quizDetails.learning_questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setStep('completed');
    }
    scrollToTop()
  };

  const handleStartQuiz = () => {
    setStep('question');
  };

  const handleAnswerSelect = (answer, image_uuid) => {
    if (hasSubmitted) return; // Prevent selecting answers after submission
    const answerId = image_uuid || answer; // Use image_uuid if available, otherwise use the answer text
    setSelectedAnswers((prevSelected) =>
      prevSelected.includes(answerId)
        ? prevSelected.filter((id) => id !== answerId)
        : [...prevSelected, answerId]
    );
  };


  const renderWelcome = () => (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="text-slate-900">
        <SchoolOutlinedIcon style={{ fontSize: "80px" }} />
      </div>
      <h3 className="text-3xl text-slate-900 font-semibold mt-8">{quizDetails?.name}</h3>
      <div className="flex items-center gap-x-2 mt-4 text-slate-500">
        <div>
          <ChecklistIcon style={{ fontSize: "22px" }} />
        </div>
        <p className="text-base">
          {quizDetails?.learning_questions?.length} {quizDetails?.learning_questions?.length === 1 ? "question" : "questions"}
        </p>
      </div>
      {quizDetails?.pass_percentage !== 0 &&
        <div className="flex items-center gap-x-2 mt-3 text-slate-500">
          <div>
            <FlagOutlinedIcon style={{ fontSize: "22px" }} />
          </div>
          <p className="text-base">
            {quizDetails?.pass_percentage ? `${quizDetails?.pass_percentage}% pass percentage` : ""}
          </p>
        </div>
      }
      <div className="flex items-center gap-x-2 mt-3 text-slate-500">
        <div>
          <LockOutlinedIcon style={{ fontSize: "22px" }} />
        </div>
        <p className="text-base">
          {(quizDetails?.retake_limit === null || quizDetails?.retake_limit === 0) ?
            `${quizDetails?.pass_attempt_count ? quizDetails?.pass_attempt_count : 0} / ∞ Attempts` :
            `${quizDetails?.pass_attempt_count ? quizDetails?.pass_attempt_count : 0} / ${quizDetails?.retake_limit} attempts`
          }
        </p>
      </div>
      <div
        className="px-6 py-2.5 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm cursor-pointer mt-7"
        onClick={handleStartQuiz}
      >
        Get Started
      </div>
    </div>
  );

  const renderQuestion = () => {
    const question = quizDetails.learning_questions[currentQuestionIndex];
    return (
      <div className="w-[50%] sm:w-[85%]">
        <div className="px-3 py-0.5 border border-slate-200 text-slate-500 text-sm rounded-lg w-fit">Multiple Response</div>
        <div className="pt-6 pb-10 border-b border-slate-200">
          <h3 className="text-slate-900 text-2xl">{question.question_v2?.[0]}</h3>
          {question.question_v2?.[2] &&
            <div className="mt-5 object-cover">
              <img src={question.question_v2?.[2]} alt="Question Image" style={{ borderRadius: "10px", maxHeight: "300px" }} />
            </div>
          }
        </div>
        <div className="mt-10">
          {question.answers.map(([answer, is_correct, image_uuid, image_url], index) => {
            let answerClass = "bg-slate-50 text-slate-600";
            if (hasSubmitted && quizDetails.show_correct_answers) {
              const answerId = image_uuid || answer;
              if (selectedAnswers.includes(answerId)) {
                answerClass = is_correct ? "bg-emerald-500 text-white" : "bg-rose-500 text-white";
              } else if (is_correct) {
                answerClass = "bg-emerald-500 text-white";
              }
            }
            return (
              <div
                key={index}
                className={`p-4 rounded-lg flex space-x-5 mt-3 cursor-pointer ${answerClass}`}
                onClick={() => handleAnswerSelect(answer, image_uuid)}
              >
                <div className={`w-5 h-5 rounded ${selectedAnswers.includes(image_uuid || answer) ? 'bg-violet-700' : 'bg-white'} border border-slate-300 flex items-center justify-center`}>
                  <div className="text-white">{selectedAnswers.includes(image_uuid || answer) ? <CheckIcon style={{ fontSize: "18px", marginBottom: "2px" }} /> : ""}</div>
                </div>
                <div className="flex-1 flex space-x-4">
                  <p>{answer}</p>
                  {image_url &&
                    <div>
                      <img src={image_url} alt="Answer Image" style={{ borderRadius: "10px", maxHeight: "250px" }} />
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex items-center justify-between mt-8">
          <div className="flex items-center space-x-3">
            <div
              className={`w-fit px-6 py-3 rounded-lg ${!hasSubmitted ? "bg-violet-700 hover:bg-violet-800 text-white cursor-pointer" : "bg-slate-200 text-slate-400"} text-sm`}
              onClick={handleNextQuestion}
            >
              Submit
            </div>
            {hasSubmitted &&
              <div
                className="w-fit px-6 py-3 rounded-lg bg-violet-700 hover:bg-violet-800 text-white text-sm cursor-pointer flex items-center justify-center gap-x-1.5"
                onClick={() => {
                  if (currentQuestionIndex === quizDetails.learning_questions?.length - 1 && props.type !== "Preview") {
                    const passScore = (score / quizDetails.learning_questions.length) * 100;
                    const passed = passScore >= quizPassPercentage;
                    props.post_learning_block_user_passed(quizDetails.uuid, passed, props.employeeID)
                  }
                  handleProceed()
                }}
              >
                <p>{currentQuestionIndex === quizDetails.learning_questions?.length - 1 ? "Results" : "Next"}</p>
                <div>
                  {currentQuestionIndex === quizDetails.learning_questions?.length - 1 ? <ChecklistIcon style={{ fontSize: "20px" }} /> : <ChevronRightIcon style={{ fontSize: "20px" }} />}
                </div>
              </div>
            }
          </div>
          <div>
            {feedback && quizDetails.show_correct_answers &&
              <div className="flex items-center space-x-2">
                <div className={`w-6 h-6 rounded-full flex items-center justify-center text-white ${feedback === 'Correct!' ? 'bg-emerald-500' : 'bg-rose-500'}`}>
                  {feedback === "Correct!" ? <CheckIcon /> : <CloseIcon />}
                </div>
                <p className={`text-lg ${feedback === 'Correct!' ? 'text-emerald-600' : 'text-rose-600'}`}>{feedback}</p>
              </div>}
          </div>
        </div>
      </div>
    );
  };


  const renderNoQuestions = () => {

    return (
      <div className="w-[50%] sm:w-[85%]">
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-10 h-10 rounded-xl bg-slate-100 flex items-center justify-center text-slate-900">
            <InfoIcon />
          </div>
          <div className="w-72">
            <p className="mt-2 text-sm text-center">There are no questions in this quiz</p>
          </div>
        </div>
      </div>
    );
  };

  const renderCompleted = () => {
    const passScore = (score / quizDetails.learning_questions.length) * 100;
    const passed = passScore >= quizPassPercentage;
    return(
      <div className="w-full h-full flex flex-col items-center justify-center">
        <h3 className="text-3xl text-slate-900 font-semibold">Quiz Completed!</h3>
        <p className="text-slate-500 mt-4 w-80 text-center">
          Thank you for completing the quiz. Your score is {score} out of {quizDetails.learning_questions.length}.
        </p>
        <div className="mt-8 flex items-center gap-x-10">
          <div className="flex flex-col items-center">
            <p className="text-slate-500">Your Score</p>
            <p className="text-slate-900 font-bold text-4xl mt-2">{passScore.toFixed()}%</p>
          </div>
          {quizPassPercentage > 0 &&
            <div className="flex flex-col items-center">
              <p className="text-slate-500">Passing Score</p>
              <p className="text-slate-900 font-bold text-4xl mt-2">{quizPassPercentage.toFixed()}%</p>
            </div>
          }
        </div>
        {passed ?
          (
            <p className="text-emerald-700 mt-8 text-center font-bold">
              Congratulations! You passed this quiz. You may now move on.
            </p>
          ) :
          (
            <p className="text-rose-700 mt-8 text-center font-bold">
              You did not pass the quiz. Please try again.
            </p>
          )
        }
      </div>
    )
  };


  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.handleClose()
        setScore(0)
        if (step === 'completed') {
          props.setRefreshSection(true)
        }
      }}
    >
      <Box sx={style} ref={modalRef}>
        <div
          className="absolute right-2 top-2 text-gray-500 cursor-pointer z-40"
          onClick={() => {
            props.handleClose()
            setScore(0)
            if (step === 'completed') {
              props.setRefreshSection(true)
            }
          }}
        >
          <CloseIcon />
        </div>
        {!isLoading ?
          <div className="w-full h-full flex flex-col items-center">
            <div className="py-5 w-full flex justify-center shadow">
              <p className="text-slate-900 text-base">{quizDetails?.name}</p>
            </div>
            {step !== "welcome" ?
              <div className="w-full bg-slate-100 flex items-center justify-center shadow-inner">
                <div className="flex items-center space-x-5">
                  {quizDetails?.learning_questions?.map((dot, index) => (
                    <div key={index}>
                      {index === currentQuestionIndex ? (
                        <div className="w-4 h-4 rounded-full border border-violet-700 flex items-center justify-center bg-slate-100 my-3">
                          <div className="w-2 h-2 rounded-full bg-violet-700"></div>
                        </div>
                      ) : (
                        <div className="w-2 h-2 rounded-full bg-slate-600"></div>
                      )}
                    </div>
                  ))}
                </div>
              </div> :
              <div className="w-full h-2 bg-slate-100 shadow-inner"></div>
            }
            <div className="flex-grow w-full flex flex-col items-center justify-center p-6">
              {step === 'welcome' && renderWelcome()}
              {(quizDetails?.learning_questions.length > 0 && step === 'question') ? renderQuestion() : (quizDetails?.learning_questions.length === 0 && step === 'question') ? renderNoQuestions() : <div></div>}
              {step === 'completed' && renderCompleted()}
            </div>
          </div> :
          <div className="w-full h-[400px]">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <LoadSpinner />
            </div>
          </div>
        }
      </Box>
    </Modal>
  );
}

export default CompleteQuizModal;
