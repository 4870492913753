import React from 'react'

export default function DefaultAvailabilityCard(props) {
  return(
    <div className="flex flex-col">
      <div className={`w-40 h-16 rounded-xl flex flex-col justify-center p-4 shadow ${props.availability === "All Day" ? "bg-green-200" : props.availability === "Unavailable" ? "bg-red-200" : props.availability === "No Availability Set" || props.availability === null ? "bg-white border border-gray-400 border-1 shadow-none" : "bg-yellow-200"}`}>
        <h3 className="text-xs font-semibold">{props.availability ? props.availability : "No Availability Set"}</h3>
        {props.preferences && <h4 className="text-xxs">Pref: {props.preferences}</h4>}
      </div>
    </div>
  )
}
