import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ScheduleCoverageGraph } from "./visual_components/ScheduleCoverageGraph";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VizualizeAvailOrSchedDepartmentFilter from "./VizualizeAvailOrSchedDepartmentFilter";

export default function VizualizeWeeklySched(props) {
  const [open, setOpen] = React.useState(false);

  // Holds the state of the department selection before the modal is open
  const [
    departmentSelectionBeforeOpenModal,
    setDepartmentSelectionBeforeOpenModal,
  ] = React.useState();

  // Event handler for opening the modal
  const handleOpen = () => {
    setDepartmentSelectionBeforeOpenModal(props.roleType);
    setOpen(true);
  };

  // Event handler for closing the modal
  const handleClose = () => {
    props.setRoleType(departmentSelectionBeforeOpenModal);
    setOpen(false);
  };

  // Modal style object
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "95%",
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    paddingX: 5,
    paddingY: 4,
    overflow: "scroll",
  };

  const minOpenTime = props.hours_of_operation.reduce(
    (min, current) => Math.min(min, current.open_time),
    Infinity
  );
  const maxCloseTime = props.hours_of_operation.reduce(
    (max, current) => Math.max(max, current.close_time),
    -Infinity
  );

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 480,
    },
  });

  return (
    <>
      <button
        type="button"
        className="w-[168px] py-0.5 rounded-lg shadow bg-violet-50 text-violet-500 text-center hover:bg-violet-100 flex items-center gap-1 justify-center"
        onClick={handleOpen}
      >
        <div>
          <BlurOnIcon style={{ fontSize: "18px", marginBottom: "3px" }} />
        </div>
        <p className="text-xxs mr-1">Schedule Coverage</p>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex justify-between w-[88%]">
              <div>
                <h2 className="text-xl font-bold">Schedule Coverage</h2>
                <div className="w-[92%]">
                  <h4 className="text-gray-500 mt-3">
                    Visualize where the gaps are in your scheduled labor versus
                    forecasted. Each color represents the number of employees
                    you have scheduled above the forecasted demand for that
                    15-minute time period.
                  </h4>
                </div>
              </div>
              <div className="flex items-end relative z-30">
                <VizualizeAvailOrSchedDepartmentFilter
                  roleType={props.roleType}
                  setRoleType={props.setRoleType}
                  roleTypeOptions={props.roleTypeOptions}
                />
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-[90%] mt-5">
                <ScheduleCoverageGraph
                  forecasted_labor={props.forecasted_labor}
                  scheduled_labor={props.scheduled_labor}
                  chartid="schedulecoveragegraph"
                  minOpenTime={minOpenTime}
                  maxCloseTime={maxCloseTime}
                  roleType={props.roleType}
                />
              </div>
              <div className="flex-1 h-full mt-5 text-slate-500 text-sm mr-3 relative">
                <div className="absolute right-2.5 -top-16 text-slate-400">
                  <CustomWidthTooltip
                    placement="bottom-start"
                    title={
                      <div>
                        <p>
                          This legend illustrates your schedule coverage across
                          the week. Blue indicates understaffing, while red
                          indicates overstaffing. The intensity of the color
                          reflects the degree of understaffing or overstaffing,
                          with darker shades indicating more severe conditions.
                          Optimal staffing is denoted by light gray.
                        </p>
                      </div>
                    }
                  >
                    <InfoOutlinedIcon />
                  </CustomWidthTooltip>
                </div>
                <div className="absolute -top-7 left-14 text-rose-700 w-content">
                  <p>Overstaffed</p>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{">= 6"}</div>
                  <div className="flex-1 h-full bg-rose-700"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"5"}</div>
                  <div className="flex-1 h-full bg-rose-600"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"4"}</div>
                  <div className="flex-1 h-full bg-rose-500"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"3"}</div>
                  <div className="flex-1 h-full bg-rose-400"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"2"}</div>
                  <div className="flex-1 h-full bg-rose-300"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"1"}</div>
                  <div className="flex-1 h-full bg-rose-200"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"0"}</div>
                  <div className="flex-1 h-full bg-slate-200"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"-1"}</div>
                  <div className="flex-1 h-full bg-blue-200"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"-2"}</div>
                  <div className="flex-1 h-full bg-blue-300"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"-3"}</div>
                  <div className="flex-1 h-full bg-blue-400"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"-4"}</div>
                  <div className="flex-1 h-full bg-blue-500"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"-5"}</div>
                  <div className="flex-1 h-full bg-blue-600"></div>
                </div>
                <div className="w-full flex items-center space-x-3 h-11">
                  <div className="w-[55%] text-right">{"<= -6"}</div>
                  <div className="flex-1 h-full bg-blue-700"></div>
                </div>
                <div className="absolute -bottom-7 left-14 text-blue-700 w-content">
                  <p>Understaffed</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
