import React from "react";
import AvailabilityInputsModal from "./AvailabilityInputsModal";

function AvailabilityCard(props) {
  const [open, setOpen] = React.useState(false);

  // To prevent people from clicking availability cards in the past

  // const handleOpen = () => setOpen(props.edit_mode);

  const handleOpen = () => setOpen(true);

  // Event handler for closing the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveAvailabilityFinished(false);
  };

  // Determine the availability text based on available hours
  let availability = "";
  if (
    props.availability_info.available_from === 0 &&
    props.availability_info.available_to === 24
  ) {
    availability = "All Day";
  } else if (
    props.availability_info.available_to === 0 &&
    props.availability_info.available_to === 0
  ) {
    availability = "No Availability";
  } else {
    availability = `${props.get_t_str(
      props.availability_info.available_from
    )} - ${props.get_t_str(props.availability_info.available_to)}`;
  }

  // Calculate available hours and determine the background color
  let availableHours =
    props.availability_info.available_to -
    props.availability_info.available_from;
  let availableColor = "";
  if (availableHours === 0 && props.edit_mode) {
    availableColor = "bg-red-200 hover:bg-red-300 cursor-pointer";
  } else if (availableHours < 24 && props.edit_mode) {
    availableColor = "bg-amber-200 hover:bg-amber-300 cursor-pointer";
  } else if (props.edit_mode) {
    availableColor = "bg-green-200 hover:bg-green-300 cursor-pointer";
  } else if (availableHours === 0) {
    availableColor = "bg-red-200 hover:bg-red-300 cursor-pointer";
  } else if (availableHours < 24) {
    availableColor = "bg-amber-200 hover:bg-amber-300 cursor-pointer";
  } else {
    availableColor = "bg-green-200 hover:bg-green-300 cursor-pointer";
  }

  return (
    <div className="w-full h-full">
      <div
        onClick={handleOpen}
        className="w-full h-full flex max-w-sm overflow-hidden bg-white rounded-lg shadow-md"
      >
        <div
          className={`w-full h-full flex items-center py-3 px-2 text-slate-900 hover:text-black ${availableColor}`}
        >
          <div className="mx-1">
            <p className="text-xs font-bold xl:font-normal">{availability}</p>
            {!props.minimizeMode && availableHours > 0 && props.availability_info.preferred_to > 0 && (
              <p className="text-xxs">
                {"Pref: " +
                  props.get_t_str(props.availability_info.preferred_from) +
                  " - " +
                  props.get_t_str(props.availability_info.preferred_to)}
              </p>
            )}
          </div>
        </div>
      </div>
      <AvailabilityInputsModal
        open={open}
        handleClose={handleClose}
        availability_info={props.availability_info}
        p={props.p}
        new_availability={false}
        editAvailability={props.editAvailability}
        date_name={props.date_name}
        day_of_week_name={props.day_of_week_name}
        date_str={props.date_str}
        get_t_str={props.get_t_str}
        availableColor={availableColor}
        availableHours={availableHours}
        availability={availability}
        saveAvailabilityFinished={props.saveAvailabilityFinished}
        d={props.d}
        businessWeekStartDayIndex={props.businessWeekStartDayIndex}
        start_qhnum={props.start_qhnum}
        request_time_off={props.request_time_off}
      />
    </div>
  );
}

export default AvailabilityCard;
