import React from "react";
import { getInitials } from "../../utilities/helpers";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import { getCardColor2 } from "../../../../../helpers/helpers";
import { formatDateReadable } from "../../utilities/helpers";
import EmployeeProfileCertificationsTab from "./EmployeeProfileCertificationsTab";
import EmployeeProfileAttributesTab from "./EmployeeProfileAttributesTab";
import EmployeeProfileStatisticsTab from "./EmployeeProfileStatisticsTab";
import EmployeeProfileNotesTab from "./EmployeeProfileNotesTab";
import WarningIcon from "@mui/icons-material/Warning";
import EmployeeNotLoggedInWarningModal from "./EmployeeNotLoggedInWarningModal";
import EmployeeDeleteWarningModal from "./EmployeeDeleteWarningModal";
import DeleteIcon from "@mui/icons-material/Delete";

function EmployeeProfile(props) {
  const [activeTab, setActiveTab] = React.useState("Attributes");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const avatarCardColor = props.avatarCardColor.trim();

  let backgroundGradientColor = "";

  if (avatarCardColor === "bg-violet-600") {
    backgroundGradientColor = "via-violet-200";
  } else if (avatarCardColor === "bg-sky-700") {
    backgroundGradientColor = "via-sky-200";
  } else if (avatarCardColor === "bg-cyan-600") {
    backgroundGradientColor = "via-cyan-200";
  } else if (avatarCardColor === "bg-emerald-600") {
    backgroundGradientColor = "via-emerald-200";
  } else if (avatarCardColor === "bg-lime-600") {
    backgroundGradientColor = "via-lime-200";
  } else if (avatarCardColor === "bg-amber-500") {
    backgroundGradientColor = "via-amber-200";
  } else if (avatarCardColor === "bg-orange-600") {
    backgroundGradientColor = "via-orange-200";
  } else if (avatarCardColor === "bg-red-600") {
    backgroundGradientColor = "via-red-200";
  } else if (avatarCardColor === "bg-pink-600") {
    backgroundGradientColor = "via-pink-200";
  } else {
    backgroundGradientColor = "via-slate-200";
  }

  console.log("SELECTEDTEAMMEMBERDATA", props.selectedTeamMemberData);

  const [openNotLoggedInModal, setOpenNotLoggedInModal] = React.useState(false);
  const handleOpenNotLoggedInModal = () => {
    setOpenNotLoggedInModal(true);
  };
  const handleCloseNotLoggedInModal = () => {
    setOpenNotLoggedInModal(false);
  };

  const [openEmployeeDeleteWarningModal, setOpenEmployeeDeleteWarningModal] =
    React.useState(false);
  const handleOpenEmployeeDeleteWarningModal = () => {
    setOpenEmployeeDeleteWarningModal(true);
  };
  const handleCloseEmployeeDeleteWarningModal = () => {
    setOpenEmployeeDeleteWarningModal(false);
  };

  return (
    <>
      <div>
        <div
          className={`w-full h-24 bg-gradient-to-r from-slate-50 ${backgroundGradientColor} to-slate-50 flex justify-center relative`}
        >
          <div
            className={`w-20 h-20 rounded-full flex items-center justify-center ${props.avatarCardColor} text-white text-2xl absolute -bottom-10`}
          >
            <p>
              {getInitials(
                props.selectedTeamMemberData.first_name,
                props.selectedTeamMemberData.last_name
              )}
            </p>
          </div>
        </div>
        <div className="mt-14 flex flex-col justify-center items-center">
          <div className="relative">
            {!props.selectedTeamMemberData.user?.has_logged_in && (
              <div className="absolute -left-8 bottom-1.5 cursor-pointer">
                <div
                  className="text-rose-800"
                  onClick={handleOpenNotLoggedInModal}
                >
                  <Tooltip
                    title={`${props.selectedTeamMemberData.first_name} has not logged in yet. Click here if you would like to resend the welcome email.`}
                  >
                    <WarningIcon style={{ fontSize: "22px" }} />
                  </Tooltip>
                </div>
              </div>
            )}
            <p className="text-3xl text-slate-900">
              {props.selectedTeamMemberData.first_name}{" "}
              {props.selectedTeamMemberData.last_name}
            </p>
            <div className="absolute -right-10 bottom-0.5">
              <div
                className="w-8 h-8 rounded-full bg-slate-100 flex items-center justify-center text-slate-500"
                onClick={() => {
                  props.setModalEdit(true);
                  props.handleOpen();
                }}
              >
                <Tooltip title="Edit employee info">
                  <IconButton>
                    <EditIcon style={{ fontSize: "16px" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="absolute -right-20 bottom-0.5">
              <Tooltip title="Delete employee">
                <div
                  className="w-8 h-8 rounded-full bg-slate-100 hover:bg-rose-800 flex items-center justify-center text-rose-800 hover:text-white cursor-pointer"
                  onClick={() => {
                    handleOpenEmployeeDeleteWarningModal();
                  }}
                >
                  <DeleteIcon style={{ fontSize: "16px" }} />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="flex items-center space-x-1.5 text-slate-500 mt-3">
            <div>
              <EmailIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
            </div>
            <p>
              {props.selectedTeamMemberData.email
                ? props.selectedTeamMemberData.email
                : "None"}
            </p>
          </div>
          <div className="flex items-center space-x-1.5 text-slate-500 mt-1">
            <div>
              <PhoneIcon style={{ fontSize: "18px", marginBottom: "2px" }} />
            </div>
            <p>
              {props.selectedTeamMemberData.phone_number
                ? props.selectedTeamMemberData.phone_number
                : "None"}
            </p>
          </div>
          {props.timeEntriesEnabled && (
            <div className="flex items-center space-x-1.5 text-slate-500 mt-1">
              <div>
                <FiberPinIcon
                  style={{ fontSize: "18px", marginBottom: "2px" }}
                />
              </div>
              <p>
                {props.selectedTeamMemberData.clock_pin
                  ? props.selectedTeamMemberData.clock_pin
                  : "None"}
              </p>
            </div>
          )}
          <div className="flex items-center space-x-1 mt-1.5">
            <div
              className={`${props.permissionInfo.style} px-3 py-1 rounded-lg flex items-center justify-center`}
            >
              <p className="text-sm">{props.permissionInfo.label}</p>
            </div>
            {props.selectedTeamMemberData.is_minor &&
              <div
                className={`bg-slate-100 border border-slate-400 text-slate-600 px-3 h-8 rounded-lg flex items-center justify-center`}
              >
                <p className="text-sm">Minor</p>
              </div>
            }
          </div>
        </div>

        <div className="w-full flex justify-center border-y border-slate-200 mt-8">
          <div className="flex items-center space-x-20">
            <div
              className={`${
                activeTab === "Attributes"
                  ? "text-slate-900 border-b-2 border-slate-900 font-bold"
                  : "text-slate-500"
              } py-4 cursor-pointer`}
              onClick={() => handleTabClick("Attributes")}
            >
              <p>Attributes</p>
            </div>
            <div
              className={`${
                activeTab === "Certifications"
                  ? "text-slate-900 border-b-2 border-slate-900 font-bold"
                  : "text-slate-500"
              } py-4 cursor-pointer`}
              onClick={() => handleTabClick("Certifications")}
            >
              <p>Documents</p>
            </div>
            {/* <div className={`${activeTab === "Training" ? "text-slate-900 border-b-2 border-slate-900 font-bold" : "text-slate-500"} py-4 cursor-pointer`} onClick={() => handleTabClick("Training")}>
              <p>Training</p>
            </div> */}
            <div className={`${activeTab === "Statistics" ? "text-slate-900 border-b-2 border-slate-900 font-bold" : "text-slate-500"} py-4 cursor-pointer`} onClick={() => handleTabClick("Statistics")}>
              <p>Statistics</p>
            </div>
            <div className={`${activeTab === "Notes" ? "text-slate-900 border-b-2 border-slate-900 font-bold" : "text-slate-500"} py-4 cursor-pointer`} onClick={() => handleTabClick("Notes")}>
              <p>Notes</p>
            </div>
          </div>
        </div>
        <div className="w-full min-h-[600px] pt-10 pb-24 bg-slate-50">
          {activeTab === "Attributes" ? (
            <div>
              <EmployeeProfileAttributesTab
                selectedTeamMemberData={props.selectedTeamMemberData}
                roleNames={props.roleNames}
                homeStore={props.homeStore}
                updatePersonInfo={props.updatePersonInfo}
              />
            </div>
          ) : activeTab === "Certifications" ? (
            <div>
              <EmployeeProfileCertificationsTab
                certificateData={props.certificateData}
                post_certificate={props.post_certificate}
                delete_certificate={props.delete_certificate}
                stm_id={props.selectedTeamMemberData?.id}
              />
            </div>
          ) : activeTab === "Training" ? (
            <div>Training</div>
          ) : activeTab === "Statistics" ? (
            <div>
              <EmployeeProfileStatisticsTab
                selectedTeamMemberData={props.selectedTeamMemberData}
              />
            </div>
          ) : activeTab === "Notes" ? (
            <div>
              <EmployeeProfileNotesTab
                selectedTeamMemberData={props.selectedTeamMemberData}
                post_logbook_employee_entry={props.post_logbook_employee_entry}
                delete_logbook_employee_entry={props.delete_logbook_employee_entry}
                stm_id={props.selectedTeamMemberData?.id}
                get_file_upload={props.get_file_upload}
                homeCompany={props.homeCompany}
              />
            </div>
          ) : (
            <div>
            </div>
          )}
        </div>
      </div>
      <EmployeeNotLoggedInWarningModal
        open={openNotLoggedInModal}
        handleClose={handleCloseNotLoggedInModal}
        firstName={props.selectedTeamMemberData.first_name}
        lastName={props.selectedTeamMemberData.last_name}
        homeStore={props.homeStore}
        stm_id={props.selectedTeamMemberData?.id}
      />
      <EmployeeDeleteWarningModal
        open={openEmployeeDeleteWarningModal}
        handleClose={handleCloseEmployeeDeleteWarningModal}
        firstName={props.selectedTeamMemberData.first_name}
        lastName={props.selectedTeamMemberData.last_name}
        homeStore={props.homeStore}
        storeNames={props.storeNames}
        stm_id={props.selectedTeamMemberData?.id}
        archive_store_team_member={props.archive_store_team_member}
        setShowUserProfile={props.setShowUserProfile}
      />
    </>
  );
}

export default EmployeeProfile;
