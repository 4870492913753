import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import ShiftCardModalDisplay from "./ShiftCardModalDisplay";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InputIcon from "@mui/icons-material/Input";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import {
  getRoleType,
  getRoleName,
  text_to_num,
  checkIfValid,
  get_t_str,
  get_t_str_shortened,
  createDayArray,
} from "../../../../helpers/helpers";
import ShiftSingleInputModal from "./ShiftSingleInputModal";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../../requests/shared/helpers/DrawerFooterContainerSmall";

// Modal style object
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  maxHeight: 800,
  bgcolor: "background.paper",
  border: "2px solid #E2E8F0",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

function ShiftInputsModal(props) {
  const [shiftsInfo, setShiftsInfo] = React.useState(props.shifts_info);

  function getDayIndex(dateString) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const date = new Date(dateString);
    const dayName = daysOfWeek[date.getDay()];
    return daysOfWeek.indexOf(dayName);
  }

  const personFrequentShiftsForWeek =
    props.frequentShifts?.[`${shiftsInfo?.[0]?.store_team_member}`];
  const dowIndex = getDayIndex(shiftsInfo?.[0].date);
  const personFrequentShiftsForDay = personFrequentShiftsForWeek?.[dowIndex];

  const [mostFrequentShifts, setMostFrequentShifts] = React.useState(
    personFrequentShiftsForDay
  );

  const fillShiftInputWithFrequentShift = (index) => {
    // Create a copy of the shiftsInfo array
    const updatedShiftsInfo = [...shiftsInfo];

    // Update the object at index 0 with the mostFrequentShifts data
    updatedShiftsInfo[0] = {
      ...updatedShiftsInfo[index],
      shift_start: mostFrequentShifts[index].shift_start,
      shift_end: mostFrequentShifts[index].shift_end,
      shift_length: mostFrequentShifts[index].shift_length,
      role_id: mostFrequentShifts[index].role_id_id,
      break_start: mostFrequentShifts[index].break_start,
      break_length: mostFrequentShifts[index].break_length,
    };

    // Update the shiftsInfo state with the modified array
    setShiftsInfo(updatedShiftsInfo);
  };

  const [savingLoading, setSavingLoading] = React.useState(false);
  const [deletingLoading, setDeletingLoading] = React.useState(false);
  const [deleteShifts, setDeleteShifts] = React.useState([]);

  // Handles the shift dropdown in the drawer. Current options are add shift and subtract shift
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handles the frequent shifts dropdown
  const [isFrequentShiftsDropdownOpen, setIsFrequentShiftsDropdownOpen] =
    React.useState(false);

  const dropdownRefFrequentShifts = React.useRef(null);

  const handleButtonClickFrequentShifts = () => {
    setIsFrequentShiftsDropdownOpen(!isFrequentShiftsDropdownOpen);
  };

  const handleClickOutsideFrequentShifts = (event) => {
    if (
      dropdownRefFrequentShifts.current &&
      !dropdownRefFrequentShifts.current.contains(event.target)
    ) {
      setIsFrequentShiftsDropdownOpen(false);
    }
  };

  const [selectedFrequentShift, setSelectedFrequentShift] = React.useState(0);

  const handleSelectFrequentShift = (shift) => {
    setSelectedFrequentShift(shift);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideFrequentShifts);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideFrequentShifts
      );
    };
  }, []);

  function convertToDayOfWeek(dateString) {
    // Map the first three letters of the day string to the corresponding day of the week
    const dayMappings = {
      Sun: "Sundays",
      Mon: "Mondays",
      Tue: "Tuesdays",
      Wed: "Wednesdays",
      Thu: "Thursdays",
      Fri: "Fridays",
      Sat: "Saturdays",
    };

    // Extract the first three letters of the day string
    const dayAbbreviation = dateString.substring(0, 3);

    // Return the corresponding day of the week in plural form
    return dayMappings[dayAbbreviation];
  }

  const [appliedTo, setAppliedTo] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  React.useEffect(() => {
    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, []);

  React.useEffect(() => {
    setShiftsInfo(props.shifts_info);
    setDeleteShifts([]);
  }, [props.shifts_info]);

  function handleAppliedToChange(day_i) {
    let appliedToCopy = [...appliedTo];
    if (day_i !== props.d) {
      appliedToCopy[day_i] = !appliedTo[day_i];
    }
    console.log(appliedToCopy);
    setAppliedTo(appliedToCopy);
  }

  function appliedToButtonGroup() {
    return (
      <div className="w-full mt-2 bg-slate-50 rounded-lg flex">
        <ThemeProvider theme={theme}>
          <ButtonGroup
            style={{
              flex: 1,
              border: "1px solid #f1f5f9",
              borderRadius: "7px",
            }}
          >
            {appliedTo.map((d, i) => (
              <Button
                fullWidth
                color="neutral"
                size="small"
                style={{
                  border: d ? "1px solid #6d28d9" : "1px solid #f8fafc",
                  borderRadius: "7px",
                  backgroundColor: d ? "#ede9fe" : "transparent",
                  padding: "6px",
                  margin: "0 0.5px",
                }}
                key={i}
                onClick={() => handleAppliedToChange(i)}
              >
                {day_abbrevs[i]}
              </Button>
            ))}
          </ButtonGroup>
        </ThemeProvider>
      </div>
    );
  }

  function submitAndClose(shift_save_type) {
    if (shiftsInfo.length === 1 && deleteShifts.length == 0) {
      let state = shiftsInfo[0];
      let shift_info = { ...state };
      shift_info["hours"] =
        state.shift_end - state.shift_start - state.break_length;
      shift_info["shift_length"] = state.shift_end - state.shift_start;
      shift_info["store_team_member"] = props.p.id;

      console.log(shift_info);

      props.addShift(shift_info, shift_save_type, appliedTo);
      if (shift_save_type === "delete") {
        setDeletingLoading(true);
      } else {
        setSavingLoading(true);
      }
    } else {
      let shifts_arr = [...deleteShifts];
      shiftsInfo.map((originalShift, index) => {
        let shift = { ...originalShift }; // copy to prevent mutation
        shift["hours"] =
          shift.shift_end - shift.shift_start - shift.break_length;
        shift["shift_length"] = shift.shift_end - shift.shift_start;
        shift["store_team_member"] = props.p.id;
        if (shift_save_type === "delete") {
          shift["action"] = "delete";
        } else {
          shift["action"] = shift["id"] > 0 ? "edit" : "add";
        }

        shifts_arr.push(shift);
      });
      props.addMultipleShifts(shifts_arr);
      if (shift_save_type === "delete") {
        setDeletingLoading(true);
      } else {
        setSavingLoading(true);
      }
    }
  }

  useEffect(() => {
    if (props.saveShiftFinished) {
      setSavingLoading(false);
      setDeletingLoading(false);
      props.handleClose();
    }
  }, [props.saveShiftFinished]);

  const day_abbrevs = createDayArray(props.businessWeekStartDayIndex);

  function updateShiftAtIndex(updatedShift, index) {
    setShiftsInfo((prevShifts) => {
      // Copy the array
      const updatedShifts = [...prevShifts];
      // Update the specific shift
      updatedShifts[index] = updatedShift;
      return updatedShifts;
    });
  }

  function addShiftForm() {
    //setNumShiftForms(numShiftForms + 1);
    // Default shift info
    let default_shift_info = { ...shiftsInfo[0] };
    default_shift_info["shift_start"] = shiftsInfo[0]["shift_end"];
    default_shift_info["shift_end"] = shiftsInfo[0]["shift_end"] + 4;
    default_shift_info["break_start"] = shiftsInfo[0]["shift_end"];
    default_shift_info["break_end"] = shiftsInfo[0]["shift_end"];
    default_shift_info["break_length"] = 0;
    default_shift_info["id"] = 0;
    setShiftsInfo((prevShifts) => [...prevShifts, default_shift_info]);
  }

  function removeShiftForm() {
    let deleteInfo = shiftsInfo[shiftsInfo.length - 1];
    if (deleteInfo["id"] > 0) {
      deleteInfo["action"] = "delete";
      setDeleteShifts((prevDeleteShifts) => [...prevDeleteShifts, deleteInfo]);
    }
    setShiftsInfo((prevShifts) => prevShifts.slice(0, -1));
  }

  const checkIfAllValid = () => {
    return shiftsInfo.every((shift) =>
      checkIfValid(shift, shift.break_length > 0)
    );
  };

  const inputsFooter = () => {
    return (
      <div className="flex gap-4 mt-4">
        {props.new_shift ? (
          <div />
        ) : (
          <LoadingButton
            variant="contained"
            loading={deletingLoading}
            loadingPosition="end"
            endIcon={<DeleteIcon />}
            onClick={() => submitAndClose("delete")}
            sx={{ textTransform: "none", borderRadius: "8px" }}
            color="error"
          >
            Delete
          </LoadingButton>
        )}
        <LoadingButton
          variant="contained"
          loading={savingLoading}
          loadingPosition="end"
          endIcon={<SaveIcon />}
          disabled={!checkIfAllValid()}
          onClick={() => submitAndClose(props.new_shift ? "add" : "edit")}
          sx={{ textTransform: "none", borderRadius: "8px" }}
        >
          {props.new_shift ? "Create" : "Save"}
        </LoadingButton>
      </div>
    );
  };

  const outerDivClass = {
    1: "",
    2: "w-full h-28 relative",
    3: "w-full h-40 relative",
  };

  const multiModalDisplay = (shift, shift_num, shift_total) => {
    const innerDivClass = {
      1: { 1: "mt-3" },
      2: { 1: "mt-3 absolute left-0 top-0", 2: "absolute left-28 top-12" },
      3: {
        1: "mt-3 absolute left-0 top-0",
        2: "absolute left-24 top-10",
        3: "absolute left-48 bottom-0",
      },
    };

    return (
      <div className={innerDivClass[shift_total][shift_num]}>
        <ShiftCardModalDisplay
          state={shift}
          availability_info={props.availability_info}
          role_names={props.role_names}
          role_types={props.role_types}
        />
      </div>
    );
  };

  const handleCloseNoSave = () => {
    setShiftsInfo(props.shifts_info);
    props.handleClose();
  };

  return (
    <div>
      <Drawer anchor="right" open={props.open} onClose={handleCloseNoSave}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={props.p.first_name + " " + props.p.last_name}
              onClose={handleCloseNoSave}
            />
            <div className="w-full px-6 -mt-6">
              <div>
                <p className="text-sm text-slate-500">{props.date_name}</p>
              </div>
              {
                props.person_day_time_off_requests?.length > 0 ? (
                  <div className="mt-2 pb-5 border-b border-slate-200">
                    <p className="text-sm text-slate-500">This employee has time off today</p>
                  </div>
                ) : (
                  props.availability_info && (
                    <div className="mt-2 pb-5 border-b border-slate-200">
                      {props.availability_info.available_from === 0 &&
                      props.availability_info.available_to === 24 ? (
                        <p className="text-sm text-slate-500">
                          This employee is available all day
                        </p>
                      ) : props.availability_info.available_to === 0 ? (
                        <p className="text-sm text-slate-500">
                          This employee is unavailable today
                        </p>
                      ) : (
                        <p className="text-sm text-slate-500">
                          This employee is available{" "}
                          {get_t_str(props.availability_info.available_from)} to{" "}
                          {get_t_str(props.availability_info.available_to)}
                        </p>
                      )}
                      {props.availability_info.preferred_to > 0 && (
                        <div className="text-sm text-slate-500 mt-1">
                          <p>
                            Preferred:{" "}
                            {get_t_str(props.availability_info.preferred_from)} -{" "}
                            {get_t_str(props.availability_info.preferred_to)}
                          </p>
                        </div>
                      )}
                    </div>
                  )
                )
              }
              <div className="mt-5 pb-5 border-b border-slate-200">
                <div className="w-full flex items-center justify-between">
                  <div className="flex items-center space-x-2 text-slate-900">
                    <h3 className="text-slate-900 font-bold text-sm">
                      Shift(s)
                    </h3>
                    <div className="relative" ref={dropdownRef}>
                      <IconButton onClick={handleButtonClick}>
                        <MoreVertIcon style={{ fontSize: "18px" }} />
                      </IconButton>
                      {isDropdownOpen && (
                        <div className="absolute top-full left-0 w-44 bg-white border border-slate-200 shadow-lg z-30 rounded-lg overflow-hidden">
                          {shiftsInfo.length < 3 && (
                            <div
                              className={`w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-2 cursor-pointer ${
                                shiftsInfo.length > 1
                                  ? "border-b border-slate-200"
                                  : ""
                              }`}
                              onClick={addShiftForm}
                            >
                              <div>
                                <AddIcon
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "2px",
                                  }}
                                />
                              </div>
                              <p>Add shift</p>
                            </div>
                          )}
                          {shiftsInfo.length > 1 && (
                            <div
                              className="w-full py-3 px-4 hover:bg-slate-50 text-sm flex items-center space-x-2 cursor-pointer"
                              onClick={removeShiftForm}
                            >
                              <div>
                                <RemoveIcon
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "2px",
                                  }}
                                />
                              </div>
                              <p>Subtract shift</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {mostFrequentShifts && (
                    <div className="flex items-center">
                      <Tooltip
                        title={`Quick fill ${
                          props.p.first_name
                        }'s most commonly scheduled shift on ${convertToDayOfWeek(
                          props.date_name
                        )}. Or click the dropdown to see more information and options.`}
                        placement={`${
                          isFrequentShiftsDropdownOpen ? "top" : "bottom"
                        }`}
                      >
                        <div
                          className="w-40 h-8 bg-violet-700 hover:bg-violet-800 border-r border-violet-900 text-white flex items-center pl-4 space-x-2 text-sm rounded-l-md cursor-pointer"
                          onClick={() => fillShiftInputWithFrequentShift(0)}
                        >
                          <div>
                            <InputIcon
                              style={{ fontSize: "18px", marginBottom: "1px" }}
                            />
                          </div>
                          <p>
                            {get_t_str_shortened(
                              mostFrequentShifts?.[0]?.shift_start
                            )}{" "}
                            -{" "}
                            {get_t_str_shortened(
                              mostFrequentShifts?.[0]?.shift_end
                            )}
                          </p>
                        </div>
                      </Tooltip>
                      <div
                        className={`w-10 h-8 ${
                          isFrequentShiftsDropdownOpen
                            ? "bg-violet-800"
                            : "bg-violet-700"
                        } hover:bg-violet-800 text-white flex items-center justify-center rounded-r-md cursor-pointer relative`}
                        onClick={handleButtonClickFrequentShifts}
                        ref={dropdownRefFrequentShifts}
                      >
                        {isFrequentShiftsDropdownOpen ? (
                          <ExpandLessIcon style={{ fontSize: "22px" }} />
                        ) : (
                          <ExpandMoreIcon style={{ fontSize: "22px" }} />
                        )}
                        {isFrequentShiftsDropdownOpen && (
                          <div className="absolute w-[200px] top-full right-0 bg-white border border-slate-200 shadow-lg z-30 rounded-lg overflow-hidden">
                            {mostFrequentShifts?.map((shift, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    className="px-4 py-3 hover:bg-slate-50 rounded-lg cursor-pointer relative"
                                    onClick={() =>
                                      fillShiftInputWithFrequentShift(index)
                                    }
                                  >
                                    <p className="text-slate-900 text-sm">
                                      {get_t_str(shift.shift_start)} -{" "}
                                      {get_t_str(shift.shift_end)}
                                    </p>
                                    <p className="text-slate-500 text-xs">
                                      {shift.break_length > 0
                                        ? `B: ${get_t_str(
                                            shift.break_start
                                          )} - ${get_t_str(
                                            shift.break_start +
                                              shift.break_length
                                          )}`
                                        : "No Break"}
                                    </p>
                                    <p className="text-slate-500 text-xs">
                                      {getRoleName(
                                        shift.role_id_id,
                                        props.role_names
                                      )}
                                    </p>
                                  </div>
                                  {/* Conditionally render the divider if it's not the last index */}
                                  {index !== mostFrequentShifts.length - 1 && (
                                    <div>
                                      <Divider />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {shiftsInfo.map((shift, index) => (
                  <div
                    key={index}
                    className="bg-slate-50 py-3 px-4 rounded-lg mt-3"
                  >
                    <ShiftSingleInputModal
                      p={props.p}
                      shift_info={shift}
                      addShiftForm={addShiftForm}
                      removeShiftForm={removeShiftForm}
                      numShiftForms={shiftsInfo.length}
                      onUpdateShift={(updatedShift) =>
                        updateShiftAtIndex(updatedShift, index)
                      }
                      role_names={props.role_names}
                      index={index}
                      start_qhnum={props.start_qhnum}
                      breakWaiverEnabled={props.breakWaiverEnabled}
                    />
                  </div>
                ))}
              </div>
              {props.new_shift && shiftsInfo.length === 1 && (
                <div className="mt-5 pb-5 border-slate-200">
                  <h3 className="text-sm text-slate-900 font-bold">Apply To</h3>
                  <div className="mt-2">{appliedToButtonGroup()}</div>
                </div>
              )}
            </div>
            <div className="w-full h-[100px]"></div>
            {props.new_shift ? (
              <DrawerFooterContainerSmall>
                <LoadingButton
                  variant="contained"
                  loading={savingLoading}
                  loadingPosition="end"
                  endIcon={<SaveIcon />}
                  disabled={!checkIfAllValid()}
                  onClick={() => submitAndClose("add")}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Create
                </LoadingButton>
              </DrawerFooterContainerSmall>
            ) : (
              <DrawerFooterContainerSmall>
                <div className="w-full h-full flex items-center">
                  <div className="w-1/2 pr-2">
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      loading={deletingLoading}
                      loadingPosition="end"
                      endIcon={<DeleteIcon />}
                      onClick={() => submitAndClose("delete")}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "50px",
                        paddingY: "14px",
                        backgroundColor: "#e11d48",
                        "&:hover": {
                          backgroundColor: "#be123c",
                        },
                        fontSize: "16px",
                      }}
                    >
                      Delete
                    </LoadingButton>
                  </div>
                  <div className="w-1/2 pl-2">
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      loading={savingLoading}
                      loadingPosition="end"
                      endIcon={<SaveIcon />}
                      disabled={!checkIfAllValid()}
                      onClick={() => submitAndClose("edit")}
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        borderRadius: "50px",
                        paddingY: "14px",
                        backgroundColor: "#6d28d9",
                        "&:hover": {
                          backgroundColor: "#5b21b6",
                        },
                        fontSize: "16px",
                      }}
                    >
                      Save
                    </LoadingButton>
                  </div>
                </div>
              </DrawerFooterContainerSmall>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ShiftInputsModal;
