import React from "react";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";
import { RosterGraph } from "../../schedule_components/visual_components/RosterGraph";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { process_schedule_data } from "../../../../../helpers/helpers";
import { formatDateReadable } from "../../../requests/utilities/helpers";
import LoadSpinner from "../../../../../utilities/LoadSpinner";
import CircularProgress from "@mui/material/CircularProgress";

function getDiffMetricsByDay(sched_dif) {
  const day_dict = {};
  sched_dif.map((day) => {
    let addCount = 0;
    let modifyCount = 0;
    let deleteCount = 0;
    let addHours = 0;
    let modifyHours = 0;
    let deleteHours = 0;
    day.diffs.map((shift) => {
      if (shift.change_type === "add") {
        addCount += 1;
        addHours += shift.hours_change;
      } else if (shift.change_type === "modify") {
        modifyCount += 1;
        modifyHours += shift.hours_change;
      } else {
        deleteCount += 1;
        deleteHours += shift.hours_change;
      }
    });
    day_dict[day.date] = {
      addCount: addCount,
      modifyCount: modifyCount,
      deleteCount: deleteCount,
      addHours: addHours,
      modifyHours: modifyHours,
      deleteHours: deleteHours,
    };
  });
  return day_dict;
}

export default function AutoScheduleDiffs(props) {
  const [isNew, setIsNew] = React.useState(true);
  const [days, setDays] = React.useState(
    props.sched_dif.map((day) => ({ ...day, status: "pending" }))
  );
  const [currentDayIndex, setCurrentDayIndex] = React.useState(0);

  const goToPreviousDay = () => {
    if (currentDayIndex > -1) {
      setCurrentDayIndex(currentDayIndex - 1);
    }
  };

  const goToNextDay = () => {
    if (currentDayIndex < props.sched_dif.length - 1) {
      setCurrentDayIndex(currentDayIndex + 1);
    }
  };

  const currentDay = days[currentDayIndex];
  const diffMetricsByDay = getDiffMetricsByDay(props.sched_dif);

  // console.log("SCHEDULE DAYS", props.sched_dif)

  function create_new_date(date_str) {
    var myDateArray = date_str.split("-");
    var theDate = new Date(myDateArray[0], myDateArray[1] - 1, myDateArray[2]);
    return theDate;
  }

  function filter_schedule(schedule, currentDay) {
    if (currentDay?.date) {
      return schedule.filter((value, i) => value.date === currentDay.date);
    } else {
      return [];
    }
  }

  const new_schedule_data = process_schedule_data(
    filter_schedule(props.schedule_differences.new_schedule, currentDay),
    props.store_team_member_weekly_info,
    props.role_names
  );

  let newOpenShiftCounter = 1;

  // Transform the array
  let new_transformed_schedule_data = new_schedule_data.map(shift => {
      if (shift.name === "") {
          shift.name = `Open Shift ${newOpenShiftCounter}`;
          newOpenShiftCounter++;
      }
      return shift;
  });

  const old_schedule_data = process_schedule_data(
    filter_schedule(props.actual_schedule, currentDay),
    props.store_team_member_weekly_info,
    props.role_names
  );

  let oldOpenShiftCounter = 1;

  // Transform the array
  let old_transformed_schedule_data = old_schedule_data.map(shift => {
      if (shift.name === "") {
          shift.name = `Open Shift ${oldOpenShiftCounter}`;
          oldOpenShiftCounter++;
      }
      return shift;
  });

  const formattedDate = currentDay
    ? create_new_date(currentDay.date).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : "Week Summary";

  const tableSection = (
    <table className="table-auto border-collapse divide-y divide-gray-300">
      <thead>
        <tr className="text-xs">
          <th className="px-7 py-3"></th>
          <th className="px-7 py-3">Before</th>
          <th className="px-7 py-3">Difference</th>
          <th className="px-7 py-3">After</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        <tr>
          <td className="px-7 py-3">
            <h6 className="font-bold text-xs">Grade</h6>
          </td>
          <td className="px-7 py-3">
            {props.calcLetter(
              props.schedule_differences.metrics.old_sched.score
            )}
          </td>
          <td className="px-7 py-3">
            {(
              props.schedule_differences.metrics.new_sched.score -
              props.schedule_differences.metrics.old_sched.score
            ).toFixed(3) + "%"}
          </td>
          <td className="px-7 py-3">
            {props.calcLetter(
              props.schedule_differences.metrics.new_sched.score
            )}
          </td>
        </tr>
        <tr>
          <td className="px-7 py-3">
            <h6 className="font-bold text-xs">Total Hours</h6>
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.old_sched.hours}
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.new_sched.hours -
              props.schedule_differences.metrics.old_sched.hours}
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.new_sched.hours}
          </td>
        </tr>
        <tr>
          <td className="px-7 py-3">
            <h6 className="font-bold text-xs">Total Shifts</h6>
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.old_sched.shifts}
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.new_sched.shifts -
              props.schedule_differences.metrics.old_sched.shifts}
          </td>
          <td className="px-7 py-3">
            {props.schedule_differences.metrics.new_sched.shifts}
          </td>
        </tr>
      </tbody>
    </table>
  );

  const handleDateClick = (index) => {
    setCurrentDayIndex(index);
    setIsNew(true);
  };

  const handleReject = () => {
    setDays((prevDays) => {
      return prevDays.map((day, index) => {
        return index === currentDayIndex ? { ...day, status: "rejected" } : day;
      });
    });
    if (currentDayIndex !== props.sched_dif.length - 1) {
      setCurrentDayIndex(currentDayIndex + 1);
    }
  };

  const handleAccept = () => {
    setDays((prevDays) => {
      return prevDays.map((day, index) => {
        return index === currentDayIndex ? { ...day, status: "accepted" } : day;
      });
    });
    if (currentDayIndex !== props.sched_dif.length - 1) {
      setCurrentDayIndex(currentDayIndex + 1);
    }
  };

  const handleUndo = () => {
    setDays((prevDays) => {
      return prevDays.map((day, index) => {
        return index === currentDayIndex ? { ...day, status: "pending" } : day;
      });
    });
  };

  const summarySentence = () => {
    const currentDay = days[currentDayIndex];

    if (currentDay.status === "accepted" || currentDay.status === "rejected") {
      return (
        <p
          className={`text-sm ${
            currentDay.status === "accepted"
              ? "text-emerald-800"
              : "text-rose-800"
          }`}
        >
          {`You have ${currentDay.status} the changes for ${formatDateReadable(
            currentDay.date
          )}. The ${
            currentDay.status === "accepted" ? "new" : "old"
          } schedule will go into effect when you save and finish.`}
        </p>
      );
    }

    return "";
  };

  const isDayAcceptedOrRejected = (status) =>
    status === "accepted" || status === "rejected";

  const areAllDaysAcceptedOrRejected = () => {
    return days.every(
      (day) => day.status === "accepted" || day.status === "rejected"
    );
  };

  console.log("DAYS", days);

  const totalHoursChange =
    diffMetricsByDay[currentDay.date]["addHours"] +
    diffMetricsByDay[currentDay.date]["modifyHours"] +
    diffMetricsByDay[currentDay.date]["deleteHours"];

  return (
    <div>
      <div className="w-full flex flex-col items-center">
        {props.sched_dif.length > 0 && (
          <div className="w-full flex items-center justify-center mt-6">
            {currentDayIndex === 0 ? (
              <div className="w-10 h-10 rounded-full bg-slate-100 border border-slate-200 text-slate-500 flex items-center justify-center mr-2">
                <ChevronLeftIcon style={{ fontSize: "28px" }} />
              </div>
            ) : (
              <div
                className="w-10 h-10 rounded-full bg-white border border-slate-200 text-slate-500 flex items-center justify-center mr-2 cursor-pointer"
                onClick={goToPreviousDay}
              >
                <ChevronLeftIcon style={{ fontSize: "28px" }} />
              </div>
            )}
            {/* <button
              className={`py-2 w-28 h-16 px-4 border border-slate-200 text-base ${currentDayIndex === -1 ? "bg-slate-200" : ""}`}
              onClick={() => handleDateClick(-1)}
            >
              Summary
            </button> */}
            {days.map((day, index) => (
              <div
                className={`py-2 w-40 px-4 text-slate-500 rounded-full text-small flex items-center justify-center space-x-1 cursor-pointer ${
                  currentDayIndex === index
                    ? "bg-violet-700 text-white"
                    : "hover:bg-slate-50"
                }`}
                onClick={() => handleDateClick(index)}
              >
                <p>{formatDateReadable(day.date)}</p>
                <div>
                  {day.status === "accepted" ? (
                    <CheckIcon style={{ fontSize: "18px" }} />
                  ) : day.status === "rejected" ? (
                    <CloseIcon style={{ fontSize: "18px" }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
            {currentDayIndex === props.sched_dif.length - 1 ? (
              <div className="w-10 h-10 rounded-full bg-slate-100 border border-slate-200 text-slate-500 flex items-center justify-center ml-2">
                <ChevronRightIcon style={{ fontSize: "28px" }} />
              </div>
            ) : (
              <div
                className="w-10 h-10 rounded-full bg-white border border-slate-200 text-slate-500 flex items-center justify-center ml-2 cursor-pointer"
                onClick={goToNextDay}
              >
                <ChevronRightIcon style={{ fontSize: "28px" }} />
              </div>
            )}
          </div>
        )}
        {currentDayIndex > -1 ? (
          <div className="flex mt-8 w-full">
            <div className="flex-1">
              <div className="mt-2 w-full flex items-center">
                <div className="flex items-center bg-slate-50 w-48 rounded-full">
                  <button
                    className={`w-1/2 py-2 text-sm text-slate-900 rounded-full pl-1 ${
                      !isNew ? "bg-violet-700 text-white w-[65%] pl-0" : ""
                    }`}
                    onClick={() => setIsNew(false)}
                  >
                    Old
                  </button>
                  <button
                    className={`w-1/2 py-2 text-sm text-slate-900 rounded-full pr-1 ${
                      isNew ? "bg-violet-700 text-white w-[65%] pr-0" : ""
                    }`}
                    onClick={() => setIsNew(true)}
                  >
                    New
                  </button>
                </div>
                {/* <div className="ml-4">{summarySentence()}</div> */}
              </div>
              {isNew ? (
                <div className="h-[500px] rounded-xl mt-4 -ml-10">
                  <RosterGraph
                    data={new_transformed_schedule_data}
                    chartid="rostergraph"
                    get_t_str={props.get_t_str}
                    role_types={props.role_types}
                  />
                </div>
              ) : (
                <div className="h-[500px] rounded-xl mt-4 -ml-10">
                  <RosterGraph
                    data={old_transformed_schedule_data}
                    chartid="rostergraph"
                    get_t_str={props.get_t_str}
                    role_types={props.role_types}
                  />
                </div>
              )}
              <div className="w-full flex items-center justify-between -mt-8 pb-8">
                {isDayAcceptedOrRejected(currentDay.status) ? (
                  <div className="flex items-center space-x-2">
                    <div
                      className={`${
                        currentDay.status === "rejected"
                          ? "bg-rose-600 text-white cursor-pointer"
                          : "bg-slate-200 text-slate-400"
                      } rounded-full py-2 flex items-center justify-center space-x-1 w-32`}
                      onClick={currentDay.status === "rejected" && handleUndo}
                    >
                      <div>
                        <CloseIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      </div>
                      <p className="pr-2">
                        {currentDay.status === "rejected"
                          ? "Rejected"
                          : "Reject"}
                      </p>
                    </div>
                    <div
                      className={`${
                        currentDay.status === "accepted"
                          ? "bg-emerald-600 text-white cursor-pointer"
                          : "bg-slate-200 text-slate-400"
                      } rounded-full py-2 flex items-center justify-center space-x-1 w-32`}
                      onClick={currentDay.status === "accepted" && handleUndo}
                    >
                      <div>
                        <CheckIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      </div>
                      <p className="pr-2">
                        {currentDay.status === "accepted"
                          ? "Accepted"
                          : "Accept"}
                      </p>
                    </div>
                    <Tooltip title="Undo" placement="top">
                      <div
                        className="w-10 h-10 rounded-full bg-violet-700 hover:bg-violet-800 text-white flex items-center justify-center cursor-pointer"
                        onClick={handleUndo}
                      >
                        <UndoIcon style={{ fontSize: "18px" }} />
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="flex items-center space-x-2">
                    <div
                      className="bg-rose-600 hover:bg-rose-700 text-white rounded-full py-2 flex items-center justify-center space-x-1 w-32 cursor-pointer"
                      onClick={handleReject}
                    >
                      <div>
                        <CloseIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      </div>
                      <p className="pr-2">Reject</p>
                    </div>
                    <div
                      className="bg-emerald-600 hover:bg-emerald-700 text-white rounded-full py-2 flex items-center justify-center space-x-1 w-32 cursor-pointer"
                      onClick={handleAccept}
                    >
                      <div>
                        <CheckIcon
                          style={{ fontSize: "18px", marginBottom: "2px" }}
                        />
                      </div>
                      <p className="pr-2">Accept</p>
                    </div>
                  </div>
                )}
                <div className="mr-8">
                  {areAllDaysAcceptedOrRejected() ? (
                    <div
                      onClick={() => props.saveAndClose(days)}
                      className="px-10 py-2 bg-violet-700 hover:bg-violet-800 rounded-full text-white cursor-pointer"
                    >
                      {props.acceptedLoading ? (
                        <CircularProgress size={14} sx={{ color: "white" }}/>
                      ) : (
                        "Save and finish"
                      )}
                    </div>
                  ) : (
                    <Tooltip
                      title="You must accept or reject every day before you can save and finish"
                      placement="top"
                    >
                      <div className="px-10 py-2 bg-slate-200 text-slate-400 rounded-full">
                        Save and finish
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="rounded-xl bg-slate-50 p-3">
                <div className="flex items-center justify-between w-56">
                  <h3>Shift Changes</h3>
                  <div
                    className={`text-sm px-3 py-1 ${
                      totalHoursChange < 0
                        ? "bg-rose-50 border border-rose-200 text-rose-800"
                        : "bg-emerald-50 border border-emerald-200 text-emerald-800"
                    } rounded-lg`}
                  >
                    {totalHoursChange > 0 && "+"}
                    {totalHoursChange} hrs
                  </div>
                </div>
                <div className="mt-8">
                  {diffMetricsByDay[currentDay.date]["addCount"] > 0 && (
                    <div>
                      <p>
                        {diffMetricsByDay[currentDay.date]["addCount"]} new{" "}
                        {/* <span className="text-slate-500 text-sm">
                          (
                          {diffMetricsByDay[currentDay.date]["addHours"] > 0 &&
                            "+"}
                          {diffMetricsByDay[currentDay.date]["addHours"]} hrs)
                        </span> */}
                      </p>
                      <div className="mt-2">
                        {currentDay.diffs.map((shift, index) => {
                          if (shift.change_type === "add") {
                            return (
                              <div
                                key={index}
                                className="flex items-center justify-between mt-1"
                              >
                                <p className="text-sm text-slate-500">
                                  {shift.name}
                                </p>
                                <p className="text-slate-500 text-sm">
                                  {shift.hours_change > 0 && "+"}
                                  {shift.hours_change} hrs
                                </p>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {diffMetricsByDay[currentDay.date]["modifyCount"] > 0 && (
                  <div className="mt-6">
                    <p>
                      {diffMetricsByDay[currentDay.date]["modifyCount"]}{" "}
                      modified{" "}
                      {/* {  <span className="text-slate-500 text-sm">
                        (
                        {diffMetricsByDay[currentDay.date]["modifyHours"] > 0 &&
                          "+"}
                        {diffMetricsByDay[currentDay.date]["modifyHours"]} hrs)
                      </span>} */}
                    </p>
                    <div className="mt-2">
                      {currentDay.diffs.map((shift, index) => {
                        if (shift.change_type === "modify") {
                          return (
                            <div
                              key={index}
                              className="flex items-center justify-between mt-1"
                            >
                              <p className="text-sm text-slate-500">
                                {shift.name}
                              </p>
                              <p className="text-slate-500 text-sm">
                                {shift.hours_change > 0 && "+"}
                                {shift.hours_change} hrs
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}
                {diffMetricsByDay[currentDay.date]["deleteCount"] > 0 && (
                  <div className="mt-6">
                    <p>
                      {diffMetricsByDay[currentDay.date]["deleteCount"]} deleted{" "}
                      {/* <span className="text-slate-500 text-sm">
                      ({diffMetricsByDay[currentDay.date]["deleteHours"]} hrs)
                    </span> */}
                    </p>
                    <div className="mt-2">
                      {currentDay.diffs.map((shift, index) => {
                        if (shift.change_type === "delete") {
                          return (
                            <div
                              key={index}
                              className="flex items-center justify-between mt-1"
                            >
                              <p className="text-sm text-slate-500">
                                {shift.name}
                              </p>
                              <p className="text-slate-500 text-sm">
                                {shift.hours_change > 0 && "+"}
                                {shift.hours_change} hrs
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}
                {/* <div className="w-56 text-xs p-2 border border-gray-300 rounded-xl px-3">
                  <div>
                    <div className="flex items-center space-x-3">
                      <div className="text-emerald-600">
                        <AddCircleIcon style={{ fontSize:"16px"}} />
                      </div>
                      <p>
                        {diffMetricsByDay[currentDay.date]["addCount"]} shifts added
                        ({diffMetricsByDay[currentDay.date]["addHours"] > 0 && "+"}{diffMetricsByDay[currentDay.date]["addHours"]} hrs)
                      </p>
                    </div>
                    <div className="mt-2 flex items-center space-x-3">
                      <div className="text-amber-600">
                        <ChangeHistoryIcon style={{ fontSize:"16px"}} />
                      </div>
                      <p>
                        {diffMetricsByDay[currentDay.date]["modifyCount"]} shifts
                        modified ({diffMetricsByDay[currentDay.date]["modifyHours"] > 0 && "+"}{diffMetricsByDay[currentDay.date]["modifyHours"]}{" "}
                        hrs)
                      </p>
                    </div>
                    <div className="mt-2 flex items-center space-x-3">
                      <div className="text-rose-600">
                        <RemoveCircleIcon style={{ fontSize:"16px"}} />
                      </div>
                      <p>
                        {diffMetricsByDay[currentDay.date]["deleteCount"]} shifts
                        deleted ({diffMetricsByDay[currentDay.date]["deleteHours"]}{" "}
                        hrs)
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  {currentDay.diffs.map((shift) => (
                    <div
                      className={`w-56 flex items-center text-xxs text-slate-900 py-1 px-4 mt-1.5 rounded-lg ${
                        shift.change_type === "add" && "border border-emerald-800 bg-emerald-50"
                      } ${shift.change_type === "modify" && "border border-amber-800 bg-yellow-50"} ${
                        shift.change_type === "delete" && "border border-rose-800 bg-rose-50"
                      }`}
                    >
                      <p className="w-20">{shift.name}</p>
                      <p>{shift.role_type}</p>
                      <p className="flex-1 text-end">{shift.hours_change > 0 && "+"}{shift.hours_change} hrs</p>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-center mt-5">
            {tableSection}
            <div className="w-full flex items-center justify-center gap-10 mt-8">
              <Button
                variant="contained"
                endIcon={<CancelIcon />}
                onClick={() => props.cancelAndClose()}
                sx={{ textTransform: "none", borderRadius: "8px" }}
                color="error"
              >
                {"Cancel"}
              </Button>
              <LoadingButton
                variant="contained"
                loading={props.acceptedLoading}
                loadingPosition="end"
                endIcon={<CheckIcon />}
                onClick={() => props.saveAndClose()}
                sx={{ textTransform: "none", borderRadius: "8px" }}
              >
                {"Accept"}
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
