import React from "react";

function ScheduledNameCard(props) {
  const employee = props.employee;
  const onDragStart = props.onDragStart;
  const formatTime = props.formatTime;
  const selectedDaypart = props.selectedDaypart || {
    start_time: 0,
    end_time: 0,
  }; // <-- Add a default value here
  const isAssigned = props.isAssigned;
  const onNoShowClick = props.onNoShowClick;
  const isNoShow = props.isNoShow;

  const lineStartPercentage =
    Math.max(
      0,
      (employee.shift_start * 60 - selectedDaypart.start_time * 60) /
        ((selectedDaypart.end_time - selectedDaypart.start_time) * 60)
    ) * 100;

  let lineWidthPercentage =
    ((Math.min(employee.shift_end * 60, selectedDaypart.end_time * 60) -
      Math.max(employee.shift_start * 60, selectedDaypart.start_time * 60)) /
      (selectedDaypart.end_time * 60 - selectedDaypart.start_time * 60)) *
    100;

  lineWidthPercentage = lineStartPercentage > 100 ? 0 : lineWidthPercentage;

  return (
    <div
      key={employee.id}
      className={`border border-gray-300 bg-white p-2 mb-2 rounded-t ${
        isAssigned ? "bg-gray-200" : isNoShow ? "bg-red-200" : "bg-white"
      } relative`}
      draggable={!isAssigned && !isNoShow}
      onDragStart={(event) => onDragStart(event, employee.name)}
      onClick={onNoShowClick}
    >
      <span className="text-sm font-medium">{employee.name}</span>
      <span
        className={`text-xs ml-2 ${
          employee.shift_start * 60 > selectedDaypart.start_time * 60
            ? "text-gray-500"
            : "text-gray-500"
        }`}
      >
        {formatTime(employee.shift_start * 60)}
      </span>
      <span className="text-xs text-gray-500"> - </span>
      <span
        className={`text-xs ${
          employee.shift_end * 60 < selectedDaypart.end_time * 60
            ? "text-gray-500"
            : "text-gray-500"
        }`}
      >
        {formatTime(employee.shift_end * 60)}
      </span>
      <div
        className="bg-sky-800 absolute bottom-0 h-1"
        style={{
          width: `${lineWidthPercentage}%`,
          left: `${lineStartPercentage}%`,
        }}
      ></div>
    </div>
  );
}

export default ScheduledNameCard;
