import amplitude from "amplitude-js";
//Code to import config info about your app
import { AMP_KEY } from "./constants";

const API_KEY = AMP_KEY;

//If you only have one environment, just put the single API key
amplitude.getInstance().init(API_KEY, null, {
  // optional configuration options
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
});
export { amplitude };
