import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { API_get_chat_users } from '../../../constants';
import { getRightworkAPI } from '../requests/utilities/requests';
import { formatName } from '../../../helpers/helpers';
import TabsTwo from '../requests/shared/helpers/TabsTwo';

function CreateChannelModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const [inputValue, setInputValue] = React.useState('');

  const handleChannelNameChange = (event) => {
    props.setChannelName(event.target.value);
  };

  const handleMembersChange = (event, newValue) => {
    props.setSelectedMembers(newValue);
  };

  function handleTabButtonPress(target) {
    props.setModalType(target);
    if (props.modalType === "Channel") {
      props.setSelectedMembers([])
    } else {
      props.setSelectedMembers([])
    }
  }

  // console.log("CHAT USERS", props.chatUsers)

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={() => props.handleClose()}
          >
            <CloseIcon />
          </div>
          <div>
            <h3 className="text-2xl">{props.modalType === "Channel" ? "Create New Channel" : "Create New Direct Message"}</h3>
            <div className="mt-5">
              <TabsTwo
                activeTab={props.modalType}
                handleTabButtonPress={handleTabButtonPress}
                tab1="Channel"
                tab2="Direct Message"
              />
            </div>
            {props.modalType === "Channel" &&
              <div className="mt-5">
                <p className="text-sm text-slate-500">Channel Name</p>
                <div className="mt-1.5">
                  <TextField
                    placeholder="channel-name (no spaces)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="name"
                    value={props.channelName}
                    onChange={handleChannelNameChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "5px",
                        height: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            }
            <div className="mt-5">
              <p className="text-sm text-slate-500">{props.modalType === "Channel" ? "Add Members" : "Select Member To DM"}</p>
              <div className="mt-1.5">
              <Autocomplete
                multiple={props.modalType === "Channel"}
                fullWidth
                value={props.selectedMembers}
                onChange={handleMembersChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                disableClearable={props.modalType !== "Channel"}
                disableCloseOnSelect={props.modalType === "Channel"}
                options={props.chatUsers}
                getOptionLabel={(option) => formatName(option.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.modalType === "Channel" ? "Select members to add to channel" : "Select member to DM"}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px !important",
                        minHeight: "45px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6d28d9",
                        },
                        backgroundColor: "white",
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                      sx={{
                        color: "#6d28d9",
                        "&.Mui-checked": {
                          color: "#6d28d9",
                        },
                      }}
                    />
                    {formatName(option.name)}
                  </li>
                )}
              />
              </div>
            </div>
            <div className="w-full flex justify-end mt-8">
              <div className="flex space-x-3">
                <div
                  className="px-8 py-2 bg-white border border-violet-700 text-violet-700 hover:bg-slate-100 rounded-full text-base w-fit cursor-pointer"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </div>
                {props.modalType === "Channel" ?
                  props.channelName ?
                    <div
                      className="px-8 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                      onClick={
                        () => props.handleCreateChannel(props.channelName, props.selectedMembers)
                      }
                    >
                      Create Channel
                    </div> :
                    <div
                      className="px-8 py-2 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                    >
                      Create Channel
                    </div> :
                  props.selectedMembers ?
                    <div
                      className="px-8 py-2 bg-violet-50 hover:bg-violet-100 text-violet-700 rounded-full text-base w-fit cursor-pointer"
                      onClick={() => props.handleCreateDM(props.selectedMembers)}
                    >
                      Create DM
                    </div> :
                    <div
                      className="px-8 py-2 bg-slate-200 text-slate-400 rounded-full text-base w-fit"
                    >
                      Create DM
                    </div>
                }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CreateChannelModal;
