import React from "react";
import Button from "@mui/material/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PurpleSwitch } from "../../../../helpers/helpers";

// This is actually the toggle between schedule display and availability display
function EditTeamButton(props) {
  return (
    <div className="w-full h-full bg-white -ml-10 pl-10">
      <h2 className="font-bold">Mode</h2>
      <div className="flex items-center">
        <h3 className="text-xxs">Schedule</h3>
        {/* <h3 className="text-xxs hidden xl:block">Sched</h3> */}
        <PurpleSwitch
          checked={props.editTeam}
          onClick={props.toggleEditTeam}
          // inputProps={{ 'aria-label': 'controlled' }}
        />
        <h3 className="text-xxs">Availability</h3>
        {/* <h3 className="text-xxs hidden xl:block">Avail</h3> */}
      </div>
    </div>
  );
}

export default EditTeamButton;
