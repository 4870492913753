import React, { useState } from "react";

function AddDaypartModal(props) {
  const isOpen = props.isOpen;
  const onClose = props.onClose;
  const onAdd = props.onAdd;

  const [name, setName] = useState("");
  const [start, setStart] = useState("");

  const handleSubmit = () => {
    if (name && start) {
      onAdd(name, Number(start), Number(start) + 4); // Assuming a default duration of 4 hours for the new daypart
      setName("");
      setStart("");
      onClose();
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      <div className="bg-white p-5 rounded shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Add New Daypart</h2>
        <input
          type="text"
          className="border border-gray-300 p-2 w-full mb-4"
          placeholder="Daypart Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="number"
          className="border border-gray-300 p-2 w-full mb-4"
          placeholder="Start Time (hour)"
          min="0"
          max="23"
          value={start}
          onChange={(e) => setStart(e.target.value)}
        />
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            onClick={handleSubmit}
          >
            Add
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default AddDaypartModal;
