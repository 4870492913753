import React, { useState } from "react";
import AddDaypartModal from "./AddDaypartModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function Daypart({ daypart, selected, onSelect }) {
  const handleClick = () => {
    onSelect(daypart);
  };

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <div
      className={`p-4 m-1 text-center cursor-pointer border-2 ${
        selected ? "border-sky-700" : "border-transparent"
      }`}
      onClick={handleClick}
    >
      <div className="text-xl">{daypart.name}</div>
      <div className="text-sm text-gray-500">
        {formatTime(daypart.start_time * 60)} -{" "}
        {formatTime(daypart.end_time * 60)}
      </div>
    </div>
  );
}

function DaypartSelector({
  dayparts,
  selectedDaypart,
  onSelect,
  onAdd,
  onDelete,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelect = (daypart) => {
    onSelect(daypart);
  };

  return (
    <div className="flex justify-center items-center">
      {dayparts.map((daypart) => (
        <Daypart
          key={daypart.name}
          daypart={daypart}
          selected={daypart === selectedDaypart}
          onSelect={handleSelect}
        />
      ))}
      <IconButton onClick={handleAddClick}>
        <AddCircleOutlineIcon className="text-green-500" />
      </IconButton>

      <IconButton onClick={onDelete}>
        <HighlightOffIcon className="text-red-500" />
      </IconButton>
      <AddDaypartModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onAdd={(name, start, end) => onAdd(name, start, end)}
      />
    </div>
  );
}

export default DaypartSelector;
