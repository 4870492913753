import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { AvailabilityGraph } from "./visual_components/AvailabilityGraph";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VizualizeAvailOrSchedDepartmentFilter from "./VizualizeAvailOrSchedDepartmentFilter";

export default function VizualizeWeeklyAvailOrSched(props) {
  const [open, setOpen] = React.useState(false);

  // Holds the state of the department selection before the modal is open
  const [
    departmentSelectionBeforeOpenModal,
    setDepartmentSelectionBeforeOpenModal,
  ] = React.useState();

  // Event handler for opening the modal
  const handleOpen = () => {
    setDepartmentSelectionBeforeOpenModal(props.roleType);
    setOpen(true);
  };

  // Event handler for closing the modal
  const handleClose = () => {
    props.setRoleType(departmentSelectionBeforeOpenModal);
    setOpen(false);
  };

  // Modal style object
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "95%",
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    paddingX: 5,
    paddingY: 4,
    // overflow: "scroll"
  };

  const minOpenTime = props.hours_of_operation.reduce(
    (min, current) => Math.min(min, current.open_time),
    Infinity
  );
  const maxCloseTime = props.hours_of_operation.reduce(
    (max, current) => Math.max(max, current.close_time),
    -Infinity
  );

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 480,
    },
  });

  return (
    <>
      <button
        type="button"
        className="w-[168px] py-0.5 rounded-lg shadow bg-violet-50 text-violet-500 text-center hover:bg-violet-100 flex items-center gap-1 justify-center"
        onClick={handleOpen}
      >
        <div>
          <BlurOnIcon style={{ fontSize: "18px", marginBottom: "3px" }} />
        </div>
        <p className="text-xxs mr-1">Availability Coverage</p>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="absolute right-2 top-2 text-gray-500 cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <div>
            <div className="flex justify-between w-[88%]">
              <div>
                <h2 className="text-xl font-bold">Availability Coverage</h2>
                <div className="w-[92%]">
                  <h4 className="text-gray-500 mt-3">
                    Visualize where the gaps are in your availability versus
                    demand. Each color represents the number of employees you
                    have available above the forecasted demand for that
                    15-minute time period.
                  </h4>
                </div>
              </div>
              <div className="flex items-end relative z-30">
                <VizualizeAvailOrSchedDepartmentFilter
                  roleType={props.roleType}
                  setRoleType={props.setRoleType}
                  roleTypeOptions={props.roleTypeOptions}
                />
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-[90%] mt-5">
                <AvailabilityGraph
                  availability={props.store_team_member_weekly_info_view}
                  forecasted_labor={props.forecasted_labor}
                  chartid="availabilitygraph"
                  minOpenTime={minOpenTime}
                  maxCloseTime={maxCloseTime}
                  roleType={props.roleType}
                />
              </div>
              <div className="flex-1 h-full mt-5 text-slate-500 text-sm mr-3 relative">
                <div className="absolute right-2.5 -top-16 text-slate-400">
                  <CustomWidthTooltip
                    placement="bottom-start"
                    title={
                      <div>
                        <p>
                          This legend represents your buffer. The buffer is the
                          percentage of available employees that are not
                          required to work to meet your forecasted demand.
                        </p>
                        <div className="w-full mt-4 flex items-center justify-center space-x-4 pb-3">
                          <div className="flex items-center space-x-3">
                            <p>buffer_percentage</p>
                            <p>=</p>
                          </div>
                          <div className="w-fit">
                            <p className="pb-1 border-b border-slate-200 text-center">
                              employees_available - employees_needed
                            </p>
                            <p className="mt-1 text-center">
                              employees_available
                            </p>
                          </div>
                          <div className="flex items-center space-x-3">
                            <p className="mt-1.5">*</p>
                            <p>100</p>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <InfoOutlinedIcon />
                  </CustomWidthTooltip>
                </div>
                <div className="absolute -top-7 left-10 text-[#69B34C] w-28">
                  <p>More availability</p>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"> 60%"}
                  </div>
                  <div className="flex-1 h-full bg-[#69B34C]"></div>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"50 - 60%"}
                  </div>
                  <div className="flex-1 h-full bg-[#ACB334]"></div>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"40 - 50%"}
                  </div>
                  <div className="flex-1 h-full bg-[#FAB733]"></div>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"30 - 40%"}
                  </div>
                  <div className="flex-1 h-full bg-[#FF8E15]"></div>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"20 - 30%"}
                  </div>
                  <div className="flex-1 h-full bg-[#FF4E11]"></div>
                </div>
                <div className="w-full flex space-x-3 h-[94px]">
                  <div className="w-[55%] text-right flex items-center justify-end">
                    {"<= 20%"}
                  </div>
                  <div className="flex-1 h-full bg-[#FF0D0D]"></div>
                </div>
                <div className="absolute -bottom-7 left-[44px] text-[#FF0D0D] w-28">
                  <p>Less availability</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
