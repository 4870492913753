import React, { useEffect } from "react";
import ShiftInputsModal from "./ShiftInputsModal";
import ShiftRecapModal from "./ShiftRecapModal";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import {
  getCardColor,
  getCardColor2,
  getRoleType,
  get_t_str,
} from "../../../../helpers/helpers";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";

function ShiftCard(props) {
  // Opens the modal when true
  const [open, setOpen] = React.useState(false);

  // Shows the copy button for copy and paste when true
  const [showActionButtons, setShowActionButtons] = React.useState(false);

  //const [showPasteButton, setShowPasteButton] = React.useState(false);

  // Shows the check when they have successfully copied a shift when true
  const [showCopiedCheck, setShowCopyCheck] = React.useState(false);

  // Shows the check when they have successfully cut a shift when true
  const [showCutCheck, setShowCutCheck] = React.useState(false);

  // Function that handles the opening of the modal
  const handleOpen = () => {
    setOpen(!props.locked_mode);
  };

  // Function that handles the closing of the modal
  const handleClose = () => {
    setOpen(false);
    props.setSaveShiftFinished(false);
    setShowActionButtons(false);
  };

  // Function that handles the context menu
  const handleContextMenu = (event) => {
    event.preventDefault();
    if (showActionButtons) {
      props.setCopiedShift(null);
      setShowActionButtons(false);
    } else {
      setShowActionButtons((prevState) => !prevState);
    }
  };

  // Function that handles the copying of a shift
  const handleCopy = (shift) => {
    if (showCopiedCheck === false) {
      shift["copy_type"] = "copy";
      props.setCopiedShift(shift);
      setShowCopyCheck(true);
      setShowCutCheck(false);
    } else {
      props.setCopiedShift(null);
      setShowCopyCheck(false);
      setShowCutCheck(false);
    }
  };

  const handleCut = (shift) => {
    if (showCutCheck === false) {
      shift["copy_type"] = "cut";
      props.setCopiedShift(shift);
      setShowCutCheck(true);
      setShowCopyCheck(false);
    } else {
      props.setCopiedShift(null);
      setShowCutCheck(false);
      setShowCopyCheck(false);
    }
  };

  // Hides the copy button and copy check when props.copiedShift changes
  useEffect(() => {
    if (props.copiedShift !== props.shift_info) {
      setShowActionButtons(false);
      setShowCopyCheck(false);
      setShowCutCheck(false);
    }
  }, [props.copiedShift]);

  // Function that gets the role name
  const getRoleName = (role_id, role_lookups) => {
    const role_info = role_lookups.filter((r) => r.id === role_id);
    if (role_info.length > 0) {
      return role_info[0]["role_name"];
    } else {
      return null;
    }
  };

  // const shiftCardColor = getCardColor(
  //   props.shift_info.role_id,
  //   props.role_names,
  //   props.role_types,
  //   props.locked_mode
  // );

  const shiftCardColor = getCardColor2(
    props.shift_info.role_id,
    props.role_names,
    props.locked_mode,
    true
  );

  // Function  that handles the drag start when dragging and dropping a shift
  const handleDragStart = (e, shift) => {
    //e.dataTransfer.setData("shift", JSON.stringify(shift));
    props.setCopiedShift(shift);
  };

  // Handles opening up shift recap modal

  const [openRecapModal, setOpenRecapModal] = React.useState(false);
  const handleOpenRecapModal = () => {
    // console.log("Would open recap modal");
    setOpenRecapModal(props.locked_mode);
  };
  const handleCloseRecapModal = () => {
    setOpenRecapModal(false);
  };

  console.log("SHIFT INFO", props.shift_info)

  return (
    <>
      <style>
        {`
          @keyframes wiggle {
            0%, 100% {
              transform: rotate(-2deg);
            }
            50% {
              transform: rotate(2deg);
            }
          }
          .wiggle {
            animation: wiggle 0.3s ease-in-out infinite;
          }
        `}
      </style>
      <div className="w-full h-full relative">
        {!showActionButtons ? (
          <div
            onClick={!props.day_in_past ? handleOpen : handleOpenRecapModal}
            className={`w-full h-full flex overflow-hidden bg-white rounded-lg ${props.showUnpublishedShifts && props.shift_info?.is_publishable ? "wiggle" : ""} shadow-md ${
              props.weekly_or_daily === "weekly" ? "max-w-sm" : ""
            } ${
              props.shift_info?.publishedschedule?.missed_shift_reason
                ? "border-2 border-rose-500"
                : ""
            }`}
            onContextMenu={(event) => handleContextMenu(event)}
            draggable={!props.locked_mode}
            onDragStart={(e) => handleDragStart(e, props.shift_info)}
            onDragEnd={(e) => props.setCopiedShift(null)}
          >
            <div
              className={`w-full h-full flex items-center py-3 px-2 text-white relative ${shiftCardColor}`}
            >
              <div className="w-full mx-1">
                {props.weekly_or_daily === "weekly" ? (
                  <div>
                    <p className="text-xs font-bold xl:font-normal">
                      {get_t_str(props.shift_info.shift_start) +
                        " - " +
                        get_t_str(props.shift_info.shift_end)}
                    </p>
                    {/* {!props.minimizeMode && (
                      <p className="text-xxxs lg:hidden">
                        {props.shift_info.break_length > 0
                          ? "Break: " +
                            get_t_str(props.shift_info.break_start) +
                            " (" +
                            props.shift_info.break_length +
                            "hr)"
                          : "Break: None"}
                      </p>
                    )} */}
                    {!props.minimizeMode &&
                      (props.shift_info.station ? (
                        <p className="text-xxs xl:hidden">
                          {props.shift_info.station.name}
                        </p>
                      ) : getRoleName(
                          props.shift_info.role_id,
                          props.role_names
                        ) ? (
                        <p className="text-xxs xl:hidden">
                          {getRoleName(
                            props.shift_info.role_id,
                            props.role_names
                          )}
                        </p>
                      ) : (
                        <p className="text-xxs xl:hidden">
                          {props.shift_info.role_type}
                        </p>
                      ))}
                  </div>
                ) : (
                  <div className="h-full flex items-center gap-4 pl-3">
                    <p className="text-xs font-bold xl:font-normal">
                      {get_t_str(props.shift_info.shift_start) +
                        " - " +
                        get_t_str(props.shift_info.shift_end)}
                    </p>
                    {props.shift_info.station ? (
                      <p className="text-xs xl:hidden">
                        {props.shift_info?.station.name}
                      </p>
                    ) : getRoleName(
                        props.shift_info.role_id,
                        props.role_names
                      ) ? (
                      <p className="text-xs xl:hidden">
                        {getRoleName(props.shift_info.role_id, props.role_names)}
                      </p>
                    ) : (
                      <p className="text-xs xl:hidden">
                        {props.shift_info.role_type}
                      </p>
                    )}
                  </div>
                )}
                {(!props.availability_info ||
                (props.availability_info.available_from === 0 &&
                  props.availability_info.available_to === 24 &&
                  !props.time_off_conflict)
                  ? false
                  : props.availability_info.available_from >
                      props.shift_info.shift_start ||
                    props.availability_info.available_to <
                      props.shift_info.shift_end ||
                    props.time_off_conflict) && (
                  <div
                    className={`absolute ${
                      props.minimizeMode ? "right-1 bottom-0" : "right-2 bottom-1"
                    }`}
                  >
                    <Tooltip
                      title={
                        props.time_off_conflict
                          ? "Time off requested for this day"
                          : "Shift outside of availability"
                      }
                    >
                      <WarningIcon
                        sx={{
                          fontSize: props.minimizeMode ? "8px" : "10px",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            // onClick={handleOpen}
            className={
              "w-full h-full flex overflow-hidden bg-white rounded-lg shadow-md " +
              (props.weekly_or_daily === "weekly" ? "max-w-sm" : "")
            }
            onContextMenu={(event) => handleContextMenu(event)}
            // draggable={!props.locked_mode}
            // onDragStart={(e) => handleDragStart(e, props.shift_info)}
            // onDragEnd={(e) => props.setCopiedShift(null)}
          >
            <div
              className={`w-full h-full flex items-center py-3 px-2 text-white relative ${shiftCardColor}`}
            >
              <div className="w-full mx-1">
                <div className="w-full flex items-center justify-between space-x-1.5">
                  <Tooltip title={"Edit or add shift"} arrow>
                    <div
                      className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center"
                      onClick={handleOpen}
                    >
                      <EditIcon style={{ fontSize: "14px" }} />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      !showCopiedCheck
                        ? "Copy shift"
                        : "Shift copied. When you paste this shift to another slot, the original will remain"
                    }
                    arrow
                  >
                    <div
                      className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center"
                      onClick={() => handleCopy(props.shift_info)}
                    >
                      {!showCopiedCheck ? (
                        <ContentCopyIcon style={{ fontSize: "14px" }} />
                      ) : (
                        <CheckIcon style={{ fontSize: "20px" }} />
                      )}
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      !showCutCheck
                        ? "Cut shift"
                        : "Shift cut. When you paste this shift to another slot, the original will disappear"
                    }
                    arrow
                  >
                    <div
                      className="flex-1 h-8 bg-slate-800 opacity-50 hover:bg-slate-700 rounded flex items-center justify-center"
                      onClick={() => handleCut(props.shift_info)}
                    >
                      {!showCutCheck ? (
                        <ContentCutIcon style={{ fontSize: "14px" }} />
                      ) : (
                        <CheckIcon style={{ fontSize: "20px" }} />
                      )}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {showActionButtons && !showCopiedCheck && (
          <div
            className={`absolute ${
              props.minimizeMode ? "-left-6 bottom-1" : "-left-6 bottom-5"
            }`}
          >
            <Tooltip title={"Copy Shift"}>
              <IconButton onClick={() => handleCopy(props.shift_info)}>
                <ContentCopyIcon style={{ fontSize: "14px" }} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {showCopiedCheck && (
          <div
            className={`absolute ${
              props.minimizeMode ? "-left-6 bottom-1" : "-left-6 bottom-5"
            }`}
          >
            <Tooltip title={"Shift Copied!"}>
              <IconButton disabled={true}>
                <CheckIcon style={{ fontSize: "14px" }} />
              </IconButton>
            </Tooltip>
          </div>
        )} */}

        {!showActionButtons &&
          !showCopiedCheck &&
          props.shifts_info.length > 1 && (
            <div
              className={`absolute ${
                props.minimizeMode ? "right-2 top-0" : "right-2 top-0"
              }`}
            >
              <Tooltip
                title={"More than one shift! Click the shift card to see details"}
              >
                {props.shifts_info.length === 2 ? (
                  <Filter2Icon style={{ fontSize: "10px", color: "white" }} />
                ) : (
                  <Filter3Icon style={{ fontSize: "10px", color: "white" }} />
                )}
              </Tooltip>
            </div>
          )}
        <ShiftInputsModal
          open={open}
          handleClose={handleClose}
          shift_info={props.shift_info}
          shifts_info={props.shifts_info}
          p={props.p}
          new_shift={false}
          addShift={props.addShift}
          date_name={props.date_name}
          availability_info={props.availability_info}
          role_names={props.role_names}
          getRoleName={getRoleName}
          getRoleType={getRoleType}
          saveShiftFinished={props.saveShiftFinished}
          setSaveShiftFinished={props.setSaveShiftFinished}
          d={props.d}
          role_types={props.role_types}
          addMultipleShifts={props.addMultipleShifts}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          frequentShifts={props.frequentShifts}
          breakWaiverEnabled={props.breakWaiverEnabled}
          person_day_time_off_requests={props.person_day_time_off_requests}
        />
        <ShiftRecapModal
          open={openRecapModal}
          handleClose={handleCloseRecapModal}
          p={props.p}
          shift_info={props.shift_info}
          post_missed_shift={props.post_missed_shift}
        />
      </div>
    </>
  );
}

export default ShiftCard;
