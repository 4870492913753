import React, { useState, useEffect } from "react";
import {
  LineChart,
  Bar,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import Typography from "@mui/material/Typography";

const VisualCustomLineChart = (props) => {
  const [data, setData] = useState(props.data);
  const [hiddenLines, setHiddenLines] = useState([]);

  useEffect(() => {
    setData(props.data); // Update data state
  }, [props.data]);

  const handleDataChange = (index, field, newValue) => {
    if (newValue >= 0) {
      setData((prevData) => {
        const newData = [...prevData];
        newData[index][field] = newValue;
        newData[index].employeesOptimal = Object.keys(newData[index])
          .filter((key) => key.endsWith(` ${props.label1} Labor`))
          .reduce((sum, key) => sum + newData[index][key], 0); // Update total employeesOptimal
        return newData;
      });
    }
  };

  const handleInputChange = (index, field, event) => {
    const newValue = parseInt(event.target.value, 10);
    handleDataChange(index, field, newValue);
  };

  const handleCheckboxChange = (dataKey) => {
    setHiddenLines((prevHiddenLines) => {
      if (prevHiddenLines.includes(dataKey)) {
        return prevHiddenLines.filter((key) => key !== dataKey);
      } else {
        return [...prevHiddenLines, dataKey];
      }
    });
  };

  const getCheckboxColor = (name) => {
    if (name.includes(`${props.label1} Non Flexible`)) {
      return "#cbd5e1"; // Blue color for Forecasted
    } else if (name.includes(props.label2)) {
      return "#FF6F59"; // Light Blue color for Scheduled
    } else {
      return "#bbf7d0"; // Default color
    }
  };

  const roleTypes = props.roleTypes;

  const showCheckBoxOption = (name, label) => {
    return (
      <div className="flex items-center mt-1">
        <input
          type="checkbox"
          checked={!hiddenLines.includes(name)}
          onChange={() => handleCheckboxChange(name)}
          style={{
            appearance: "none",
            WebkitAppearance: "none",
            width: props.isSmall ? "8px" : "12px",
            height: props.isSmall ? "8px" : "12px",
            borderRadius: "50px",
            border: `2px solid ${getCheckboxColor(name)}`,
            outline: "none",
            marginRight: "5px",
            cursor: "pointer",
            backgroundColor: !hiddenLines.includes(name)
              ? getCheckboxColor(name)
              : "transparent",
          }}
        />
        <label htmlFor={name} className={`ml-1 ${props.isSmall && "text-xs"}`}>
          {label}
        </label>
      </div>
    );
  };

  const showDataRow = (name, bold) => {
    return (
      <tr key={name}>
        <td
          className={
            "border px-5 py-4 text-xs whitespace-nowrap bg-gray-100 " + bold
          }
          style={{
            position: "sticky",
            left: 0,
            zIndex: 1,
            minWidth: "150px",
          }}
        >
          {name}
        </td>
        {data.map((entry, index) => (
          <td key={index} className="border">
            <div
              className={
                "h-full w-full flex items-center justify-center text-sm m-auto " +
                bold
              }
            >
              <p>{entry[name] ? entry[name] : 0}</p>
            </div>
          </td>
        ))}
      </tr>
    );
  };

  const showLine = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Line
          type="monotone"
          dataKey={name}
          stroke={getCheckboxColor(name)}
          strokeWidth={1}
          // dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          isAnimationActive={true}
          dot={false}
        />
      )
    );
  };

  const showStackedArea = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Area
          type="monotone"
          dataKey={name}
          //stroke={getCheckboxColor(name)}
          // strokeWidth={2}
          // dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          // isAnimationActive={true}
          fill={getCheckboxColor(name)}
          stroke={getCheckboxColor(name)}
          stackId="1"
        />
      )
    );
  };

  const showArea = (name) => {
    return (
      !hiddenLines.includes(name) && (
        <Area
          type="monotone"
          dataKey={name}
          //stroke={getCheckboxColor(name)}
          //strokeWidth={2}
          //dot={{ onMouseDown: (event, payload) => event.stopPropagation() }}
          isAnimationActive={false}
          fill={getCheckboxColor(name)}
        />
      )
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="bg-white border border-gray-300 w-80 px-6 py-5 rounded-xl shadow-xl">
          <div className="w-full flex items-center justify-between mb-3">
            <p className="text-slate-900">{`${label}`}</p>
            <p className="text-slate-900">Total</p>
          </div>
          {payload.map((entry, index) => {
            // Split entry.name into an array of words
            const words = entry.name.split(" ");

            // Join all words except the last one
            const modifiedName = words.slice(0, -1).join(" ");

            return (
              <div
                key={index}
                className={`w-full flex items-center justify-between py-3 ${
                  index !== 0 ? "border-t border-slate-200" : ""
                }`}
              >
                <div className="flex items-center space-x-3">
                  <div
                    className={`w-3 h-3 rounded-full`}
                    style={{ backgroundColor: entry.stroke }}
                  ></div>
                  <div className="text-slate-500">{modifiedName}</div>
                </div>
                <div className="text-slate-900">{entry.value}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="relative">
      <div className="ml-6">
        <h2 className={`${props.isSmall ? "text-sm font-bold" : "text-xl"}`}>
          {`${props.label1} vs ${props.label2} Staffing Levels`}
        </h2>
      </div>
      <div className="w-full mt-5 flex items-center justify-between">
        {props.type === "daily" ? (
          <div className="w-full flex justify-between ml-6">
            <div className="flex items-center space-x-10">
              <div>
                <p className="text-xs text-slate-500">Grade</p>
                <div
                  style={{ backgroundColor: props.gradeColor }}
                  className="w-7 h-7 rounded-lg flex items-center justify-center text-white mt-1"
                >
                  <p className="text-sm">{props.gradeLetter}</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-slate-500">Forecast Hours</p>
                <div className="h-7 mt-1">
                  <p>{props.forecastHours}</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-slate-500">Scheduled Hours</p>
                <div className="h-7 mt-1">
                  <p>{props.scheduleHours}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-slate-500 text-sm ml-6 w-32">
            {props.date_name}
          </h3>
        )}
        <div className="text-sm text-slate-500 flex items-center justify-end space-x-10 mr-16 w-[100%]">
          <div>
            {showCheckBoxOption(
              `${props.label1} Non Flexible Total`,
              `${props.label1} Non Flexible`
            )}
          </div>
          <div>
            {showCheckBoxOption(
              `${props.label1} Flexible Total`,
              `${props.label1} Flexible`
            )}
            {/*roleTypes.map((roleType) =>
              showCheckBoxOption(`${roleType} Forecasted Labor`)
    )*/}
          </div>
          <div>
            {showCheckBoxOption(`${props.label2} Labor Total`, props.label2)}
            {/*roleTypes.map((roleType) =>
              showCheckBoxOption(`${roleType} Scheduled Labor`)
  )*/}
          </div>
        </div>
      </div>
      {/* <div className="absolute top-2 right-48">
        <div className=" p-4">
          <div className="text-gray-900 font-bold text-xl mb-2">
            {" + " + props.nonprod_hours}
          </div>
          <p className="text-gray-700 text-base">Non Production Hours</p>
        </div>
  </div>*/}
      <div className="mt-10 -ml-10 mr-12">
        {
          <ResponsiveContainer width="100%" height={props.height}>
            <ComposedChart data={data}>
              <XAxis
                dataKey="time"
                tick={{ fontSize: 12 }}
                tickLine={false}
                interval="preserveEnd"
                minTickGap={20}
              />
              <YAxis tick={{ fontSize: 14 }} tickLine={false} />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

              {showStackedArea(`${props.label1} Non Flexible Total`)}
              {showStackedArea(`${props.label1} Flexible Total`)}
              {showLine(`${props.label2} Labor Total`)}
              {/*roleTypes.map((roleType) =>
              showLine(`${roleType} Forecasted Labor`)
            )*/}

              {/*roleTypes.map((roleType) =>
              showLine(`${roleType} Scheduled Labor`)
            )*/}
              <Tooltip content={<CustomTooltip />} />
            </ComposedChart>
          </ResponsiveContainer>
        }
      </div>
      {props.isTable && (
        <div>
          <h3 className="ml-12 mt-3 font-bold">{`${props.label1} Staffing Table`}</h3>
          <div className="mt-3 mr-12 ml-12">
            <div
              className="flex flex-wrap items-center gap-5 overflow-scroll w-full"
              style={{
                boxShadow: "inset -10px 0px 10px -10px rgba(0, 0, 0, 0.5)",
              }}
            >
              <table className="table-auto border-collapse">
                <thead>
                  <tr>
                    <th
                      className="border px-5 py-3 bg-primary text-white"
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        minWidth: "150px",
                      }}
                    ></th>
                    {data.map((entry, index) => (
                      <th
                        key={index}
                        className="border px-5 py-2 text-xs bg-primary text-white"
                      >
                        {entry.time}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {showDataRow(
                    `${props.label1} Non Flexible Total`,
                    "font-bold"
                  )}
                  {roleTypes.map((roleType) =>
                    showDataRow(`${roleType} ${props.label1} Non Flexible`, "")
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualCustomLineChart;
