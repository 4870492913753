import React, { useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import ReactToPrint from "react-to-print";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { formatName } from "../../../../helpers/helpers";

const date_options = { weekday: "short", month: "short", day: "numeric" };

export default function PrintMode(props) {
  const [selectedRoles, setSelectedRoles] = useState([]);

  let componentRef = React.useRef();

  const dow_nums = [0, 1, 2, 3, 4, 5, 6];
  let day_names = [];
  dow_nums.map((dow) =>
    day_names.push(
      props
        .addDays(props.week_start_date, dow)
        .toLocaleDateString("en-us", date_options)
    )
  );

  const groupByRole = () => {
    const groupedData = {};
    props.store_team_member_weekly_info_view
      .filter((p) => p.hours > 0)
      .forEach((employee) => {
        const { role } = employee;
        if (!groupedData[role]) {
          groupedData[role] = [];
        }
        groupedData[role].push(employee);
      });
    return groupedData;
  };

  const groupedData = groupByRole();
  const roles = Object.keys(groupedData);

  const handleRoleChange = (event) => {
    const { value } = event.target;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const filteredData = selectedRoles.length
    ? Object.entries(groupedData).filter(([role]) =>
        selectedRoles.includes(role)
      )
    : Object.entries(groupedData);

  const getAvailabilityText = (avail_list, timeoff_list) => {
    if (timeoff_list.length > 0) {
      return "Time Off";
    } else if (avail_list.length === 0) {
      return "";
    } else if (
      (avail_list[0]["available_from"] === 0) &
      (avail_list[0]["available_to"] === 0)
    ) {
      return "Unavailable";
    } else {
      return "";
    }
  };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="w-full px-5">
          <div className="w-full flex items-center justify-between pt-8">
            <div>
              <h3 className="text-xs">
                {day_names[0]} - {day_names[6]}
              </h3>
            </div>
            <div className="flex items-center space-x-8">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-yellow-200"></div>
                <p className="text-xxs">Morning</p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-200"></div>
                <p className="text-xxs">Evening</p>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-blue-200"></div>
                <p className="text-xxs">Late-night</p>
              </div>
            </div>
          </div>
          {filteredData.map(([role, employees]) => (
            <div key={role} className="mb-10">
              <table className="w-full text-sm text-left text-gray-500 mt-6">
                <thead className="text-xs text-gray-700 uppercase bg-slate-100 border-x border-t border-slate-100">
                  <tr>
                    <th scope="col" className="py-1.5 px-4 w-[160px]">
                      <p>{role}</p>
                    </th>
                    {day_names.map((d) => (
                      <th
                        scope="col"
                        className="py-1.5 px-2.5 w-[140px]"
                        key={d}
                      >
                        <p>{d}</p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {employees
                    .filter((p) => p.hours > 0)
                    .map((s) => (
                      <tr
                        key={s.employee_id}
                        className="bg-white border-b text-xs border-x border-slate-100"
                      >
                        <th
                          scope="row"
                          className="py-1.5 px-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          <p>
                            {formatName(s.first_name + " " + s.last_name)}
                          </p>
                        </th>
                        {dow_nums.map((dow) => {
                          if (s[dow].length > 0) {
                            const startTime = s[dow][0].shift_start;

                            let backgroundColor = "";
                            if (startTime <= 10) {
                              backgroundColor = "#fef08a";
                            } else if (startTime < 22) {
                              backgroundColor = "#fecaca";
                            } else {
                              backgroundColor = "#93c5fd";
                            }
                            return (
                              <td
                                key={dow}
                                className="py-1.5 pl-2.5 border-l border-slate-100"
                                style={{ backgroundColor }}
                              >
                                <p>
                                  {props.get_t_str(s[dow][0].shift_start) +
                                    "-" +
                                    props.get_t_str(s[dow][0].shift_end)}
                                </p>
                                <p>
                                  {s[dow][0].station
                                    ? s[dow][0].station.name
                                    : ""}
                                </p>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={dow}
                                className="py-1.5 pl-2.5 border-l border-slate-100"
                              >
                                <p>
                                  {getAvailabilityText(
                                    s[dow + "_availability"],
                                    s[dow + "_timeoff"]
                                  )}
                                </p>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      );
    }
  }

  return (
    <div>
      <div className="w-full flex items-center justify-between pr-4 pt-8">
        <div className="flex items-center space-x-10">
          <h3>
            {day_names[0]} - {day_names[6]}
          </h3>
          <div className="w-[500px]">
            {/* <p className="text-sm text-slate-500">Filter by role</p> */}
            <div className="w-full">
              <FormControl sx={{ width: 500 }}>
                <Select
                  labelId="multiple-checkbox-label"
                  fullWidth
                  multiple
                  value={selectedRoles}
                  onChange={handleRoleChange}
                  renderValue={(selected) =>
                    selected.length === 0 ? "Filter by role" : selected.join(", ")
                  }
                  displayEmpty
                  sx={{
                    borderRadius: "8px",
                    paddingY: "5px",
                    height: "45px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6d28d9",
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <p>Filter by role</p>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox
                        checked={selectedRoles.indexOf(role) > -1}
                        sx={{
                          color: "#6d28d9",
                          "&.Mui-checked": {
                            color: "#6d28d9",
                          },
                          "&.MuiCheckbox-indeterminate": {
                            color: "#6d28d9",
                          },
                        }}
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-yellow-200"></div>
            <p className="text-sm">Morning</p>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-200"></div>
            <p className="text-sm">Evening</p>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 rounded-full bg-blue-200"></div>
            <p className="text-sm">Late-night</p>
          </div>
          <ReactToPrint
            trigger={() => (
              <div className="text-slate-500">
                <IconButton>
                  <PrintIcon />
                </IconButton>
              </div>
            )}
            content={() => componentRef}
          />
        </div>
      </div>
      <div className="overflow-x-auto relative">
        <div style={{ display: "none" }}>
          <ComponentToPrint ref={(el) => (componentRef = el)} />
        </div>
        {filteredData.map(([role, employees]) => (
          <div key={role} className="mb-10">
            <table className="w-full text-sm text-left text-gray-500 mt-6">
              <thead className="text-xs text-gray-700 uppercase bg-slate-100 border-x border-t border-slate-100">
                <tr>
                  <th scope="col" className="py-1.5 px-4 w-[160px]">
                    <p>{role}</p>
                  </th>
                  {day_names.map((d) => (
                    <th
                      scope="col"
                      className="py-1.5 px-2.5 w-[140px]"
                      key={d}
                    >
                      <p>{d}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {employees
                  .filter((p) => p.hours > 0)
                  .map((s) => (
                    <tr
                      key={s.employee_id}
                      className="bg-white border-b text-xs border-x border-slate-100"
                    >
                      <th
                        scope="row"
                        className="py-1.5 px-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <p>{formatName(s.first_name + " " + s.last_name)}</p>
                      </th>
                      {dow_nums.map((dow) => {
                        if (s[dow].length > 0) {
                          const startTime = s[dow][0].shift_start;

                          let backgroundColor = "";
                          if (startTime <= 10) {
                            backgroundColor = "#fef08a";
                          } else if (startTime < 22) {
                            backgroundColor = "#fecaca";
                          } else {
                            backgroundColor = "#93c5fd";
                          }
                          return (
                            <td
                              key={dow}
                              className="py-1.5 pl-2.5 border-l border-slate-100"
                              style={{ backgroundColor }}
                            >
                              <p>
                                {props.get_t_str(s[dow][0].shift_start) +
                                  "-" +
                                  props.get_t_str(s[dow][0].shift_end)}
                              </p>
                              <p>
                                {s[dow][0].station
                                  ? s[dow][0].station.name
                                  : ""}
                              </p>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={dow}
                              className="py-1.5 pl-2.5 border-l border-slate-100"
                            >
                              <p>
                                {getAvailabilityText(
                                  s[dow + "_availability"],
                                  s[dow + "_timeoff"]
                                )}
                              </p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}
