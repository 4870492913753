import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { getInitials } from "../../utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import AvatarSmall from "../../shared/helpers/AvatarSmall";
import DrawerHeader from "../../shared/helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../../shared/helpers/DrawerFooterContainerSmall";
import ButtonFilled from "../../shared/helpers/ButtonFilled";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import { formatName } from "../../../../../helpers/helpers";
import { getCardColor2 } from "../../../../../helpers/helpers";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WarningIcon from '@mui/icons-material/Warning';
import EmployeeNotLoggedInWarningModal from "./EmployeeNotLoggedInWarningModal";

export default function EmployeesTableRow(props) {


  const [isEmployeeHidden, setIsEmployeeHidden] = React.useState(
    props.is_hidden
  );

  function toggleEmployeeHidden() {
    if (isEmployeeHidden) {
      props.hide_store_team_member(props.id, "false");
      setIsEmployeeHidden(false);
    } else {
      props.hide_store_team_member(props.id, "true");
      setIsEmployeeHidden(true);
    }
  }

  const shiftCardColor = getCardColor2(
    props.primaryRoleId,
    props.roleNames,
    false
  );

  let permissionInfo = {
    label: "",
    style: "",
  };
  if (props.permissionType === "company_admin") {
    permissionInfo = {
      label: "Admin",
      style: "border border-violet-600 bg-violet-50 text-violet-600",
    };
  } else if (props.permissionType === "company_manager") {
    permissionInfo = {
      label: "Manager",
      style: "border border-orange-600 bg-orange-50 text-orange-600",
    };
  } else if (props.permissionType === "company_timeclock") {
    permissionInfo = {
      label: "Time Clock",
      style: "border border-yellow-600 bg-yellow-50 text-yellow-700",
    };
  } else {
    permissionInfo = {
      label: "Employee",
      style: "border border-blue-600 bg-blue-50 text-blue-600",
    };
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};

  return (
    <>
      <tr
        {...props.provided.draggableProps}
        // {...props.provided.dragHandleProps}
        ref={props.provided.innerRef}
        className={`${props.snapshot.isDragging ? "bg-slate-200" : "bg-white"}`}
      >
        <td
          className={`whitespace-nowrap py-4 pl-6 text-sm ${
            props.snapshot.isDragging ? "w-[55px]" : ""
          }`}
        >
          <div
            {...props.provided.dragHandleProps}
            className="text-slate-500"
          >
            <DragIndicatorIcon />
          </div>
        </td>
        <td
          className={`whitespace-nowrap px-3 py-4 text-sm ${
            props.snapshot.isDragging ? "w-[112px]" : ""
          }`}
        >
          <div>
            <AvatarSmall
              initials={getInitials(props.firstName, props.lastName)}
              color={shiftCardColor}
            />
          </div>
        </td>
        <td
          className={`whitespace-nowrap px-3 py-4 text-sm text-slate-500 ${
            props.snapshot.isDragging ? "w-[140px]" : ""
          }`}
        >
          <div className="font-medium text-slate-900 relative">
            {!props.has_logged_in &&
              <div
                className="absolute -left-8 bottom-0.5 text-rose-800 cursor-pointer"
                onClick={handleOpen}
              >
                <Tooltip title={`${props.firstName} has not logged in yet. Click here if you would like to resend the welcome email.`}>
                  <WarningIcon style={{ fontSize: "14px" }} />
                </Tooltip>
              </div>
            }
            {formatName(`${props.firstName}`)}
          </div>
        </td>
        <td
          className={`whitespace-nowrap px-3 py-4 text-sm text-slate-500 ${
            props.snapshot.isDragging ? "w-[200px]" : ""
          }`}
        >
          <div className="font-medium text-slate-900">
            {formatName(`${props.lastName}`)}
          </div>
        </td>
        <td
          className={`whitespace-nowrap px-3 py-4 text-sm text-slate-500 ${
            props.snapshot.isDragging ? "w-[280px]" : ""
          }`}
        >
          <div className="text-slate-900">
            {props.email ? props.email : "None"}
          </div>
        </td>
        <td
          className={`whitespace-nowrap px-3 py-4 text-sm text-slate-500 ${
            props.snapshot.isDragging ? "w-[170px]" : ""
          }`}
        >
          <div className="text-slate-900">
            {props.phoneNumber ? props.phoneNumber : "None"}
          </div>
        </td>
        {props.timeEntriesEnabled && (
          <td
            className={`whitespace-nowrap px-3 py-4 text-sm text-slate-500 ${
              props.snapshot.isDragging ? "w-[120px]" : ""
            }`}
          >
            <div className="text-slate-900">
              {props.clockPin ? props.clockPin : "None"}
            </div>
          </td>
        )}
        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div className="flex items-center space-x-1">
            <div className="w-fit">
              <div
                className={`${permissionInfo.style} px-3 h-8 rounded-lg flex items-center justify-center`}
              >
                <p>{permissionInfo.label}</p>
              </div>
            </div>
            {props.is_minor &&
              <div className="w-fit">
                <div
                  className={`bg-slate-100 border border-slate-400 text-slate-600 px-3 h-8 rounded-lg flex items-center justify-center`}
                >
                  <p>Minor</p>
                </div>
              </div>
            }
          </div>
        </td>
        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
          <div>{props.person.homeStore}</div>
        </td> */}
        {!props.snapshot.isDragging && (
          <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium flex items-center justify-end space-x-5">
            {isEmployeeHidden && (
              <div className="border border-rose-600 bg-rose-50 text-rose-600 px-3 h-8 rounded-lg flex items-center justify-center">
                <p>hidden</p>
              </div>
            )}
            <div
              className="w-10 h-10 rounded-full bg-slate-50 flex items-center justify-center text-slate-500"
              onClick={toggleEmployeeHidden}
            >
              <Tooltip
                title={
                  isEmployeeHidden
                    ? "Unhide employee from scheduling view"
                    : "Hide employee from scheduling view"
                }
              >
                <IconButton>
                  {isEmployeeHidden ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>
            <div
              className="w-10 h-10 rounded-full bg-slate-50 flex items-center justify-center text-slate-500"
              onClick={() => {
                // props.fetchCertificates(props.id);
                props.fetchStoreTeamMember(true, props.id);
                props.setShowUserProfile(true);
                // props.handleOpen();
              }}
            >
              <Tooltip title="Expand employee profile">
                <IconButton>
                  <PersonIcon />
                </IconButton>
              </Tooltip>
            </div>
            {/* <div className="bg-primary hover:bg-blue-700 text-white px-5 py-2 rounded-lg hover:bg-slate-50 cursor-pointer" onClick={toggleDrawer(true)}>Edit</div> */}
          </td>
        )}
      </tr>
      <EmployeeNotLoggedInWarningModal
        open={open}
        handleClose={handleClose}
        firstName={props.firstName}
        lastName={props.lastName}
        homeStore={props.homeStore}
        stm_id={props.id}
      />
    </>
  );
}
